<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu class="navbar-custom" [concurso]="concurso?.fullname" [idConcurso]="concurso?.id_concurso"></menu>
      <div class="container-fluid py-3">
        <div class="card shadow-sm">
          <div class="card-header bg-white py-2 d-flex justify-content-between align-items-center">
            <h4 class="card-title mb-0">Season Passes</h4>
            <!-- <button class="btn btn-primary btn-sm" (click)="createNewPass()">
              <i class="fas fa-plus me-1"></i>New Pass
            </button> -->
          </div>
          <div class="card-body p-3">
            <!-- Loading State -->
            <div *ngIf="loading" class="text-center py-3">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>

            <!-- Error State -->
            <div *ngIf="error" class="alert alert-danger py-2 d-flex align-items-center" role="alert">
              <i class="fas fa-exclamation-circle me-2"></i>
              <div>{{ error }}</div>
            </div>

            <!-- Passes List -->
            <div *ngIf="!loading && !error" class="row g-3">
              <!-- Passes List Sidebar -->
              <div class="col-md-4">
                <div class="card border-0 shadow-sm h-100">
                  <div class="card-header bg-white py-2">
                    <h5 class="mb-0 fs-6">Available Passes</h5>
                  </div>
                  <div class="card-body p-0">
                    <div class="list-group list-group-flush">
                      <a *ngFor="let pass of passes" (click)="selectPass(pass)"
                        class="list-group-item list-group-item-action border-0 py-2 px-3"
                        [ngClass]="{'bg-light': selectedPass?.name === pass.name}">
                        <div class="d-flex justify-content-between align-items-start">
                          <div>
                            <h6 class="mb-0 fs-6">{{ pass.name }}</h6>
                            <p class="mb-1 text-muted small">{{ pass.description }}</p>
                            <div class="d-flex align-items-center text-muted small">
                            </div>
                          </div>
                          <span class="badge badge-success-lighten rounded-pill">{{ pass.entries.length }}</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Pass Details -->
              <div class="col-md-8" *ngIf="selectedPass">
                <div class="card border-0 shadow-sm h-100">
                  <div class="card-header bg-white py-2 d-flex justify-content-between align-items-center">
                    <div>
                      <h5 class="mb-0 fs-6">{{ selectedPass.name }}</h5>
                      <p class="text-muted small mb-0">{{ selectedPass.description }}</p>
                    </div>
                    <div class="d-flex gap-1 rounded p-2 mb-2">
                      <small class="text-muted">Total Entries: {{ selectedPass.entries.length }}</small>
                    </div>
                    <div class="d-flex gap-1">
                      <!-- <button class="btn btn-outline-primary btn-sm" (click)="editPass(selectedPass)">
                        <i class="fas fa-edit"></i>
                      </button> -->
                      <button class="btn btn-outline-secondary btn-sm" (click)="closeDetails()">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body p-2">
                    <!-- Pass Summary -->
                    

                    <!-- Entries Table -->
                    <div class="table-responsive">
                      <table class="table table-sm table-hover align-middle">
                        <thead class="table-light">
                          <tr>
                            <th>Entry #</th>
                            <th>Horse</th>
                            <th>EC</th>
                            <th>Owner</th>
                            <th>Trainer</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let entry of selectedPass.entries">
                            <td>{{ entry.entry }}</td>
                            <td>{{ entry.name }}</td>
                            <td>{{ entry.ec }}</td>
                            <td>{{ entry.owner }}</td>
                            <td>{{ entry.trainer }}</td>
                            <td>
                              <span [class]="'badge bg-' + (entry.status === 'active' ? 'success' : 
                                                   entry.status === 'expired' ? 'danger' : 'warning')">
                                {{ entry.status }}
                              </span>
                            </td>
                            <td>
                              <div class="btn-group btn-group-sm">
                                <button class="btn btn-outline-primary btn-sm p-1" (click)="editEntry(entry)">
                                  <i class="fas fa-edit"></i>
                                </button>
                                <!-- <button class="btn btn-outline-danger btn-sm p-1" (click)="deleteEntry(entry)">
                                  <i class="fas fa-trash"></i>
                                </button> -->
                              </div>
                            </td>
                          </tr>
                          <tr *ngIf="selectedPass.entries.length === 0">
                            <td colspan="6" class="text-center py-2 text-muted">
                              No entries found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <!-- Add Entry Button -->
                    <div class="text-center mt-2">
                      <button class="btn btn-primary btn-sm" (click)="addEntry()">
                        <i class="fas fa-plus me-1"></i>Add Entry
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- No Pass Selected State -->
              <div class="col-md-8" *ngIf="!selectedPass">
                <div class="card border-0 shadow-sm h-100">
                  <div class="card-body d-flex flex-column align-items-center justify-content-center py-3">
                    <i class="fas fa-ticket-alt fa-2x text-muted mb-2"></i>
                    <h5 class="text-muted mb-1">Select a Season Pass</h5>
                    <p class="text-muted small mb-0">Choose a pass from the list to view its details</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>