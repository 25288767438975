import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-class-form-dialog',
  template: `
    <h2 mat-dialog-title>{{ data.classId ? 'Edit' : 'Add' }} Class</h2>
    <mat-dialog-content>
      <app-class-form
        [classId]="data.classId"
        [concursoId]="data.concursoId"
        [divisionId]="data.divisionId"
        (saved)="onSaved($event)"
        (cancel)="dialogRef.close()"
        (imageUpload)="onImageUpload($event)">
      </app-class-form>
    </mat-dialog-content>
  `
})
export class ClassFormDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ClassFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onSaved(savedClass: any) {
    this.dialogRef.close(savedClass);
  }

  onImageUpload(event: any) {
    // Handle image upload if needed
  }
} 