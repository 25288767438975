import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CircuitsService } from '../services/circuits.service';
import { AddCircuitsComponent } from './add-circuit/add-circuit.component';
import { Circuit } from './circuit.interface';
declare var $: any;

@Component({
  selector: 'app-circuits',
  templateUrl: './circuits.component.html',
  styleUrls: ['./circuits.component.scss']
})
export class CircuitsComponent implements OnInit {
  @Input() idClub: string;
  @Input() season: string;
  
  public circuits: Circuit[] = [];
  public loading: boolean = true;
  public error: string = '';
  private circuitToDelete: string;
  public calculatingCircuits: Set<string> = new Set();

  constructor(
    private circuitsService: CircuitsService,
    private dialog: MatDialog
  ) {}

  openAddCircuitDialog(): void {
    const dialogRef = this.dialog.open(AddCircuitsComponent, {
      width: '500px',
      data: { idClub: this.idClub, season: this.season }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.circuits.push(result);
      }
    });
  }

  ngOnInit() {
    this.getCircuits();
  }

  private getCircuits(): void {
    this.loading = true;
    this.circuitsService.getCircuits(this.idClub, this.season)
      .subscribe({
        next: (response) => {
          if (!response.error) {
            this.circuits = response.data;
          } else {
            this.error = response.message;
          }
          this.loading = false;
        },
        error: (error) => {
          this.error = 'Failed to load circuits';
          this.loading = false;
          console.error(error);
        }
      });
  }

  confirmDelete(circuit: Circuit): void {
    this.circuitToDelete = circuit.id;
    $('#modal-delete-circuit').modal('show');
  }

  deleteCircuit(): void {
    if (!this.circuitToDelete) return;

    this.loading = true;
    this.circuitsService.deleteCircuit(this.circuitToDelete)
      .subscribe({
        next: (response) => {
          if (!response.error) {
            this.circuits = this.circuits.filter(c => c.id !== this.circuitToDelete);
            $.NotificationApp.send(
              "Success",
              "Circuit has been deleted successfully",
              'bottom-right',
              '#06d5a1',
              'success'
            );
          } else {
            $.NotificationApp.send(
              "Error",
              response.message,
              'bottom-right',
              '#fa5c7c',
              'error'
            );
          }
          $('#modal-delete-circuit').modal('hide');
          this.loading = false;
        },
        error: (error) => {
          console.error('Error deleting circuit:', error);
          $.NotificationApp.send(
            "Error",
            "Failed to delete circuit",
            'bottom-right',
            '#fa5c7c',
            'error'
          );
          $('#modal-delete-circuit').modal('hide');
          this.loading = false;
        }
      });
  }

  editCircuit(circuit: Circuit): void {
    const dialogRef = this.dialog.open(AddCircuitsComponent, {
      width: '500px',
      data: { 
        idClub: this.idClub, 
        season: this.season,
        circuit: circuit // Pass the circuit to edit
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the circuit in the list
        const index = this.circuits.findIndex(c => c.id === circuit.id);
        if (index !== -1) {
          this.circuits[index] = result;
        }
      }
    });
  }

  calculateCircuit(circuit: Circuit): void {
    this.calculatingCircuits.add(circuit.id);
    this.circuitsService.calculateCircuit(circuit.id)
      .subscribe({
        next: (response) => {
          if (!response.error) {
            $.NotificationApp.send(
              "Success",
              "Circuit points have been calculated successfully",
              'bottom-right',
              '#06d5a1',
              'success'
            );
          } else {
            $.NotificationApp.send(
              "Error",
              response.message,
              'bottom-right',
              '#fa5c7c',
              'error'
            );
          }
          this.calculatingCircuits.delete(circuit.id);
        },
        error: (error) => {
          console.error('Error calculating circuit:', error);
          $.NotificationApp.send(
            "Error",
            "Failed to calculate circuit points",
            'bottom-right',
            '#fa5c7c',
            'error'
          );
          this.calculatingCircuits.delete(circuit.id);
        }
      });
  }
} 