import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { Observable } from 'rxjs';
import { Circuit } from '../components/circuit.interface';
import { environment } from '../../../../../src/environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export interface CircuitsConfig {
  apiUrl: string;
  getAuthToken: () => string;
}

@Injectable()
export class CircuitsService {
  constructor(private http: HttpClient, 
   private authService: AuthService
  ) {}

  getCircuits(idClub: string, season: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this.authService.getAuthorizationToken()
    };

    return this.http.get(
      `${environment.api.nest.uri}circuits/club/${idClub}?season=${season}`, 
      { headers }
    );
  }

  createCircuit(circuit: Circuit): Observable<any> {
    console.log('Creating circuit with data:', circuit);
    const headers = {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this.authService.getAuthorizationToken()
    };

    return this.http.post(
      `${environment.api.nest.uri}circuits`, 
      circuit,
      { headers }
    ).pipe(
      tap(response => console.log('Circuit creation response:', response)),
      catchError(error => {
        console.error('Error creating circuit:', error);
        return throwError(() => error);
      })
    );
  }

  deleteCircuit(id: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this.authService.getAuthorizationToken()
    };

    return this.http.delete(
      `${environment.api.nest.uri}circuits/${id}`,
      { headers }
    );
  }

  updateCircuit(id: string, circuit: Circuit): Observable<any> {
    console.log('Updating circuit with ID:', id, 'Data:', circuit);
    const headers = {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this.authService.getAuthorizationToken()
    };

    return this.http.patch(
      `${environment.api.nest.uri}circuits/${id}`,
      circuit,
      { headers }
    ).pipe(
      tap(response => console.log('Circuit update response:', response)),
      catchError(error => {
        console.error('Error updating circuit:', error);
        return throwError(() => error);
      })
    );
  }

  getClassesByClubAndSeason(clubId: string, season: string, search?: string, searchAllOrganizers?: boolean): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this.authService.getAuthorizationToken()
    };

    const params = new URLSearchParams();
    if (search) params.append('search', search);
    if (searchAllOrganizers) params.append('allOrganizers', 'true');

    const url = `${environment.api.nest.uri}circuits/classes/${clubId}/${season}${params.toString() ? `?${params.toString()}` : ''}`;
    return this.http.get(url, { headers });
  }

  getCircuitClasses(circuitId: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this.authService.getAuthorizationToken()
    };

    return this.http.get(
      `${environment.api.nest.uri}circuits/${circuitId}/classes`,
      { headers }
    );
  }

  calculateCircuit(circuitId: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this.authService.getAuthorizationToken()
    };

    return this.http.post(
      `${environment.api.nest.uri}circuits/${circuitId}/calculate`,
      {},
      { headers }
    );
  }
} 