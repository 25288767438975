import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';
@Injectable()
export class ConceptoService extends Api {

    constructor(private http: HttpClient, private authService: AuthService) {
        super();
    }

    public getConceptos(idConcurso): Observable<any>{
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}conceptos/?c=${idConcurso}`, {headers: headers});
    }

    public getConceptosNest(idConcurso): Observable<any>{
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8',
            'authorization': 'Bearer ' + this.authService.getAuthorizationToken() || '',
        };

        return this.http.get(`${environment.api.nest.uri}conceptos?idConcurso=${idConcurso}`, {headers: headers});
    }


    public getFeedConcepts(idConcurso): Observable<any>{
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}conceptos/feed/?c=${idConcurso}`, { headers: headers });
    }

    public detalleConcepto(idConcurso, idConcepto): Observable<any>{
        const params = JSON.stringify({idConcurso, idConcepto});
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}conceptos/detalles/`, params, {headers: headers});
    }

    public detalleConceptoMX(idConcurso, idConcepto): Observable<any>{
        const params = JSON.stringify({idConcurso, idConcepto});
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}conceptos/detallesmx/`, params, {headers: headers});
    }

    public editarConceptoNest(datos): Observable<any> {
        const params = JSON.stringify(datos);
        const headers = {
          'x-api-key': this.api_key,
          'Content-Type': 'application/json;charset=UTF-8'
        };
    
        return this.http.patch(`${environment.api.nest.uri}conceptos/${datos.id}`, params, { headers: headers });
      }
}
