import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';

export interface SeasonPass {
  id: string;
  name: string;
  description?: string;
  price: number;
  validFrom: Date;
  validTo: Date;
  entries: SeasonPassEntry[];
}

export interface SeasonPassEntry {
  entry: string;
  name : string;
  ec: string;
  owner: string;
  trainer?: string;
  riders?: string;
  purchaseDate?: Date;
  status: 'active' | 'expired' | 'cancelled' | '';
}

interface SeasonPassData extends Omit<SeasonPass, 'validFrom' | 'validTo' | 'entries'> {
  validFrom: any;
  validTo: any;
  entries: Array<Omit<SeasonPassEntry, 'purchaseDate'> & { purchaseDate: any }>;
}

@Injectable({
  providedIn: 'root'
})
export class SeasonPassService {
  private readonly clubId = sessionStorage.getItem('club')?.toString();

  constructor(private firestore: AngularFirestore) {}

  getSeasonPasses(): Observable<SeasonPass[]> {
    if (!this.clubId) {
      throw new Error('Club ID not found in session storage');
    }
    
    console.log('Getting passes for club:', this.clubId);
    
    try {
      const passesRef = this.firestore.collection('seasonPasses');
      console.log('Collection reference created:', passesRef);
      
      return passesRef.valueChanges().pipe(
        map(passes => passes as SeasonPass[])
      );
      
      
    } catch (error) {
      console.error('Error in getSeasonPasses:', error);
      throw error;
    }
  }

  private convertTimestamp(timestamp: any): Date {
    if (!timestamp) return new Date(); // Default to current date if missing
    
    // If it's already a Date object, return it
    if (timestamp instanceof Date) return timestamp;
    
    // If it's a Firestore Timestamp
    if (timestamp.toDate) return timestamp.toDate();
    
    // If it's a string, try to parse it
    if (typeof timestamp === 'string') {
      const date = new Date(timestamp);
      return isNaN(date.getTime()) ? new Date() : date;
    }
    
    return new Date(); // Default to current date if invalid
  }

  updateSeasonPassEntry(passId: string, entry: SeasonPassEntry): Promise<void> {
    if (!this.clubId) {
      throw new Error('Club ID not found in session storage');
    }

    return this.firestore.doc(`seasonPasses/${passId}`).get().toPromise()
      .then(doc => {
        if (!doc?.exists) {
          throw new Error(`Season pass with ID ${passId} not found`);
        }
        
        const pass = doc.data() as SeasonPassData;
        const entryIndex = pass.entries?.findIndex(e => e.entry === entry.entry) ?? -1;
        
        let updatedEntries;
        
        if (entryIndex === -1) {
          // Add new entry
          updatedEntries = [...(pass.entries || []), {
            ...entry,
            // Send purchase date as firestore timestamp for storing
            purchaseDate: entry.purchaseDate
          }];
        } else {
          // Update existing entry
          updatedEntries = [...pass.entries];
          updatedEntries[entryIndex] = {
            ...entry,
            // Send purchase date as firestore timestamp for storing
            purchaseDate: entry.purchaseDate
          };
        }
        
        // Update the document with the updated entries
        return this.firestore.doc(`seasonPasses/${passId}`).update({
          entries: updatedEntries
        });
      });
  }

} 