import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { PruebaService } from '../services/prueba.service';
import { ConcursoService } from '../services/concurso.service';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { PrintService } from '../services/print.service';
import { AppService } from '../services/app.service';
import { EmailService } from './../services/email.service';
import { firstValueFrom } from 'rxjs';
import * as moment from 'moment';
declare let $: any;

@Component({
  selector: 'app-hunter-classes',
  templateUrl: './hunter-classes.component.html',
  styleUrls: ['./hunter-classes.component.css']
})
export class HunterClassesComponent implements OnInit, AfterViewInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public fecha: string;
  public fechaSeleccionada: string;
  public pruebasSinAsignar: any[];
  public ipcUnassigned: string;
  public pistaUnassigned: string;
  public eventReassign: Event;
  public pistaReassign: string;
  public fechaReassign: string;
  public pistaAnteriorReassing: string;
  public mostrarInfoCaballo: boolean;
  public mostrarPaisJinete: boolean;
  public loading: Map<string, boolean>;
  public dates: Set<string | null>;
  public dias: string[];
  public pruebas: any[];
  public pistas: any[];
  public showReassignModal: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private pruebaService: PruebaService,
    private concursoService: ConcursoService,
    private printService: PrintService,
    private appService: AppService,
    private emailService: EmailService
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso() || '';
    this.dates = new Set<string | null>();

    this.dias = [];
    this.pruebas = [];
    this.pistas = [];
    this.pruebasSinAsignar = [];

    this.fechaSeleccionada = '';
    this.ipcUnassigned = '';
    this.pistaUnassigned = '';
    this.fechaReassign = '';
    this.pistaAnteriorReassing = '';
    this.fecha = moment().format('YYYY-MM-DD');
    this.mostrarInfoCaballo = false;
    this.mostrarPaisJinete = false;
    this.loading = new Map<string, boolean>([['classes',true], ['dates',true], ['saving', false]]);
  }

  ngAfterViewInit(): void {
    $('#modal-confirmar-unassign').on('shown.bs.modal', () => {
      $('#confirm-reassing-class').focus();
    });
  }

  async ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    await this.getDias();
    this.getPruebas();
  }

  private async getPruebas(notificacion = false){
    return firstValueFrom(this.pruebaService.getPruebas(this.idConcurso, true)).then(response => {
        const pbas: any[] = [];
        for (const [dia, pruebas] of Object.entries<any[]>(response)) {
          pruebas.map(p => {
            p.momentDate = moment(p.fecha);
            return p;
          });
          pbas.push({ dia, pruebas: pruebas });
          if(!dia) this.pruebasSinAsignar = pruebas;
        }
        this.pruebas = pbas;
        this.seleccionarDia({ target: { value: this.fechaSeleccionada } });
        if (notificacion) {
          $.NotificationApp.send("Success", 'The classes have been updated successfully.', 'bottom-right', '#3c763d', 'success');
        }
        this.loading.set('classes', false);
      }).catch(error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to update the classes.', 'bottom-right', '#fa5c7c', 'error');
        this.loading.set('classes', false);
        return;
      });
  }

  private async getDias(): Promise<any> {
    return firstValueFrom(this.concursoService.getDias(this.idConcurso)).then(response => {
      if(response.error) {
        $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        this.loading.set('dates', false);
        return;
      }
      this.dates = new Set<string | null>([null, ...response.dias]);
      this.dias = response.dias;
      this.loading.set('dates', false);
      return this.dias;
    }).catch(error => {
      console.log(error);
      $.NotificationApp.send("Error", 'An error occurred while obtaining the dates', 'bottom-right', '#fa5c7c', 'error');
      this.loading.set('dates', false);
      return;
    });;
  }

  public async seleccionarDia(e): Promise<void> {
    this.pistas = [];
    const dia = moment(e.target.value);
    this.fechaSeleccionada = dia.isValid() ? dia.format('YYYY-MM-DD') : '';
    const pruebas = (this.pruebas.find(p => p.dia == this.fechaSeleccionada)||{}).pruebas||[];
    const { pistas, error, message } = await firstValueFrom(this.concursoService.getPistas(this.idConcurso));
    if (error) {
      $.NotificationApp.send("Error", message, 'bottom-right', '#fa5c7c', 'error', 10000);
      return;
    }
    for(const pista of pistas) {
      this.pistas.push({
        nombre: pista,
        pruebas: pruebas.filter(p => p.pista == pista).map((p, i) => {
          if (i == 0 && (p.hard_start != '1' || !p.horario)) {
            p.hard_start = '1';
            p.horario = p.horario || '08:00';
          }
          return p;
        }),
        pruebas_us: pruebas.filter(p => p.pista == pista).reduce((a, b) => { if (b.id_modalidad == '44') return a + 1; else return a }, 0),
        inscritos: pruebas.filter(p => p.pista == pista).reduce((a, b) => { return a + b.inscritos }, 0),
        inscritos_us: pruebas.filter(p => p.pista == pista).reduce((a, b) => { return Number(a) + Number(b.inscritos_us) }, 0)
      });
    }
    this.pistas = this.pistas.sort((a, b) => {
      if (a.nombre == b.nombre) return 0;
      return a.nombre < b.nombre ? -1 : 1;
    });

    setTimeout(() => {
      $.App.init();
    });
  }

  public drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      if (event.previousIndex == 0 && event.previousIndex != event.currentIndex) {
        event.container.data[event.currentIndex]['horario'] = '';
        event.container.data[event.currentIndex]['hard_start'] = '0';
        event.container.data[event.currentIndex]['mostrarHora'] = false;
        event.container.data[event.previousIndex]['horario'] = event.container.data[event.previousIndex]['hard_start'] == '1' && event.container.data[event.previousIndex]['horario'] ? event.container.data[event.previousIndex]['horario'] : '08:00';
        event.container.data[event.previousIndex]['hard_start'] = '1';
      } else if (event.currentIndex == 0 && event.previousIndex != event.currentIndex) {
        event.container.data[event.currentIndex + 1]['horario'] = '';
        event.container.data[event.currentIndex + 1]['hard_start'] = '0';
        event.container.data[event.currentIndex + 1]['mostrarHora'] = false;
        event.container.data[event.currentIndex]['horario'] = event.container.data[event.currentIndex]['hard_start'] == '1' && event.container.data[event.currentIndex]['horario'] ? event.container.data[event.currentIndex]['horario'] : '08:00';
        event.container.data[event.currentIndex]['hard_start'] = '1';
      }
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      if (event.previousIndex == 0) {
        event.previousContainer.data[event.previousIndex]['horario'] = event.previousContainer.data[event.previousIndex]['hard_start'] == '1' && event.previousContainer.data[event.previousIndex]['horario'] ? event.previousContainer.data[event.previousIndex]['horario'] : '08:00';
        event.previousContainer.data[event.previousIndex]['hard_start'] = '1';
      }

      if (event.currentIndex == 0) {
        event.container.data[event.currentIndex]['horario'] = event.container.data[event.currentIndex]['hard_start'] == '1' && event.container.data[event.currentIndex]['horario'] ? event.container.data[event.currentIndex]['horario'] : '08:00';
        event.container.data[event.currentIndex]['hard_start'] = '1';
        if (event.container.data[event.currentIndex + 1]) {
          event.container.data[event.currentIndex + 1]['horario'] = '';
          event.container.data[event.currentIndex + 1]['hard_start'] = '0';
          event.container.data[event.currentIndex + 1]['mostrarHora'] = false;
        }
      } else {
        event.container.data[event.currentIndex]['horario'] = '';
        event.container.data[event.currentIndex]['hard_start'] = '0';
        event.container.data[event.currentIndex]['mostrarHora'] = false;
      }
    }
  }

  public connectTo(pista): any[] {
    return this.pistas.map(p => `list-${p.nombre}`).filter(p => p != `list-${pista}`);
  }

  public async save(){
    this.loading.set('saving', true);
    const pruebas: any[] = [];
    const pbasTmp: any[] = [];
    for (const pista of this.pistas) {
      const nombrePista = pista.nombre;
      let n = 0;
      const fecha: moment.Moment = moment(this.fechaSeleccionada);
      for(const prueba of pista.pruebas) {
        //First: n == 0 si no tiene horario o no tiene hard_start da error
        if (n == 0 && (prueba.hard_start != '1' || !prueba.horario)) {
          $.NotificationApp.send("Error", "The first class of each ring must have a start time.", 'bottom-right', '#fa5c7c', 'error', 10000);
          this.loading.set('saving', false);
          return;
        }
        let inicio = '';
        //Validar fecha, si no es valida se asigna inicio vacio
        if(fecha.isValid()){
          //Si la fecha es valida y la prueba tiene hard_start y horario se asigna la hora
          //Validar formato de hora (HH:MM)
          const regexTime = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
          if (prueba.hard_start == '1' && prueba.horario && regexTime.test(prueba.horario)) {
            const [hour, minute] = prueba.horario.split(':').map(Number);
            fecha.set({ hour, minute });
          } else {
            //En caso contrario se asigna la hora de inicio de la fecha
            fecha.add(5, 'minutes');
          }
          inicio = fecha.format('YYYY-MM-DD HH:mm');
        }
        pbasTmp.push({
          agrupadores: prueba.agrupadores,
          altura: prueba.altura,
          ambito: prueba.ambito,
          fecha: prueba.fecha,
          hard_start: prueba.hard_start,
          horario: prueba.horario,
          inicio,
          ipc: prueba.ipc,
          numero: prueba.numero,
          pista: nombrePista,
          prueba: prueba.prueba,
          usef_section: prueba.usef_section
        });
        pruebas.push({
          ipc: prueba.ipc,
          pista: nombrePista,
          inicio,
          numero: prueba.numero,
          nombre: prueba.prueba,
          hard_start: prueba.hard_start,
          noDate: false
        });
        n++;
      }
    }
    const pruebasUnasigned = this.pruebas.find(p => p.dia == '');
    if (pruebasUnasigned) {
      for (const p of pruebasUnasigned.pruebas) {
        pruebas.push({
          ipc: p.ipc,
          pista: '',
          inicio: '',
          numero: p.numero,
          nombre: p.prueba,
          hard_start: '0',
          noDate: true
        });
      }
    }

    const response = await firstValueFrom(this.pruebaService.actualizarPruebasHunters(pruebas)).catch(error => ({ error: true, message: 'It was not possible to update the classes.' }));
    if(response.error) {
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      this.loading.set('saving', false);
      return;

    }
    const index = this.pruebas.findIndex(p => p.dia == this.fechaSeleccionada);
    if (index > -1) {
      this.pruebas[index].pruebas = pbasTmp;
    }
    this.appService.updateClasses(this.idConcurso);
    this.getPruebas(true);
    this.loading.set('saving', false);

    const logData: string = `<p>Show: ${sessionStorage.nombreConcurso}</p><p>User: ${this.sessionData.nombre}</p><p>Component: ${'Class Planner'}</p>`;
    const responseMail = await firstValueFrom(this.emailService.send('Updated class', logData));
    console.log(responseMail);
  }

  public asignarHardStart(e, prueba): void {
    //Validar formato de hora (HH:MM)
    const regexTime = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
    if (regexTime.test(e.target.value)) {
      prueba.mostrarHora = false;
      prueba.hard_start = '1';
    } else {
      e.target.value = '';
      prueba.mostrarHora = false;
      prueba.hard_start = '0';
    }
  }

  public agregarPrueba(): void {
    const numero = this.eventReassign?.target?.['value'];
    let index1: any = -1;
    let index2: any = -1;
    for (const i in this.pruebas) {
      const index = this.pruebas[i].pruebas.findIndex(p => p.numero == numero);
      if (index > -1) {
        index1 = i;
        index2 = index;
        break;
      }
    }

    if (index1 == -1 || index2 == -1) {
      if (this.eventReassign?.target) {
        this.eventReassign.target['value'] = '';
      }
      $.NotificationApp.send("Error", 'It was not possible to update the classes.', 'bottom-right', '#fa5c7c', 'error');
      this.closeReassignModal();
      return;
    }

    let index3: any = -1;
    let index4: any = -1;
    for (const i in this.pistas) {
      const ind = this.pistas[i].pruebas.findIndex(p => p.numero == numero);
      if (ind > -1) {
        index3 = i;
        index4 = ind;
        break;
      }
    }

    if (index3 > -1 && index4 > -1) {
      this.pistas[index3].pruebas.splice(index4, 1);
      if (index4 == 0 && this.pistas[index3].pruebas[index4]) {
        if (this.pistas[index3].pruebas[index4].hard_start != '1' || !this.pistas[index3].pruebas[index4].horario) {
          this.pistas[index3].pruebas[index4].horario = '08:00';
        }
        this.pistas[index3].pruebas[index4].hard_start = '1';
      }
    }

    //Aqui guarda la prueba
    let prueba = this.pruebas[index1].pruebas[index2];
    const indexPista = this.pistas.findIndex(p => p.nombre == this.pistaReassign);
    if (this.pistas[indexPista].pruebas.length == 0) {
      prueba.hard_start = '1';
      prueba.horario = '08:00';
    } else {
      prueba.hard_start = '0';
      prueba.horario = '';
    }
    this.pistas[indexPista].pruebas.push(prueba);
    //borrar de pruebas sin asignar si es que esta en la lista
    const indexUnassigned = this.pruebasSinAsignar.findIndex(p => p.numero == numero);
    if (indexUnassigned > -1 && this.fechaSeleccionada) {
      this.pruebasSinAsignar.splice(indexUnassigned, 1);
    }
    if (this.eventReassign?.target) {
      this.eventReassign.target['value'] = '';
    }
    this.closeReassignModal();
  }

  public unassignClass(ipc, pista): void {
    const indexPruebas = this.pruebas.findIndex(p => p.dia == this.fechaSeleccionada);
    const indexPrueba = this.pruebas[indexPruebas].pruebas.findIndex(p => p.ipc == ipc);
    let prueba = this.pruebas[indexPruebas].pruebas.splice(indexPrueba, 1).pop();
    prueba.horario = null;
    prueba.fecha = null;
    let indexUnassigned = this.pruebas.findIndex(p => p.dia == '');
    if(indexUnassigned == -1 ) {indexUnassigned = 0;}
    this.pruebas[indexUnassigned].pruebas.push(prueba);
    if (this.fechaSeleccionada) {
      const indexPistas = this.pistas.findIndex(p => p.nombre == pista);
      const indexPruebaPista = this.pistas[indexPistas].pruebas.findIndex(p => p.ipc == ipc);
      this.pistas[indexPistas].pruebas.splice(indexPruebaPista, 1);
    }
  }

  public modalConfirmar(e, pista): void {
    console.log('Modal confirm called with event:', e);
    this.eventReassign = e;
    this.pistaReassign = pista;
    const numero = this.eventReassign?.target?.['value'];
    if (!numero) return;
    
    const index = this.pruebasSinAsignar.findIndex(p => p.numero == numero);
    //Consulta reasignacion
    if (index == -1) { // Si es reasignacion
      console.log('reassign');
      let existe = false;
      for (const dia of this.pruebas) {
        if (dia.dia) {
          const p = dia.pruebas.find(p => p.numero == numero);
          if (p) {
            this.fechaReassign = p.inicio;
            this.pistaAnteriorReassing = p.pista;
            existe = true;
          }
        }
      }
      if (existe) {
        console.log('si existe');
        this.showReassignModal = true;
      } else {
        console.log('no existe');
        if (this.eventReassign?.target) {
          this.eventReassign.target['value'] = '';
        }
        $.NotificationApp.send("Error", 'It was not possible to update the classes.', 'bottom-right', '#fa5c7c', 'error');
      }
    } else { //Es una prueba sin asignar
      this.agregarPrueba();
    }
  }

  public closeReassignModal(): void {
    this.showReassignModal = false;
    if (this.eventReassign?.target) {
      this.eventReassign.target['value'] = '';
    }
  }

  public showInfoCaballo(e) {
    this.mostrarInfoCaballo = e.target.checked;
  }

  public showPaisJinete(e) {
    this.mostrarPaisJinete = e.target.checked;
  }

  public printClasses(classes): void {
    this.printService.printClasses(this.idConcurso, classes.map(c => c.ipc), this.mostrarInfoCaballo, this.mostrarPaisJinete);
  }

  public printClassSheets(classes): void {
    this.printService.printClassSheets(this.idConcurso, classes.map(c => c.ipc), this.mostrarInfoCaballo, this.mostrarPaisJinete);
  }
}
