import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SeasonPassService, SeasonPass, SeasonPassEntry } from '../services/season-pass.service';
import { EntryEditDialogComponent } from './dialogs/entry-edit-dialog.component';
import { ConcursoService } from '../services/concurso.service';

@Component({
  selector: 'app-season-passes',
  templateUrl: './season-passes.component.html',
  styleUrls: ['./season-passes.component.scss'],
})
export class SeasonPassesComponent implements OnInit {
  passes: SeasonPass[] = [];
  selectedPass: SeasonPass | null = null;
  loading = true;
  error: string | null = null;
  concurso: ConcursoNest;
  idConcurso: string;
  constructor(
    private seasonPassService: SeasonPassService,
    private dialog: MatDialog,
    private concursoService: ConcursoService
  ) {
    this.idConcurso = sessionStorage.getItem('concurso')|| '';
  }

  ngOnInit(): void {
    this.loadPasses();
    this.getConcurso();
  }

  getConcurso(): void {
    this.concursoService.getConcursoNest(this.idConcurso).subscribe((concurso) => {
      this.concurso = concurso;
    });
  }

  private loadPasses(): void {
    this.loading = true;
    this.seasonPassService.getSeasonPasses().subscribe({
      next: (passes) => {
        this.passes = passes;
        this.loading = false;
        console.log(this.passes);
      },
      error: (err) => {
        this.error = 'Failed to load season passes';
        console.error('Error loading passes:', err);
        this.loading = false;
      }
    });
  }

  selectPass(pass: SeasonPass): void {
    this.selectedPass = pass;
  }

  closeDetails(): void {
    this.selectedPass = null;
  }

  createNewPass(): void {
    console.log('Creating new pass');
  }

  addEntry(): void {
    if (!this.selectedPass) return;
    
    const dialogRef = this.dialog.open(EntryEditDialogComponent, {
      width: '500px',
      data: {
        entry: null,
        passId: this.selectedPass.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!this.selectedPass) return;
        
        // Add the new entry to the local array first for immediate UI update
        const updatedPass = {
          ...this.selectedPass,
          entries: [...this.selectedPass.entries, result]
        };
        
        // Update the selected pass and the passes array
        this.selectedPass = updatedPass;
        this.passes = this.passes.map(p => 
          p.id === updatedPass.id ? updatedPass : p
        );
        
        // Store the passId in a variable to ensure it's available
        const passId = this.selectedPass.id;
        
        // Add debugging information
        console.log('Debug - Selected Pass:', this.selectedPass);
        console.log('Debug - Pass ID:', passId);
        console.log('Debug - Entry to add:', result);
        
        // Update in Firestore with explicit passId
        this.seasonPassService.updateSeasonPassEntry(passId, result)
          .catch(error => {
            console.error('Error adding entry:', error);
            this.error = 'Failed to add entry';
          });
      }
    });
  }

  deleteEntry(entry: SeasonPassEntry): void {
    console.log('Deleting entry', entry);
    // TODO: Add confirmation dialog and implement delete functionality
  }

  editEntry(entry: SeasonPassEntry): void {
    if (!this.selectedPass) return;
    
    const dialogRef = this.dialog.open(EntryEditDialogComponent, {
      width: '500px',
      data: {
        entry: {...entry},
        passId: this.selectedPass.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!this.selectedPass) return;
        
        // Update the entry in the local array first for immediate UI update
        const updatedEntries = this.selectedPass.entries.map(e => 
          e.entry === result.entry ? result : e
        );
        
        const updatedPass = {
          ...this.selectedPass,
          entries: updatedEntries
        };
        
        // Update the selected pass and the passes array
        this.selectedPass = updatedPass;
        this.passes = this.passes.map(p => 
          p.id === updatedPass.id ? updatedPass : p
        );
        
        // Store the passId in a variable to ensure it's available
        const passId = this.selectedPass.id;
        
        // Add debugging information
        console.log('Debug - Selected Pass:', this.selectedPass);
        console.log('Debug - Pass ID:', passId);
        console.log('Debug - Entry to update:', result);
        
        // Update in Firestore with explicit passId
        this.seasonPassService.updateSeasonPassEntry(passId, result)
          .catch(error => {
            console.error('Error updating entry:', error);
            this.error = 'Failed to update entry';
          });
      }
    });
  }
} 