import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { CircuitsComponent } from './circuits.component';
import { AddCircuitsComponent } from './add-circuit/add-circuit.component';
import { CircuitsService } from '../services/circuits.service';

@NgModule({
  declarations: [
    CircuitsComponent,
    AddCircuitsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  exports: [
    CircuitsComponent
  ],
  providers: [
    CircuitsService
  ]
})
export class CircuitsModule { } 