import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPais'
})
export class FilterPaisPipe implements PipeTransform {
  transform(value: Array<any>, query: any): any {
    if(query){
      if(typeof(query) == 'string'){
        return value.filter((pais) => {
          const name = pais.country || pais.pais;
          return name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      }
      return value;
    }
    return value;
  }
}
