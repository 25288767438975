import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { PruebaService } from '../services/prueba.service';
import { ModalidadService } from '../services/modalidad.service';
import { JuezService } from '../services/juez.service';
import { CategoriaService } from '../services/categoria.service';
import { ConcursoService } from '../services/concurso.service';
import { ClubService } from '../services/club.service';
import { PaisService } from '../services/pais.service';
import { ConceptoService } from '../services/concepto.service';
import { PersonaService } from '../services/persona.service';
import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AppService } from '../services/app.service';
import { formatDate } from '@angular/common';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import 'firebase/compat/storage';
import { firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ClassFormDialogComponent } from '../shared/components/class-form/class-form-dialog.component';
declare var $: any;
declare var bootstrap: any;

@Component({
  selector: 'convocatoria',
  templateUrl: './convocatoria.component.html',
  styleUrls: ['./convocatoria.component.css']
})

export class ConvocatoriaComponent implements OnInit, AfterViewInit {
  private idUsuario: string;
  public idConcurso: string | boolean;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  private ipcSeleccionado: number;
  public nombreConcurso: string;
  public pruebas: Array<PruebaNest>;
  public nombreSeleccionado: string;
  public modalidades: Array<any>;
  public es: any;
  public jueces: Array<any>;
  public juecesPistas: any;
  public categorias: Array<any>;
  public formPrueba: UntypedFormGroup;
  public categoriasPrueba: Array<string>;
  public nombreControl: UntypedFormControl;
  public clubControl: UntypedFormControl;
  public inicioControl: UntypedFormControl;
  public finControl: UntypedFormControl;
  public nominacionControl: UntypedFormControl;
  public deduccionControl: UntypedFormControl;
  public salesTaxPercentControl: UntypedFormControl;
  public desinscripcion1Control: UntypedFormControl;
  public desinscripcion2Control: UntypedFormControl;
  public feiFee1Control: UntypedFormControl;
  public feiFee2Control: UntypedFormControl;
  public feiFee3Control: UntypedFormControl;
  public correoControl: UntypedFormControl;
  public usefIDControl: UntypedFormControl;
  public officialIDControl: UntypedFormControl;
  public publicoControl: UntypedFormControl;
  public edControl: UntypedFormControl;
  public videosForSale: UntypedFormControl;
  public inscripcionesRemotasControl: UntypedFormControl;
  public pagoConChequeControl: UntypedFormControl;
  public salesTaxControl: UntypedFormControl;
  public feedOrdersSalesTaxControl: UntypedFormControl;
  public merchSalesTaxControl: UntypedFormControl;
  public divisionChampsControl: UntypedFormControl;
  public horarioCierreOnlineControl: UntypedFormControl;
  public entriesDue: UntypedFormControl;
  public entryAmount: UntypedFormControl;
  public timezone: UntypedFormControl;
  public zonaHora: string;
  public prizeCurrency: UntypedFormControl;
  public organizingCountry: UntypedFormControl;
  public buttonLink: UntypedFormControl;
  public buttonLink2: UntypedFormControl;
  public titleLink: UntypedFormControl;
  public titleLink2: UntypedFormControl;
  public clubs: Array<any>;
  public queryClub: string;
  public imgPatrocinador: string;
  public multicosto: boolean;
  public costo: any;
  public porcentajeSubida: number;
  public subiendoPortada: boolean;
  public subiendoHeader: boolean;
  public subiendoFooter: boolean;
  public subiendoLogo: boolean;
  public subiendoInvoiceFooter: boolean;
  public portada: string;
  public header: string;
  public footer: string;
  public logo: string;
  public convocatoria: string;
  public schedule: string;
  public feedForm: string;
  public entryBlank: string;
  public facilityMap: string;
  public invoiceFooter: string;
  public baseUrl: string;
  public croquis: string;
  public paises: any[];
  public conceptos: any[];
  public conceptoSeleccionado: string;
  public cargosPrueba: any[];
  public distribuciones: any[];
  public pistaSeleccionada: string;
  public pistas: string[];
  public actualizarPruebasConcurso: boolean;
  public banderaInicio: boolean;
  public banderaFin: boolean;
  public personas: any[];
  public reining: boolean;
  public concurso: Concurso;
  //Format dd/mm/yyyy
  public dateFormatRegEx: RegExp;
  public formButtons: FormGroup;
  public linkButtons: Map<string, string>[];
  ecIDControl = new FormControl('');
  public showType: FormControl;
  selectedClass: any = null;

  currentSort = {
    column: '',
    direction: 'asc' as 'asc' | 'desc'
  };

  constructor(
    private router: Router,
    private authService: AuthService,
    private pruebaService: PruebaService,
    private modalidadService: ModalidadService,
    private juezService: JuezService,
    private categoriaService: CategoriaService,
    private concursoService: ConcursoService,
    private clubService: ClubService,
    private paisService: PaisService,
    private conceptoService: ConceptoService,
    private appService: AppService,
    private personaService: PersonaService,
    private ngZone: NgZone,
    private db: AngularFirestore,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.juecesPistas = [];
    this.idUsuario = '';
    this.idConcurso = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.pruebas = [];
    this.ipcSeleccionado = null;
    this.nombreSeleccionado = '';
    this.modalidades = [];
    this.es = {
      firstDayOfWeek: 0,
      dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "I", "J", "V", "S"],
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      today: 'Hoy',
      clear: 'Borrar'
    }
    this.jueces = [];
    this.categorias = [];
    this.formPrueba = new UntypedFormGroup({
      nombre: new UntypedFormControl('', [Validators.required]),
      numero: new UntypedFormControl('', [Validators.required]),
      altura: new UntypedFormControl('', []),
      modalidad: new UntypedFormControl('', [Validators.required]),
      //ambito: new FormControl('', [Validators.required]),
      description: new UntypedFormControl('', []),
      pista: new UntypedFormControl('', []),
      costo: new UntypedFormControl('', []),
      premio: new UntypedFormControl('', []),
      prize_distribution: new UntypedFormControl('', []),
      inicio: new UntypedFormControl('', []),
      hora_inicio: new UntypedFormControl('', []),
      juez: new UntypedFormControl('', []),
      video: new UntypedFormControl(false, []),
      designer: new UntypedFormControl('', []),
      youtube: new UntypedFormControl('', []),
      fei_competition_id: new UntypedFormControl('', []),
      fei_event_id: new UntypedFormControl('', []),
      usef_section: new UntypedFormControl('', []),
      hard_start: new UntypedFormControl(false, []),
      overall: new UntypedFormControl(false, []),
      height_unit: new UntypedFormControl('M', []),
      attachmentUrl: new UntypedFormControl('', []),
      attachmentTxt: new UntypedFormControl('', []),
    });
    this.categoriasPrueba = [];
    this.nombreControl = new UntypedFormControl('', [Validators.required]);
    this.clubControl = new UntypedFormControl('', [Validators.required]);
    this.inicioControl = new UntypedFormControl('', []);
    this.finControl = new UntypedFormControl('', []);
    this.nominacionControl = new UntypedFormControl('', [Validators.required]);
    this.deduccionControl = new UntypedFormControl('', [Validators.required]);
    this.salesTaxPercentControl = new UntypedFormControl('', [Validators.required]);
    this.desinscripcion1Control = new UntypedFormControl('', [Validators.required]);
    this.desinscripcion2Control = new UntypedFormControl('', [Validators.required]);
    this.feiFee1Control = new UntypedFormControl('', []);
    this.feiFee2Control = new UntypedFormControl('', []);
    this.feiFee3Control = new UntypedFormControl('', []);
    this.correoControl = new UntypedFormControl('', []);
    this.usefIDControl = new UntypedFormControl('', []);
    this.officialIDControl = new UntypedFormControl('', []);
    this.publicoControl = new UntypedFormControl('', []);
    this.edControl = new UntypedFormControl('', []);
    this.videosForSale = new UntypedFormControl('', []);
    this.inscripcionesRemotasControl = new UntypedFormControl('', []);
    this.pagoConChequeControl = new UntypedFormControl(false, []);
    this.salesTaxControl = new UntypedFormControl(false, []);
    this.feedOrdersSalesTaxControl = new UntypedFormControl(false, []);
    this.merchSalesTaxControl = new UntypedFormControl(false, []);
    this.divisionChampsControl = new UntypedFormControl(false, []);
    this.horarioCierreOnlineControl = new UntypedFormControl('', [Validators.minLength(5)]);
    this.dateFormatRegEx = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([12]\d{3})$/;
    this.entriesDue = new UntypedFormControl('', [Validators.minLength(10), Validators.pattern(this.dateFormatRegEx)]);
    this.entryAmount = new UntypedFormControl('', [Validators.required]);
    this.timezone = new UntypedFormControl('America/Chicago', [Validators.required]);
    this.zonaHora = '-07:00';
    this.prizeCurrency = new UntypedFormControl('', []);
    this.organizingCountry = new UntypedFormControl('', []);
    this.buttonLink = new UntypedFormControl('', [Validators.maxLength(255)]);
    this.buttonLink2 = new UntypedFormControl('', [Validators.maxLength(255)]);
    this.titleLink = new UntypedFormControl('', [Validators.maxLength(32)]);
    this.titleLink2 = new UntypedFormControl('', [Validators.maxLength(32)]);
    this.clubs = [];
    this.queryClub = '';
    this.imgPatrocinador = '';
    this.multicosto = false;
    this.costo = {};
    this.subiendoPortada = false;
    this.subiendoHeader = false;
    this.subiendoFooter = false;
    this.subiendoLogo = false;
    this.subiendoInvoiceFooter = false;
    this.porcentajeSubida = 0;
    this.convocatoria = '';
    this.schedule = '';
    this.feedForm = '';
    this.entryBlank = '';
    this.facilityMap = '';
    this.invoiceFooter = '';
    this.baseUrl = window.location.hostname;
    this.croquis = '';
    this.paises = [];
    this.conceptos = [];
    this.conceptoSeleccionado = '';
    this.cargosPrueba = [];
    this.distribuciones = [];
    this.pistaSeleccionada = '';
    this.pistas = [];
    this.actualizarPruebasConcurso = false;
    this.banderaInicio = false;
    this.banderaFin = false;
    this.personas = [];
    this.reining = sessionStorage.getItem('reining') == '1';
    this.formButtons = this.fb.group({
      link: ['', Validators.required],
      title: ['', Validators.required],
    });
    this.linkButtons = [];
    this.showType = new FormControl('', []);
  }

  ngOnInit() {
    window['angularComponentReference'] = { component: this, zone: this.ngZone, loadAngularFunctionInicioChange: (inicio) => this.inicioChange(inicio), loadAngularFunctionFinChange: (fin) => this.finChange(fin) };
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
      this.idUsuario = String(this.sessionData['idUsuario']);
    }
    if (this.authService.validarConcurso() && this.privilegios >= 200) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getPruebas();
    this.getModalidades();
    this.getJueces();
    this.getCategorias();
    this.getConcurso();
    this.getClubs();
    this.getPaises();
    this.getConceptos();
    this.clubControl.valueChanges.subscribe(
      data => {
        this.queryClub = data;
      }
    );
    this.getDistributions();
    this.getPistas();
  }

  ngAfterViewInit(): void {
    this.enableTooltips();
  }

  /**
   * Enable bootstrap tooltips everywhere
   */
  async enableTooltips() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => {
      tooltipTriggerEl.onclick = () => {
        $(tooltipTriggerEl).tooltip('hide');
      };
      return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover click' })
    });
    return tooltipList;
  }

  private getPistas(): void {
    this.concursoService.getPistas(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.pistas = response.pistas;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the rings list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getDistributions(): void {
    this.pruebaService.getPrizeDistributions(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.distribuciones = [];
          for (let distribucion of response.distribuciones) {
            if (distribucion.tipo == 'percentage') {
              distribucion.distribucion = distribucion.distribucion.map(d => d * 100);
              this.distribuciones.push(distribucion);
            } else {
              this.distribuciones.push(distribucion);
            }
          }
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to save the prize distribution.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  private getPruebas() {
    this.pruebaService.getPruebasNest(this.idConcurso).subscribe(
      response => {
        if (!response.error) {

          this.juecesPistas = [];
          this.pruebas = response.data.map(prueba => {
            if (prueba.multicosto) {
              prueba.costo = JSON.parse(prueba.costo);
            } else {
              prueba.costo = parseFloat(prueba.costo);
            }
            if (prueba.juez && prueba.pista) {
              const judge = this.jueces.find(juez => juez.id == prueba.juez);
              const pistaIndex = this.juecesPistas.findIndex(item => item.pista === prueba.pista);
              if (pistaIndex === -1) {
                this.juecesPistas.push({ pista: prueba.pista, jueces: [judge?.nombre] });
              } else {
                if (judge && !this.juecesPistas[pistaIndex].jueces.includes(judge.nombre)) {
                  this.juecesPistas[pistaIndex].jueces.push(judge.nombre);
                }
              }
            }
            return prueba;
          });
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the classes list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getModalidades() {
    this.modalidadService.getModalidades().subscribe(
      response => {
        if (!response.error) {
          this.modalidades = response.modalidades;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to consult the tables list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getJueces() {
    this.juezService.getJueces().subscribe(
      response => {
        if (!response.error) {
          this.jueces = response.data;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the judges list', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getCategorias() {
    this.categoriaService.getCategorias().subscribe(
      response => {
        if (!response.error) {
          this.categorias = response.categorias;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to consult the categories list.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getConcurso() {
    firstValueFrom(this.concursoService.getConcursoNest(this.idConcurso)).then(
      response => {
        if (!response.error) {
          this.concurso = response.data.concurso;
          this.nombreControl.setValue(this.concurso['fullname']);
          this.clubControl.setValue({
            id_club: this.concurso['id_club'],
            club: this.concurso['club'].fullname // TODO: Add field to show response
          });
          let inicio = this.concurso['inicio'];
          let fin = this.concurso['fin'];

          // Format dates as MM/DD/YYYY for the input controls
          const formatDate = (dateStr: string | null) => {
            if (!dateStr) return '';
            const date = new Date(dateStr);
            return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
          };

          this.inicioControl.setValue(formatDate(inicio));
          this.finControl.setValue(formatDate(fin));
          this.nominacionControl.setValue(this.concurso['nominacion']);
          this.deduccionControl.setValue(this.concurso['deduccion']);
          this.salesTaxPercentControl.setValue(this.concurso['sales_tax_percent']);
          this.salesTaxControl.setValue(this.concurso['sales_tax']);   
          this.desinscripcion1Control.setValue(this.concurso['desinscripcion_1']);
          this.desinscripcion2Control.setValue(this.concurso['desinscripcion_2']);
          this.feiFee1Control.setValue(this.concurso['feiFee1']);
          this.feiFee2Control.setValue(this.concurso['feiFee2']);
          this.feiFee3Control.setValue(this.concurso['feiFee3']);
          this.correoControl.setValue(this.concurso['correo_cuentas_responsable']);
          this.usefIDControl.setValue(this.concurso['usef_id']);
          this.ecIDControl.setValue(this.concurso['ec_id']);
          this.officialIDControl.setValue(this.concurso['id_oficial']);
          this.publicoControl.setValue(this.concurso['publico'] ? true : false);
          this.edControl.setValue(this.concurso['en_ed'] ? true : false);
          this.videosForSale.setValue(this.concurso['videos_for_sale'] ? true : false);
          this.inscripcionesRemotasControl.setValue(this.concurso['inscripciones_remotas'] ? true : false);
          this.pagoConChequeControl.setValue(this.concurso['pago_cheque']);
          this.feedOrdersSalesTaxControl.setValue(this.concurso['feed_order_sales_tax']);
          this.merchSalesTaxControl.setValue(this.concurso['merch_sales_tax']);
          this.divisionChampsControl.setValue(this.concurso['division_champs']);
          this.horarioCierreOnlineControl.setValue(this.concurso['cierre_inscripciones_online']);
          this.entriesDue.setValue(formatDate(this.concurso['entries_due'] || ''));
          this.entryAmount.setValue(this.concurso['entry_amount']);
          this.timezone.setValue(this.concurso['timezone']);
          this.zonaHora = this.getTimeZone();
          this.prizeCurrency.setValue(this.concurso['prize_currency']);
          this.organizingCountry.setValue(this.concurso['organizing_country']);
          this.titleLink.setValue(this.concurso['buttonTitle']);
          this.titleLink2.setValue(this.concurso['buttonTitle2']);
          this.buttonLink.setValue(this.concurso['buttonLink']);
          this.buttonLink2.setValue(this.concurso['buttonLink2']);
          this.portada = this.concurso.imagen ? this.concurso.imagen : 'portada.jpg';
          this.header = this.concurso.img_encabezado ? this.concurso.img_encabezado : 'header.jpg';
          this.footer = this.concurso.img_footer ? this.concurso.img_footer : 'footer.jpg';
          this.logo = this.concurso.img_encabezado_2 ? this.concurso.img_encabezado_2 : 'header2.jpg';
          this.invoiceFooter = this.concurso.invoice_footer ? this.concurso.invoice_footer : 'invoiceFooter.jpg';
          this.convocatoria = this.concurso.convocatoria;
          this.schedule = this.concurso.schedule;
          this.feedForm = this.concurso.feed_form;
          this.entryBlank = this.concurso.entry_blank;
          this.facilityMap = this.concurso.facilityMap;
          this.invoiceFooter = this.concurso.invoice_footer;
          this.pistas = this.concurso.pistas;
          this.linkButtons = (this.concurso.buttons || []).map(b => new Map<string, string>([['title', b.title || ''], ['link', b.link || '']]));
          this.showType.setValue(this.concurso['type']);
          setTimeout(() => { this.enableTooltips(); });
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It was not possible to consult the show data.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getClubs() {
    this.clubService.getClubs().subscribe(
      response => {
        if (!response.error) {
          this.clubs = response.clubs;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It was not possible to consult the clubs list', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  /**
   * Get the countries list ordered by country name (field PAIS)
   */
  async getPaises() {
    const response = await firstValueFrom(this.paisService.getPaisesNest()).catch(error => ({
      error: true,
      message: 'It was not possible to consult the countries list'
    }));
    if (!response.error) {
      this.paises = response.data;
    } else {
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      console.log(response.message);
    }
  }

  public getConceptos(): void {
    this.conceptoService.getConceptos(this.idConcurso).subscribe(
      response => {
        this.conceptos = response.conceptos;
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the concepts list.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public displayClub(val): string {
    return val ? val.club : val;
  }

  public modalEditar(prueba) {
    console.log(prueba);
    //Cambiar prueba seleccionada
    this.ipcSeleccionado = prueba['ipc'];
    this.imgPatrocinador = prueba['sponsorImage'];
    this.croquis = prueba['croquis'];
    this.actualizarPruebasConcurso = false;
    //Limpiar el arreglo de personas(designers) al abrir el modal
    this.personas = [];
    //Falta Cambiar array de categorias seleccionadas
    this.categoriasPrueba = prueba['categorias'].map(categoria => {
      return String(categoria);
    });
    //Asignar valor multicosto a variable global y en caso de ser true asignar costo a variable global
    this.multicosto = prueba.multicosto;
    if (this.multicosto) {
      this.costo = prueba.costo;
    } else {
      this.costo = {};
    }
    // const [inicio, hora_inicio] = prueba['inicio'].split(' ');
    const [inicio, hora_inicio] = prueba['inicio'] ? prueba['inicio'].split(' ') : ['', ''];

    //Limpiar formulario
    this.formPrueba.setValue({
      nombre: prueba['nombre'] || '',
      numero: prueba['numero'] || '',
      modalidad: prueba['modalidad'] || '',
      altura: prueba['altura'] || '',
      description: prueba['description'] || '',
      pista: prueba['pista'] || '',
      costo: !prueba.multicosto ? prueba['costo'] : '',
      premio: prueba['premio'] || '',
      inicio: inicio || '',
      hora_inicio: hora_inicio || '',
      juez: prueba['juez'] || '',
      video: !!prueba['video'] || '',
      designer: prueba['designer'] || '',
      youtube: prueba['youtube'] || '',
      fei_competition_id: prueba['competitionID'] || '',
      fei_event_id: prueba['eventId'] || '',
      usef_section: prueba['usef_section'] || '',
      hard_start: prueba['hard_start'] || '',
      overall: prueba['overall'] || '',
      prize_distribution: prueba['id_prize_distribution'] || '',
      height_unit: prueba['height_unit'] || '',
      attachmentUrl: prueba['attachmentUrl'] || '',
      attachmentTxt: prueba['attachmentTxt'] || '',
    });
    this.conceptoSeleccionado = '';
    this.cargosPrueba = prueba['charges'] ? prueba['charges'] : ( prueba['cargosPrueba'] ? prueba['cargosPrueba'] : []);

    $('#modal-prueba').modal('show');
  }

  public validarCategoria(idCategoria): boolean {
    return this.categoriasPrueba.indexOf(idCategoria) > -1 ? true : false;

  }

  public toggleCategoria(idCategoria: string) {
    const index = this.categoriasPrueba.indexOf(idCategoria);
    if (index > -1) {
      //Borrar categoria de array de categorias prueba
      this.categoriasPrueba.splice(index, 1);
      //Hacer validaciones para el multicosto
      if (this.categoriasPrueba.length > 1) {
        if (this.multicosto) { //Si ya es multicosto borrar valor del json de costo
          delete this.costo[idCategoria];
        } else { //Si aun no era multicosto crear json con categorias
          this.multicosto = true;
          this.costo = {};
          for (const categoria of this.categoriasPrueba) {
            this.costo[categoria] = '';
          }
        }
      } else { //Si queda con una categoria o menos no es multicosto
        this.multicosto = false;
        this.costo = {};
      }
    } else {
      this.categoriasPrueba.push(idCategoria);
      //Hacer validaciones para el multicosto
      if (this.categoriasPrueba.length > 1) {
        if (this.multicosto) { //Si ya es multicosto solo agregar el valor al json
          this.costo[idCategoria] = '';
        } else {//Si aun no era multicosto crear json con categorias
          this.multicosto = true;
          this.costo = {};
          for (const categoria of this.categoriasPrueba) {
            this.costo[categoria] = '';
          }
        }
      } else { //Si solo se agrega una categoria se queda con un solo costo
        this.multicosto = false;
        this.costo = {};
      }
    }
  }

  public modalBorrar(ipc, numero, nombre) {
    this.ipcSeleccionado = ipc;
    this.nombreSeleccionado = `${numero} - ${nombre}`;
    $('#modal-borrar-prueba').modal('show');
  }

  public async borrarPrueba() {
    this.pruebaService.deletePrueba(this.ipcSeleccionado, this.idUsuario, this.idConcurso).subscribe(
      async response => {
        if (!response.error) {
          //this.appService.updateClass(this.idConcurso, this.ipcSeleccionado);
          await this.db.collection('concursos').doc(`us_${this.idConcurso}`).collection('pruebas').doc(`us_${this.ipcSeleccionado}`).delete();
          const index = this.pruebas.findIndex(pba => {
            return pba.id_prueba_concurso == this.ipcSeleccionado;
          });
          this.pruebas.splice(index, 1);
          $('#modal-borrar-prueba').modal('hide');
          $.NotificationApp.send("Deleted", response.message, 'bottom-right', '#06d5a1', 'success');
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.query);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'The test could not be deleted', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public nombreChange() {
    if (this.nombreControl.valid) {
      const nombre = this.nombreControl.value;
      this.guardarCampo('fullname', 'texto', nombre, this.idConcurso);
    } else {
      this.nombreControl.markAsTouched();
    }
  }

  public clubChange() {
    if (this.clubControl.valid) {
      if (this.clubControl.value['id_club']) {
        const club = this.clubControl.value['id_club'];
        this.guardarCampo('id_club', 'numero', club, this.idConcurso);
      } else {
        this.clubControl.setValue('');
        this.clubControl.markAsTouched();
      }
    } else {
      this.clubControl.markAsTouched();
    }
  }

  public inicioChange(dateInicio) {
    if (this.banderaInicio) {
      if (dateInicio.length == 10) {
        dateInicio = dateInicio.split('/');
        dateInicio = `${dateInicio[2]}-${dateInicio[0]}-${dateInicio[1]} 08:00`;
        this.guardarCampo('inicio', 'texto', dateInicio, this.idConcurso);
      }
    }
    this.banderaInicio = true;
  }

  public finChange(dateFin) {
    if (this.banderaFin) {
      if (dateFin.length == 10) {
        dateFin = dateFin.split('/');
        dateFin = `${dateFin[2]}-${dateFin[0]}-${dateFin[1]} 18:00`;
        this.guardarCampo('fin', 'texto', dateFin, this.idConcurso);
      }
    }
    this.banderaFin = true;
  }

  public nominacionChange() {
    if (this.nominacionControl.valid) {
      const nominacion = this.nominacionControl.value;
      this.guardarCampo('nominacion', 'numero', nominacion, this.idConcurso);
    } else {
      this.nominacionControl.markAsTouched();
    }
  }

  public deduccionChange() {
    if (this.deduccionControl.valid) {
      const deduccion = this.deduccionControl.value;
      this.guardarCampo('deduccion', 'numero', deduccion, this.idConcurso);
    } else {
      this.deduccionControl.markAsTouched();
    }
  }

  public salesTaxPercentChange() {
    if (this.salesTaxPercentControl.valid) {
      const salesTaxPercent = this.salesTaxPercentControl.value;
      this.guardarCampo('sales_tax_percent', 'numero', salesTaxPercent, this.idConcurso);
    } else {
      this.salesTaxPercentControl.markAsTouched();
    }
  }

  public desinscripcion1Change() {
    if (this.desinscripcion1Control.valid) {
      const desinscripcion1 = this.desinscripcion1Control.value;
      this.guardarCampo('desinscripcion_1', 'numero', desinscripcion1, this.idConcurso);
    } else {
      this.desinscripcion1Control.markAsTouched();
    }
  }

  public desinscripcion2Change() {
    if (this.desinscripcion2Control.valid) {
      const desinscripcion2 = this.desinscripcion2Control.value;
      this.guardarCampo('desinscripcion_2', 'numero', desinscripcion2, this.idConcurso);
    } else {
      this.desinscripcion2Control.markAsTouched();
    }
  };

  public feiFee1Change() {
    if (this.feiFee1Control.valid) {
      const feiFee1 = this.feiFee1Control.value ? this.feiFee1Control.value : 0;
      this.guardarCampo('fei_fee_1', 'numero', feiFee1, this.idConcurso);
    } else {
      this.feiFee1Control.markAsTouched();
    }
  }

  public feiFee2Change() {
    if (this.feiFee2Control.valid) {
      const feiFee2 = this.feiFee2Control.value ? this.feiFee2Control.value : 0;
      this.guardarCampo('fei_fee_2', 'numero', feiFee2, this.idConcurso);
    } else {
      this.feiFee2Control.markAsTouched();
    }
  }

  public feiFee3Change() {
    if (this.feiFee3Control.valid) {
      const feiFee3 = this.feiFee3Control.value ? this.feiFee3Control.value : 0;
      this.guardarCampo('fei_fee_3', 'numero', feiFee3, this.idConcurso);
    } else {
      this.feiFee3Control.markAsTouched();
    }
  }

  public correoChange() {
    const correo = this.correoControl.value;
    this.guardarCampo('correo_cuentas_responsable', 'texto', correo, this.idConcurso);
  }

  public officialIDChange() {
    const idOficial = this.officialIDControl.value;
    this.guardarCampo('id_oficial', 'texto', idOficial, this.idConcurso);
  }

  public usefIDChange() {
    const usefID = this.usefIDControl.value;
    this.guardarCampo('usef_id', 'texto', usefID, this.idConcurso);
  }

  public ecIDChange() {
    const ecID = this.ecIDControl.value;
    this.guardarCampo('ec_id', 'texto', ecID, this.idConcurso);
  }

  public publicoChange() {
    const publico = this.publicoControl.value ? 1 : 0;
    this.guardarCampo('publico', 'numero', publico, this.idConcurso);
  }

  public edChange() {
    const ed = this.edControl.value ? 1 : 0;
    this.guardarCampo('en_ed', 'numero', ed, this.idConcurso);
  }

  public videoForSaleChange() {
    const videosForSale = this.videosForSale.value ? 1 : 0;
    this.guardarCampo('videos_for_sale', 'numero', videosForSale, this.idConcurso);
  }

  public inscripcionesRemotasChange() {
    const inscripcionesRemotas = this.inscripcionesRemotasControl.value ? 1 : 0;
    this.guardarCampo('inscripciones_remotas', 'numero', inscripcionesRemotas, this.idConcurso);
  }

  public pagoConChequeChange() {
    const pagoConCheque = this.pagoConChequeControl.value ? 1 : 0;
    this.guardarCampo('pago_cheque', 'numero', pagoConCheque, this.idConcurso);
  }

  public salesTaxChange() {
    const salesTax = this.salesTaxControl.value ? 1 : 0;
    this.guardarCampo('sales_tax', 'numero', salesTax, this.idConcurso);
  }

  public feedOrdersSalesTaxChange() {
    const feedOrdersSalesTax = this.feedOrdersSalesTaxControl.value ? 1 : 0;
    this.guardarCampo('feed_order_sales_tax', 'numero', feedOrdersSalesTax, this.idConcurso);
  }

  public merchSalesTaxChange() {
    const merchSalesTax = this.merchSalesTaxControl.value ? 1 : 0;
    this.guardarCampo('merch_sales_tax', 'numero', merchSalesTax, this.idConcurso);
  }

  public divisionChampsChange() {
    const divisionChamps = this.divisionChampsControl.value ? 1 : 0;
    this.guardarCampo('division_champs', 'numero', divisionChamps, this.idConcurso);
    //Si marcan el checkbox de division champs se debe mostrar el campo de division champs en el boton y link 1 del concurso
    if (this.divisionChampsControl.value) {
      this.titleLink.setValue('Div. Champs');
      this.titleLinkChange();
      this.buttonLink.setValue(`https://equestrian.digital/divisionChampions/us_${this.idConcurso}`);
      this.buttonLinkChange();
    }
  }

  public horarioCierreChange() {
    if (this.horarioCierreOnlineControl.valid) {
      const horarioCierre = this.horarioCierreOnlineControl.value;
      this.guardarCampo('cierre_inscripciones_online', 'texto', horarioCierre, this.idConcurso);
    }
  }

  public entriesDueChange() {
    if (this.entriesDue.valid && this.dateFormatRegEx.test(this.entriesDue.value)) {
      //const entriesDue = this.entriesDue.value.split('/').reverse().join('-');
      if (this.entriesDue.value.length == 10) {
        let entriesDue = this.entriesDue.value.split('/');
        entriesDue = `${entriesDue[2]}-${entriesDue[0]}-${entriesDue[1]} 08:00`;
        this.guardarCampo('entries_due', 'texto', entriesDue, this.idConcurso);
      }
      //this.guardarCampo('entries_due', 'texto', entriesDue, this.idConcurso);
    }
  }

  public entryAmountChange() {
    if (this.entryAmount.valid) {
      const entryAmount = this.entryAmount.value;
      this.guardarCampo('entry_amount', 'numero', entryAmount, this.idConcurso);
    }
  }

  public timeZoneChange() {
    if (this.timezone.valid) {
      const timezone = this.timezone.value;
      this.guardarCampo('timezone', 'texto', timezone, this.idConcurso);
    }
  }
  public getTimeZone() {
    if (this.timezone.value == 'America/New_York_DST') {
      return this.zonaHora = '-03:00';
    }
    if (this.timezone.value == 'America/New_York') {
      return this.zonaHora = '-04:00';
    }
    if (this.timezone.value == 'America/Chicago') {
      return this.zonaHora = '-05:00';
    }
    if (this.timezone.value == 'America/Denver') {
      return this.zonaHora = '-06:00';
    }
    if (this.timezone.value == 'America/Phoenix') {
      return this.zonaHora = '-07:00';
    }
    if (this.timezone.value == 'America/Los_Angeles') {
      return this.zonaHora = '-07:00';
    }
    if (this.timezone.value == 'America/Anchorage') {
      return this.zonaHora = '-08:00';
    }
    if (this.timezone.value == 'America/Adak') {
      return this.zonaHora = '-09:00';
    }
    if (this.timezone.value == 'Pacific/Honolulu') {
      return this.zonaHora = '-10:00';
    }
  }

  public prizeCurrencyChange() {
    if (this.prizeCurrency.valid) {
      const prize_currency = this.prizeCurrency.value;
      this.guardarCampo('prize_currency', 'texto', prize_currency, this.idConcurso);
    }
  }

  public organizingCountryChange() {
    if (this.organizingCountry.valid) {
      const organizingCountry = this.organizingCountry.value;
      this.guardarCampo('organizing_country', 'texto', organizingCountry, this.idConcurso);
    }
  }

  public buttonLinkChange() {
    if (this.buttonLink.valid) {
      const buttonLink = this.buttonLink.value;
      this.guardarCampo('buttonLink', 'texto', buttonLink, this.idConcurso);
    } else {
      $.NotificationApp.send("Error", 'The link is too long.', 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public buttonLink2Change() {
    if (this.buttonLink2.valid) {
      const buttonLink2 = this.buttonLink2.value;
      this.guardarCampo('buttonLink2', 'texto', buttonLink2, this.idConcurso);
    } else {
      $.NotificationApp.send("Error", 'The link is too long.', 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public titleLinkChange() {
    if (this.titleLink.valid) {
      const titleLink = this.titleLink.value;
      this.guardarCampo('buttonTitle', 'texto', titleLink, this.idConcurso);
    } else {
      $.NotificationApp.send("Error", 'The title is too long.', 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public titleLink2Change() {
    if (this.titleLink2.valid) {
      const titleLink2 = this.titleLink2.value;
      this.guardarCampo('buttonTitle2', 'texto', titleLink2, this.idConcurso);
    } else {
      $.NotificationApp.send("Error", 'The title is too long.', 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public showTypeChange() {
    if (this.showType.valid) {
      const showType = this.showType.value;
      this.guardarCampo('type', 'texto', showType, this.idConcurso);
    }
  }

  private guardarCampo(nombreCampo, tipoCampo, valor, idConcurso) {
    $('#loader').show();
    return firstValueFrom(this.concursoService.guardarCampo(nombreCampo, tipoCampo, valor, idConcurso)).then(
      async response => {
        $('#loader').hide();
        if (!response.error) {
          $.NotificationApp.send("Updated", "The show has been updated.", 'bottom-right', '#06d5a1', 'success');
          await this.appService.updateShowFirestore(this.idConcurso);
          return response;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          return response.message;
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'The show has not been updated', 'bottom-right', '#fa5c7c', 'error');
        return error;
      }
    );
  }

  public removerImagen(tipo) {
    switch (tipo) {
      case 'sponsor':
        this.imgPatrocinador = '';
        break;
      case 'course-desing':
        this.croquis = '';
        break;
    }
  }

  public subirImagen(event: any, tipo) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      if (((file.type === 'image/jpeg' || file.type === 'image/png') && (tipo === 'portada' || tipo === 'header' || tipo === 'footer' || tipo === 'logo' || tipo === 'invoiceFooter')) || (file.type === 'application/pdf' && tipo === 'convocatoria') || (file.type === 'application/pdf' && tipo === 'schedule') || (file.type === 'application/pdf' && tipo === 'feedForm') || (file.type === 'application/pdf' && tipo === 'entryBlank') || (file.type === 'application/pdf' && tipo === 'facilityMap')) {
        this.porcentajeSubida = 0;
        switch (tipo) {
          case 'portada':
            this.subiendoPortada = true;
            break;
          case 'header':
            this.subiendoHeader = true;
            break;
          case 'footer':
            this.subiendoFooter = true;
            break;
          case 'logo':
            this.subiendoLogo = true;
            break;
          case 'convocatoria':
            $('#loader').show();
            break;
          case 'schedule':
            $('#loader').show();
            break;
          case 'feedForm':
            $('#loader').show();
            break;
          case 'entryBlank':
            $('#loader').show();
            break;
          case 'facilityMap':
            $('#loader').show();
            break;
          case 'invoiceFooter':
            this.subiendoInvoiceFooter = true;
            break;
        }

        const ref = firebase.storage().ref(tipo).child(String(this.idConcurso));
        const task = ref.put(file);
        task.on('state_changed',
          (snapshot: any) => { //Cuando hay cambio de estado
            this.porcentajeSubida = (snapshot.bytesTransferred / snapshot.totalBytes);
          },
          (error: any) => { //Cuando hubo un error
            this.subiendoPortada = false;
            this.subiendoHeader = false;
            this.subiendoFooter = false;
            this.subiendoLogo = false;
            this.subiendoInvoiceFooter = false;
            $('#loader').hide();
            $.NotificationApp.send("Error", error.code, 'bottom-right', '#ffbc00', 'error');
            console.log(error);
          },
          () => { //Cuando la funcion termino correctamente
            task.snapshot.ref.getDownloadURL().then(
              downloadURL => {
                console.log(downloadURL);
                switch (tipo) {
                  case 'portada':
                    this.concursoService.guardarCampo('imagen', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.portada = downloadURL;
                          this.subiendoPortada = false;
                          $.NotificationApp.send("Updated", "The image of the show has been updated", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show image has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'header':
                    this.concursoService.guardarCampo('img_encabezado', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.header = downloadURL;
                          this.subiendoHeader = false;
                          $.NotificationApp.send("Updated", "The show header has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show header has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'footer':
                    this.concursoService.guardarCampo('img_footer', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.footer = downloadURL;
                          this.subiendoFooter = false;
                          $.NotificationApp.send("Updated", "The show footer has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show footer has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'logo':
                    this.concursoService.guardarCampo('img_encabezado_2', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.logo = downloadURL;
                          this.subiendoLogo = false;
                          $.NotificationApp.send("Updated", "The show logo has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show logo has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'invoiceFooter':
                    this.concursoService.guardarCampo('invoice_footer', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.invoiceFooter = downloadURL;
                          this.subiendoInvoiceFooter = false;
                          $.NotificationApp.send("Updated", "The invoice footer has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show logo has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'convocatoria':
                    this.concursoService.guardarCampo('convocatoria', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.convocatoria = downloadURL;
                          $('#loader').hide();
                          $.NotificationApp.send("Updated", "The show prize list has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show prize list has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'schedule':
                    this.concursoService.guardarCampo('schedule', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.schedule = downloadURL;
                          $('#loader').hide();
                          $.NotificationApp.send("Updated", "The show schedule has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show schedule has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'feedForm':
                    this.concursoService.guardarCampo('feed_form', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.feedForm = downloadURL;
                          $('#loader').hide();
                          $.NotificationApp.send("Updated", "The show feed form has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show feed form has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'entryBlank':
                    this.concursoService.guardarCampo('entry_blank', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.entryBlank = downloadURL;
                          $('#loader').hide();
                          $.NotificationApp.send("Updated", "The show entry blank has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show entry blank has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;
                  case 'facilityMap':
                    this.concursoService.guardarCampo('facilityMap', 'texto', downloadURL, this.idConcurso).subscribe(
                      response => {
                        if (!response.error) {
                          this.facilityMap = downloadURL;
                          $('#loader').hide();
                          $.NotificationApp.send("Updated", "The show facility map has been updated.", 'bottom-right', '#06d5a1', 'success');
                        } else {
                          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                          console.log(response.message);
                        }
                      },
                      error => {
                        $.NotificationApp.send("Error", 'The show facility map has not been updated', 'bottom-right', '#fa5c7c', 'error');
                        console.log(error);
                      }
                    );
                    break;

                  case 'invoiceFooter':
                    break;
                }
              },
              error => {
                this.subiendoPortada = false;
                this.subiendoHeader = false;
                this.subiendoFooter = false;
                this.subiendoLogo = false;
                this.subiendoInvoiceFooter = false;
                $('#loader').hide();
                $.NotificationApp.send("Error", 'The download url could not be obtained.', 'bottom-right', '#ffbc00', 'error');
                console.log(error);
              }
            );
          }
        );
      } else {
        $.NotificationApp.send("Invalid file.", 'This type of file is not allowed, please try with a JPG image.', 'bottom-right', '#ffbc00', 'warning');
      }
    }
  }

  public imagenPatrocinador(event) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      event.target.value = '';
      if (file.type === 'image/jpeg') {
        $('#loader').show();

        const ref = firebase.storage().ref('sponsor').child(`${this.ipcSeleccionado ? this.ipcSeleccionado : new Date().getTime()}`);
        const task = ref.put(file);
        task.on('state_changed',
          (snapshot: any) => { //Cuando hay cambio de estado
            this.porcentajeSubida = (snapshot.bytesTransferred / snapshot.totalBytes);
          },
          (error: any) => { //Cuando hubo un error
            $('#loader').hide();
            $.NotificationApp.send("Error", error.code, 'bottom-right', '#ffbc00', 'error');
            console.log(error);
          },
          () => { //Cuando la funcion termino correctamente
            task.snapshot.ref.getDownloadURL().then(
              downloadURL => {
                $('#loader').hide();
                this.imgPatrocinador = downloadURL;
              },
              error => {
                $('#loader').hide();
                $.NotificationApp.send("Error", 'The download url could not be obtained.', 'bottom-right', '#ffbc00', 'error');
                console.log(error);
              }
            );
          }
        );
      } else {
        $.NotificationApp.send("Invalid file.", 'This type of file is not allowed, please try with a JPG image.', 'bottom-right', '#ffbc00', 'warning');
      }
    }
  }

  public getLabelCategoria(idCategoria): string {
    const index = this.categorias.findIndex(categoria => {
      return categoria.id == idCategoria;
    });

    return this.categorias[index].siglas; //nombre o siglas
  }

  public cambioCostoCategoria(e, idCategoria) {
    const costo = e.currentTarget.value;
    this.costo[idCategoria] = costo;
  }

  public fechaSafari(fecha: string): Date | null {
    if (!fecha) return null;
    
    // Convert string date to Date object
    const date = new Date(fecha);
    
    // Check if date is valid
    if (isNaN(date.getTime())) {
      return null;
    }
    
    return date;
  }

  public archivoCroquis(event) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      event.target.value = '';
      $('#loader').show();
      const ref = firebase.storage().ref('croquis').child(`${this.ipcSeleccionado ? this.ipcSeleccionado : new Date().getTime()}`);
      const task = ref.put(file);
      task.on('state_changed',
        (snapshot: any) => { //Cuando hay cambio de estado
          this.porcentajeSubida = (snapshot.bytesTransferred / snapshot.totalBytes);
        },
        (error: any) => { //Cuando hubo un error
          $('#loader').hide();
          $.NotificationApp.send("Error", error.code, 'bottom-right', '#ffbc00', 'error');
          console.log(error);
        },
        () => { //Cuando la funcion termino correctamente
          task.snapshot.ref.getDownloadURL().then(
            downloadURL => {
              $('#loader').hide();
              this.croquis = downloadURL;
              console.log(this.croquis);
            },
            error => {
              $('#loader').hide();
              $.NotificationApp.send("Error", 'The download url could not be obtained.', 'bottom-right', '#ffbc00', 'error');
              console.log(error);
            }
          );
        }
      );
    }
  }

  public agregarCargo(): void {
    if (this.conceptoSeleccionado) {
      const concepto = this.conceptos.find(c => c.id == this.conceptoSeleccionado);
      this.cargosPrueba.push({
        id: concepto.id,
        name: concepto.nombre
      });
      this.conceptoSeleccionado = '';
    }
  }

  public removeCharge(id): void {
    const index = this.cargosPrueba.findIndex(c => c.id == id);
    this.cargosPrueba.splice(index, 1);
  }

  public filtroConceptosSinAgregar(): any[] {
    const cargos = this.cargosPrueba.map(c => c.id);
    return this.conceptos.filter(c => cargos.indexOf(c.id) === -1);
  }

  public agregarPista(): void {
    if (this.pistaSeleccionada) {
      if (this.pistas.indexOf(this.pistaSeleccionada) > -1) {
        this.pistaSeleccionada = '';
        return;
      }

      this.pistas.push(this.pistaSeleccionada);
      this.pistaSeleccionada = '';
    }
    this.guardarPistas();
  }

  public eliminarPista(i) {
    this.pistas.splice(i, 1);
    this.guardarPistas();
  }

  public guardarPistas() {
    this.guardarCampo('pistas', 'texto', JSON.stringify(this.pistas), this.idConcurso);
  }

  public cambioDiaPrueba() {
    this.actualizarPruebasConcurso = true;
    if (!this.formPrueba.value.hora_inicio) {
      this.formPrueba.get('hora_inicio').setValue('08:00');
    }
  }

  public cambioHoraPrueba() {
    this.actualizarPruebasConcurso = true;
  }

  public updateFirestoreResults(ipc) {
    this.appService.deleteEntriesAndUpdate(this.idConcurso, ipc);
  }

  public getPersonas(e, entidad) {
    //Solo hacer consulta de personas cuando la cadena tiene mas de 3 letras
    const filtro: string = e.target.value;
    if (filtro.length > 3) {
      this.personaService.getPersonasFiltradas(filtro, this.idConcurso).subscribe(
        response => {
          if (!response.error) {
            this.personas = response.personas;
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $.NotificationApp.send("Error", 'It has not been possible to query the designers, please try again.', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    } else {
      console.log('Busqueda de personas muy corta');
      this.personas = [];
    }
  }

  public displayPersona(idPersona): string {
    const designer = this.personas.find(p => p.id_persona == idPersona);

    return designer ? designer.fullname : idPersona;
  }

  async assingUserToRing(e: any, ring: any) {
    const judgeID = e.target.value;
    if (!this.pistas.includes(ring)) {
      $.NotificationApp.send("Error", `Ring ${ring} doesn't exist.`, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    const response = await this.pruebaService.assignUserToRing(ring, judgeID, this.idConcurso);
    if (!response.error) {
      this.getPruebas();
      $.NotificationApp.send("Updated", "The judge has been assigned to the ring.", 'bottom-right', '#06d5a1', 'success');
    } else {
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public returnUser(pista) {
    const juez = this.juecesPistas.find(p => p.pista == pista);
    if (!juez) return 'No judge assigned';
    return juez.jueces.length > 1 ? juez.jueces.concat() : juez.jueces[0];
  }

  async addButtonLink() {
    $('#form-buttons').addClass('was-validated');
    if (this.formButtons.valid) {
      this.formButtons.disable();
      const buttonLink = new Map<string, string>([
        ['link', this.formButtons.value.link],
        ['title', this.formButtons.value.title]
      ]);
      this.linkButtons.push(buttonLink);
      const buttons = JSON.stringify(this.linkButtons.map(b => ({ link: b.get('link'), title: b.get('title') })));
      await this.guardarCampo('buttons', 'texto', buttons, this.idConcurso);

      //Limpiar y habilitar el formulario
      $('#form-buttons').removeClass('was-validated');
      this.formButtons.enable();
      this.formButtons.reset();
      setTimeout(() => { this.enableTooltips(); });
    }
  }

  async deleteButtonLink(index: number) {
    this.linkButtons.splice(index, 1);
    const buttons = JSON.stringify(this.linkButtons.map(b => ({ link: b.get('link'), title: b.get('title') })));
    await this.guardarCampo('buttons', 'texto', buttons, this.idConcurso);
  }

  async enableEditButton(index: number) {
    this.linkButtons[index].set('edit', 'true');
    setTimeout(() => { this.enableTooltips(); });
  }

  async disableEditButton(index: number) {
    this.linkButtons[index].delete('edit');
    setTimeout(() => { this.enableTooltips(); });
  }

  async editButtonLink(index: number) {
    const title = $(`#buttonTitle${index}`).val();
    const link = $(`#buttonLink${index}`).val();
    if (!title || !link) {
      $.NotificationApp.send("Error", 'The title and link are required.', 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.linkButtons[index].set('saving', title);
    this.linkButtons[index].set('title', title);
    this.linkButtons[index].set('link', link);
    const buttons = JSON.stringify(this.linkButtons.map(b => ({ link: b.get('link'), title: b.get('title') })));
    await this.guardarCampo('buttons', 'texto', buttons, this.idConcurso);
    this.linkButtons[index].delete('saving');
    this.linkButtons[index].delete('edit');
    setTimeout(() => { this.enableTooltips(); });
  }

  onClassSave(classData: any) {
    // Handle save
  }

  onClassCancel() {
    this.selectedClass = null;
  }

  onClassImageUpload(event: {type: 'sponsor' | 'course', file: File}) {
    // Handle image upload
  }

  public editClass(prueba: PruebaNest) {
    const dialogRef = this.dialog.open(ClassFormDialogComponent, {
      width: '800px',
      data: { 
        classId: prueba.id_prueba_concurso,
        concursoId: this.idConcurso,
        divisionId: prueba.division // Since convocatoria doesn't have divisions
      }
    });
  
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.getPruebas(); // Refresh the classes list
      }
    });
  }

  public createClass() {
    const dialogRef = this.dialog.open(ClassFormDialogComponent, {
      width: '800px',
      data: { 
        classId: null,
        concursoId: this.idConcurso,
        divisionId: null
      }
    });
  
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.getPruebas(); // Refresh the classes list  
      }
    });
  }

  sortTable(column: string) {
    // If clicking the same column, toggle direction
    if (this.currentSort.column === column) {
      this.currentSort.direction = this.currentSort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      // New column, set to asc
      this.currentSort.column = column;
      this.currentSort.direction = 'asc';
    }

    this.pruebas.sort((a, b) => {
      let comparison = 0;
      
      // Handle nested properties
      switch(column) {
        case 'numero_prueba':
        // Convert to numbers for numeric comparison
        const numA = parseInt(a.numero_prueba || '0');
        const numB = parseInt(b.numero_prueba || '0');  
        comparison = numA - numB;
        break;
        case 'modalidad':
          comparison = (a.modalidad?.nombre || '').localeCompare(b.modalidad?.nombre || '');
          break;
        case 'division':
          comparison = (a.divisionData?.name || '').localeCompare(b.divisionData?.name || '');
          break;
        case 'charged_by':
          comparison = (a.divisionData?.charged_by || '').localeCompare(b.divisionData?.charged_by || '');
          break;
        default:
          comparison = String(a[column] || '').localeCompare(String(b[column] || ''));
      }

      return this.currentSort.direction === 'asc' ? comparison : -comparison;
    });
  }
}
