
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from './../../environments/environment';
import { first } from 'rxjs-compat/operator/first';

@Injectable()
export class CaballoService extends Api {

    constructor(private http: HttpClient) {
        super();
    }

    public getHorses(idConcurso): Observable<any> {
        const params = JSON.stringify({ idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/`, params, { headers: headers });
    }

    public getInfo(idCaballo): Observable<any> {
        const params = JSON.stringify({ idCaballo });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };
         return this.http.get(`${environment.api.nest.uri}caballos/${idCaballo}`);
        // return this.http.post(`${environment.api.uri}caballos/info/`, params, { headers: headers });
    }

    public editHorse(horse, idCaballo, idConcurso, idUsuario): Observable<any> {
        const params = JSON.stringify({ horse, idCaballo, idConcurso, idUsuario });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/edit/`, params, { headers: headers });
    }

    public save(horse): Promise<any> {
        const params = JSON.stringify(horse);
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return firstValueFrom(this.http.post(`${environment.api.nest.uri}caballos/save`, params, { headers: headers }));
    }

    public getCaballoFEM(caballo): Observable<any> {
        const params = JSON.stringify({ fem: caballo });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}fem/caballos/`, params, { headers: headers });
    }

    public addCaballo(caballo, idConcurso, idUsuario): Observable<any> {
        const params = JSON.stringify({ caballo, idConcurso, idUsuario });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/add/`, params, { headers: headers });
    }

    public updateCaballo(caballo): Observable<any> {
        const params = JSON.stringify(caballo);
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/update/`, params, { headers: headers });
    }

    public deleteCaballo(caballo): Observable<any> {
        const params = JSON.stringify(caballo);
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/delete/`, params, { headers: headers });
    }

    public getFeiInfo(fei): Observable<any> {
        const params = JSON.stringify({ fei });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/fei/`, params, { headers: headers });
    }

    public getUsefData(usef: string, idConcurso: any): Observable<any> {
        const params = JSON.stringify({ usef, idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/usef/`, params, { headers: headers });
    }

    public getECData(ppn): Observable<any> {
        const response = this.http.get(`${environment.api.nest.uri}ec/HorseByPPN/${ppn}`);
        return response;
    }

    public getNrhaData(nrha: number): Observable<any> {
        const params = { id: nrha };
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.get(`${environment.api.uri}nrha/horse/`, { headers, params });
    }

    public getAllUsefData(): Observable<any> {
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}caballos/allUsef/`, { headers: headers });
    }

    public updateUsefHorses(idConcurso): Observable<any> {
        const params = JSON.stringify({ id_concurso: idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/allUsef/byConcurso.php`, params, { headers: headers });
    }

    public getInformacionCaballo(idCaballo, idConcurso): Observable<any> {
        const params = JSON.stringify({ id_caballo: idCaballo, id_concurso: idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/caballo_datos/`, params, { headers: headers });
    }

    public getCaballosFiltrados(filtro, idConcurso): Observable<any> {
        const params = JSON.stringify({ filtro, idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/query/`, params, { headers: headers });
    }

    public getFEIDuplicados(idConcurso): Observable<any> {
        const params = JSON.stringify({ idConcurso });
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.post(`${environment.api.uri}limpiarBD/caballos/`, params, { headers: headers });
    }

    public getFEMDuplicados(): Observable<any> {
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}limpiarBD/caballos/femDuplicados.php`, { headers: headers });
    }

    public getCaballosPorFEI(fei, idConcurso): Observable<any> {
        const params = JSON.stringify({ fei, idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}limpiarBD/caballos/caballosPorFEI.php`, params, { headers: headers });
    }

    public getCaballosPorFEM(fem): Observable<any> {
        const params = JSON.stringify({ fem });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}limpiarBD/caballos/caballosPorFEM.php`, params, { headers: headers });
    }

    public mergeCaballos(viejo, nuevo): Observable<any> {
        const params = JSON.stringify({ viejo, nuevo });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}limpiarBD/caballos/merge.php`, params, { headers: headers });
    }

    public porConcurso(idConcurso): Observable<any> {
        const params = JSON.stringify({ idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/porConcurso/`, params, { headers: headers });
    }

    public getCuenta(idCaballo, idConcurso): Observable<any> {
        const params = JSON.stringify({ idCaballo, idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/cuentaCaballo/`, params, { headers: headers });
    }

    public getCuentas(entries, idConcurso): Observable<any> {
        const params = JSON.stringify({ entries, idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/cuentasCaballo/`, params, { headers: headers });
    }

    public anularPago(idPago, metodo, monto, idUsuario, idConcurso, idCaballo, nombreCaballo): Observable<any> {
        const params = JSON.stringify({ idPago, metodo, monto, idUsuario, idConcurso, idCaballo, nombreCaballo });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/anularPago/`, params, { headers: headers });
    }

    public anularCargo(idPago, monto, idUsuario, idConcurso, idCaballo): Observable<any> {
        const params = JSON.stringify({ idPago, monto, idUsuario, idConcurso, idCaballo });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/anularCargo/`, params, { headers: headers });
    }

    public eliminarCargo(idCargo, nombreCargo, cantidad, monto, idUsuario, idConcurso, caballo): Observable<any> {
        const params = JSON.stringify({ idCargo, nombreCargo, cantidad, monto, idUsuario, idConcurso, caballo });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/eliminarCargo/`, params, { headers: headers });
    }

    public updateSuspensionsStatus(idConcurso): Observable<any> {
        const params = JSON.stringify({ idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`{environment.api.uri}caballos/updateSuspensionsStatus/`, params, { headers: headers });
    }

    public mergeHorsesManual(horses): Observable<any> {
        const params = JSON.stringify(horses);
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/merge-horses-manual/`, params, { headers: headers });
    }

    public getCaballosDupicados(idConcurso): Observable<any> {
        const params = JSON.stringify({ idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/duplicados/`, params, { headers: headers });
    }

    public merge(ids, info): Observable<any> {
        const params = JSON.stringify({ ids, info });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}caballos/merge/`, params, { headers: headers });
    }
}
