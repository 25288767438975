import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { PersonaService } from '../../../services/persona.service';
import { PruebaService } from '../../../services/prueba.service';
import { AppService } from '../../../services/app.service';
import { ModalidadService } from '../../../services/modalidad.service';
import { JuezService } from '../../../services/juez.service';
import { ConcursoService } from '../../../services/concurso.service';
import { firstValueFrom } from 'rxjs';
//import { Class } from 'libs/Angular/circuits/src/interfaces/class.interface';
import { ConceptoService } from '../../../services/concepto.service';
import firebase from 'firebase/compat/app';
import { MAT_DATE_FORMATS } from '@angular/material/core';
declare let $: any;

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-class-form',
  templateUrl: './class-form.component.html',
  styleUrls: ['./class-form.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ClassFormComponent implements OnInit {
  @Input() classId?: string; // For editing existing class
  @Input() concursoId: string; // Required for new classes
  @Input() divisionId: string; // Required for new classes

  @Output() saved = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<void>();
  @Output() imageUpload = new EventEmitter<{ type: 'sponsor' | 'course', file: File }>();

  classForm: FormGroup;
  personas: any[] = [];
  modalidades: any[] = [];
  pistas: string[] = [];
  jueces: any[] = [];
  distribuciones: any[] = [];
  reining: boolean = false;
  concurso: any;
  class: any;
  divisions: DivisionNest[] = [];
  // Move all the EC codes here since they're specific to class editing
  ecSectionCodes = [
    { code: "29000043", description: "4 YR Old Jumper 1.10M - 1.20M" },
    { code: "29000044", description: "5 YR Old Jumper 1.20M - 1.30M" },
    { code: "29000045", description: "6 YR Old Jumper 1.30M - 1.40M" },
    { code: "29000071", description: "7/8 YR Old Jumper 1.40M - 1.50M" },
    { code: "29000050", description: "Adult Amateur Hunter 3' (0.90M)" },
    { code: "29000052", description: "Amateur Owner Hunter 3' (0.90M)" },
    { code: "20011200", description: "Amateur Owner Hunter 3' 6\" (1.10M)" },
    { code: "31000143", description: "Canadian Hunter Derby Series 3'0" },
    { code: "29000048", description: "Canadian Hunter Derby Series 3'6" },
    { code: "29000008", description: "Low Hunter" },
    { code: "29000010", description: "Combined Working Hunter " },
    { code: "29000022", description: "Children's Pony" },
    { code: "31000136", description: "Junior/Amateur Jumper 0.90M" },

    { code: "29000032", description: "CET Medal" },
    { code: "29000033", description: "CET Mini Medal" },
    { code: "31000159", description: "CHILD/AA/AO 3'0\" Hunter Combined" },
    { code: "20010800", description: "Children's Hunter 3' (0.90M)" },
    { code: "29000026", description: "Equitation A (16 until 18 years of age)" },
    { code: "31000157", description: "Equitation A,B,C Combined" },
    { code: "29000027", description: "Equitation B (13 until 15 years of age)" },
    { code: "29000028", description: "Equitation C (12 years of age)" },
    { code: "29000046", description: "FEI" },
    { code: "29000110", description: "Green & Regular Hunter Combined" },
    { code: "31000155", description: "Green Hunter - 1st and 2nd Year Combined" },
    { code: "20010200", description: "Green Hunter - 1st Year -  3' 6\" (1.10M)" },
    { code: "20010300", description: "Green Hunter - 2nd Year - 3' 9\" (1.15M)" },
    { code: "29000228", description: "Green Pony Hunter - Large 2'9\"" },
    { code: "29000227", description: "Green Pony Hunter - Medium 2'6\"" },
    { code: "29000226", description: "Green Pony Hunter - Small  2'3\"" },
    { code: "29000025", description: "Green Pony Hunter Combined" },
    { code: "29000031", description: "JC Medal" },
    { code: "20010900", description: "Junior Hunter 3' 6\" (1.10M)" },
    { code: "20011333", description: "Junior/Amateur Hunter 3' 3\" (1.00M)" },
    { code: "29000056", description: "Junior/Amateur Jumper 1.00M" },
    { code: "29000057", description: "Junior/Amateur Jumper 1.10M" },
    { code: "29000058", description: "Junior/Amateur Jumper 1.20M" },
    { code: "29000059", description: "Junior/Amateur Jumper 1.30M" },
    { code: "29000060", description: "Junior/Amateur Jumper 1.40M" },
    { code: "31000137", description: "Junior/Amateur Owner Hunter 3' 6\" (1.10M)" },
    { code: "29000029", description: "Miscellaneous Equitation" },
    { code: "29000041", description: "Miscellaneous Hunter" },
    { code: "29000042", description: "Miscellaneous Jumper" },
    { code: "29000061", description: "Open Jumper 1.0M" },
    { code: "29000062", description: "Open Jumper 1.10M" },
    { code: "29000063", description: "Open Jumper 1.15M" },
    { code: "29000064", description: "Open Jumper 1.20M" },
    { code: "29000065", description: "Open Jumper 1.25M" },
    { code: "29000066", description: "Open Jumper 1.30M" },
    { code: "29000067", description: "Open Jumper 1.35M" },
    { code: "29000068", description: "Open Jumper 1.40M" },
    { code: "29000039", description: "Open Jumper 1.45M/1.60M" },
    { code: "31000147", description: "Pleasure Hack" },
    { code: "29000020", description: "Pony Hunter - Large 2'9\" (0.85M)" },
    { code: "29000019", description: "Pony Hunter - Medium 2'6\" (0.75M)" },
    { code: "29000018", description: "Pony Hunter - Small 2'3\" (0.70M)" },
    { code: "21111100", description: "Pony Jumpers 1.0M" },
    { code: "29000003", description: "Pre Green Hunter - 1st Year - 3' (0.90M)" },
    { code: "31000146", description: "Pre Green Hunter - 2nd Year - 3' 3\" (1.00M)" },
    { code: "29000004", description: "Pre Green Hunter Combined" },
    { code: "20011500", description: "Regular Hunter 4' (1.20M)" },
    { code: "29000021", description: "Regular Pony Hunter Combined" },
    { code: "31000156", description: "Regular/Green Pony Hunter Combined" },
    { code: "31000148", description: "Road Hack" },
    { code: "31000149", description: "Show Hack" }
  ];
  filteredEcCodes: Observable<any[]>;
  selectedCharges: any[] = [];
  conceptos: any[] = [];
  loading = false;

  constructor(
    private fb: FormBuilder,
    private personaService: PersonaService,
    private pruebaService: PruebaService,
    private appService: AppService,
    private modalidadService: ModalidadService,
    private juezService: JuezService,
    private concursoService: ConcursoService,
    private conceptoService: ConceptoService
  ) { }

  async ngOnInit() {
    this.initForm();
    this.loading = true;
    try {
      await this.loadInitialData();
    } catch (error) {
      console.error('Error in initialization:', error);
      $.NotificationApp.send("Error", 'Error loading form data', 'bottom-right', '#fa5c7c', 'error');
    } finally {
      this.loading = false;
    }
  }

  private async loadInitialData() {
    // Load pistas first - needed for both new and edit
    try {
      const pistasRes = await firstValueFrom(this.concursoService.getPistas(this.concursoId));
      if (!pistasRes.error) {
        this.pistas = pistasRes.pistas || [];
      }
    } catch (error) {
      console.error('Error loading pistas:', error);
    }


    // Load all supporting data in parallel
    try {
      const [
        modalidadesRes,
        conceptosRes,
        distribucionesRes,
        divisionsRes,
        concursoRes
      ] = await Promise.all([
        firstValueFrom(this.modalidadService.getModalidadesNest()),
        firstValueFrom(this.conceptoService.getConceptosNest(this.concursoId)),
        firstValueFrom(this.concursoService.getDistributions(this.concursoId)),
        firstValueFrom(this.concursoService.getDivisionsNest(this.concursoId)),
        firstValueFrom(this.concursoService.getConcursoNest(this.concursoId))
      ]);
      // Handle each response individually with error checking
      if (!modalidadesRes.error) this.modalidades = modalidadesRes.data;
      if (!conceptosRes.error) this.conceptos = conceptosRes.data;
      if (!distribucionesRes.error) this.distribuciones = distribucionesRes.data;
      if (!divisionsRes.error) this.divisions = divisionsRes.data;
      if (!concursoRes.error) this.concurso = concursoRes.data.concurso;
      console.log('Concurso:', this.concurso);
    } catch (error) {
      console.error('Error loading supporting data:', error);
      throw error;
    }

    // Only load class data if editing an existing class
    if (this.classId) {
      const classResponse = await firstValueFrom(this.pruebaService.getPruebaNest(this.classId));
      if (classResponse.error) {
        throw new Error('Error loading class: ' + classResponse.message);
      }
      this.class = classResponse.data;
      // Update form with class data
      const formData = {
        nombre: this.class.fullname,
        numero_prueba: this.class.numero_prueba,
        description: this.class.description,
        division: this.class.division,
        modalidad: this.class?.modalidad?.id ?? '',
        altura: this.class.altura,
        costo: this.class.costo,
        premio: this.class.premio,
        prize_distribution: this.class.prize_distribution,
        //inicio: this.class.inicio,
        juez: this.class.juez,
        video: this.class.video,
        designer: this.class.designer_id,
        youtube: this.class.youtube,
        fei_competition_id: this.class.fei_competition_id,
        fei_event_id: this.class.fei_event_id,
        usef_section: this.class.usef_section,
        ec_section: this.class.ec_section,
        //hard_start: this.class.hard_start,
        height_unit: this.class.height_unit || 'M',
        categories: this.class.categories,
        open_to: this.class.open_to,
        type: this.class.type,
        sponsorImage: this.class.sponsorImage,
        croquis: this.class.croquis
      };
      this.classForm.patchValue(formData);
      this.selectedCharges = this.class.charges || []
      // Handle pista separately
      if (this.class.pista) {
        this.classForm.patchValue({ pista: this.class.pista });
      }
    } else if (this.divisionId) {
      this.classForm.patchValue({ division: this.divisionId });
    }

    // Ensure we have a concursoId either from input or from loaded class
    const showId = this.concursoId || this.class?.concurso?.id_concurso;
    if (!showId) {
      throw new Error('No show ID available');
    }



    // if (this.class?.inicio) {
    //   try {
    //     console.log('Original inicio:', this.class.inicio);
    //     // Parse the ISO string to local date
    //     const date = new Date(this.class.inicio);
    //     console.log('Parsed date:', date);
    //     // const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    //     //console.log('Local date:', localDate);
    //     console.log('Formatted time:', this.formatTime(date));
    //     if (!isNaN(date.getTime())) {
    //       this.classForm.patchValue({
    //         startDate: date,
    //         startTime: this.formatTime(date)
    //       });
    //       console.log('Form values after patch:', {
    //         startDate: this.classForm.get('startDate').value,
    //         startTime: this.classForm.get('startTime').value
    //       });
    //     }
    //   } catch (error) {
    //     console.error('Error parsing inicio date:', error);
    //   }
    // }
  }

  private initForm() {
    this.classForm = this.fb.group({
      nombre: ['', Validators.required],
      numero_prueba: ['', Validators.required],
      description: [''],
      modalidad: ['', Validators.required],
      altura: [''],
      pista: [''],
      costo: [''],
      premio: [''],
      prize_distribution: [''],
      // inicio: [''],
      startDate: [''],
      startTime: [''],
      juez: [''],
      video: [false],
      designer: [''],
      youtube: [''],
      fei_competition_id: [''],
      fei_event_id: [''],
      usef_section: [''],
      ec_section: ['', []],
      //hard_start: [false],
      height_unit: ['M'],
      categories: [[]],
      type: [''],
      selectedCharge: [''],
      division: [''],
    });

    if (this.class) {
      console.log('Class division:', this.class.division);
      this.classForm.patchValue({
        numero_prueba: this.class.numero_prueba,
        description: this.class.description,
        division: this.class.division,
        modalidad: this.class?.modalidad?.id ?? '',
        altura: this.class.altura,
        costo: this.class.costo,
        premio: this.class.premio,
        prize_distribution: this.class.prize_distribution,
        juez: this.class.juez,
        video: this.class.video,
        designer: this.class.designer_id,
        youtube: this.class.youtube,
        fei_competition_id: this.class.fei_competition_id,
        fei_event_id: this.class.fei_event_id,
        usef_section: this.class.usef_section,
        ec_section: this.class.ec_section,
        height_unit: this.class.height_unit || 'M',
        categories: this.class.categories,
        type: this.class.type,
        sponsorImage: this.class.sponsorImage,
        croquis: this.class.croquis
      });
      this.selectedCharges = this.class.charges || []
      // Handle pista separately
      if (this.class.pista) {
        this.classForm.patchValue({ pista: this.class.pista });
      }
    }

    // Setup EC code filtering with selection handling
    this.filteredEcCodes = this.classForm.get('ec_section').valueChanges.pipe(
      startWith(''),
      map(value => {
        if (typeof value === 'string') {
          return this._filterEcCodes(value);
        }
        return this._filterEcCodes('');
      })
    );

    // Setup designer search
    this.classForm.get('designer').valueChanges.subscribe(value => {
      if (typeof value === 'string' && value.length > 3) {
        this.getPersonas(value);
      }
    });
  }

  async onSubmit() {

    console.log('Saving Class');
    console.log('Division ID:', this.divisionId);
    if (this.classForm.valid) {
      const formData = this.classForm.value;
      const prueba = {
        altura: formData.altura || '',
        categories: formData.categories?.length ? JSON.stringify(formData.categories) : null,
        costo: formData.costo || 0,
        designer: formData.designer,
        //inicio: this.combineDateAndTime(formData.startDate, formData.startTime),
        juez: formData.juez,
        id_modalidad: formData.modalidad,
        fullname: formData.nombre,
        numero_prueba: formData.numero_prueba,
        description: formData.description,
        pista: formData.pista,
        premio: formData.premio || 0,
        video: formData.video ? 1 : 0,
        youtube: formData.youtube,
        fei_competition_id: formData.fei_competition_id,
        fei_event_id: formData.fei_event_id,
        usef_section: formData.usef_section || null,
        ec_section: formData.ec_section || null,
        //hard_start: formData.hard_start ? 1 : 0,
        prize_distribution: formData.prize_distribution || null,
        division: formData.division,
        height_unit: formData.height_unit,
        type: formData.type,
        sponsorImage: this.class?.sponsorImage || formData.sponsorImage || null,
        croquis: this.class?.croquis || formData.croquis || null,
        charges: this.selectedCharges || [] // Agrega los cargos seleccionados a la prueba.
      };

      try {
        if (this.classId) {
          // Update existing class
          const response = await this.pruebaService.updatePruebaNest(prueba, this.classId).toPromise();
          this.saved.emit(response);
        } else {
          // Create new class
          const response = await this.pruebaService.createPruebaNest(prueba, this.concursoId).toPromise();
          this.saved.emit(response);
        }
      } catch (error) {
        console.error('Error saving class:', error);
        $.NotificationApp.send("Error", 'The class could not be saved', 'bottom-right', '#fa5c7c', 'error');
      }
    } else {
      console.log('Form is not valid');
      Object.keys(this.classForm.controls).forEach(key => {
        const control = this.classForm.get(key);
        if (control) {
          control.markAsTouched();
        }
        if (control?.errors) {
          console.log(`Field "${key}" has errors:`, control.errors);
        }
      });
    }
  }

  private _filterEcCodes(value: string): any[] {
    // Don't return empty array on valid code during filtering
    const filterValue = value?.toLowerCase() || '';
    return this.ecSectionCodes.filter(code =>
      code.code.toLowerCase().includes(filterValue) ||
      code.description.toLowerCase().includes(filterValue)
    );
  }

  displayEcCode = (code: string): string => {
    if (!code) return '';
    const found = this.ecSectionCodes.find(c => c.code === code);
    return found ? `${found.code} - ${found.description}` : code;
  }

  displayPersona(idPersona: any): string {
    // const designer = this.personas.find(p => p.id_persona === idPersona);
    // return designer ? designer.fullname : idPersona;
    return idPersona;
  }

  getPersonas(filtro: string) {
    this.personaService.getPersonasFiltradas(filtro, this.concurso?.id).subscribe({
      next: (response) => {
        if (!response.error) {
          this.personas = response.personas;
        }
      },
      error: (error) => {
        console.error('Error loading personas:', error);
      }
    });
  }

  private uploadImageToFirebase(file: File, type: 'sponsor' | 'course') {
    $('#loader').show();
    const folder = type === 'sponsor' ? 'sponsor-banners' : 'croquis';
    const ref = firebase.storage().ref(folder).child(`${new Date().getTime()}`);
    const task = ref.put(file);

    task.on('state_changed',
      (snapshot: any) => {
        //this.porcentajeSubida = (snapshot.bytesTransferred / snapshot.totalBytes);
      },
      (error: any) => {
        $('#loader').hide();
        $.NotificationApp.send("Error", error.code, 'bottom-right', '#ffbc00', 'error');
        console.log(error);
      },
      () => {
        task.snapshot.ref.getDownloadURL().then(
          downloadURL => {
            $('#loader').hide();
            if (type === 'sponsor') {
              // Update both class object and form value
              this.class.sponsorImage = downloadURL;
              this.classForm.patchValue({
                sponsorImage: downloadURL
              });
            } else {
              this.class.croquis = downloadURL;
              this.classForm.patchValue({
                croquis: downloadURL
              });
            }
            $.NotificationApp.send("Success", 'Image uploaded successfully', 'bottom-right', '#06d5a1', 'success');
          },
          error => {
            $('#loader').hide();
            $.NotificationApp.send("Error", 'The download url could not be obtained.', 'bottom-right', '#ffbc00', 'error');
            console.log(error);
          }
        );
      }
    );
  }

  onSponsorImage(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      event.target.value = '';
      this.uploadImageToFirebase(file, 'sponsor');
    }
  }

  onCourseImage(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      event.target.value = '';
      this.uploadImageToFirebase(file, 'course');
    }
  }


  /**
   * Agrega un cargo a la clase, evitando duplicados.
   */
  public addCharge() {
    // Obtiene el valor del cargo seleccionado del formulario.
    const chargeId = this.classForm.get('selectedCharge').value;
    // Busca el cargo en los conceptos disponibles. No valida el tipo de dato. 
    const charge = this.conceptos.find(c => c.id == chargeId);
    // Si el cargo existe y no está en la lista de cargos de la clase, lo agrega.
    if (charge && !this.selectedCharges.find(c => c.id == chargeId)) {
      this.selectedCharges.push({
        id: charge.id,
        name: charge.nombre
      });
      this.classForm.patchValue({ selectedCharge: '' });
    }
  }

  /**
   * Elimina un cargo de la clase.
   */
  public removeCharge(chargeId: string) {
    this.selectedCharges = this.selectedCharges.filter(c => c.id !== chargeId);
  }

  get availableConcepts() {
    if (!this.conceptos) return [];
    return this.conceptos.filter(c =>
      !this.selectedCharges.find(sc => sc.id === c.id)
    );
  }

  clearImage(type: 'sponsor' | 'course') {
    if (type === 'sponsor') {
      this.class.sponsorImage = null;
    } else {
      this.class.croquis = null;
    }
    // Emit an event to notify parent component
    this.imageUpload.emit({ type, file: null });
  }

  onEcCodeSelected(event: any) {
    const selectedCode = event.option.value;
    this.classForm.patchValue({
      ec_section: selectedCode
    });
  }

  onEcCodeBlur() {
    setTimeout(() => {
      const currentValue = this.classForm.get('ec_section').value;
      // Only reset if there's a value and it's not a valid code
      if (currentValue && !this.ecSectionCodes.find(c => c.code === currentValue)) {
        this.classForm.patchValue({
          ec_section: ''
        });
      }
    }, 200); // Small delay to ensure selection is processed first
  }

  // Helper methods for date/time handling
  private formatDate(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  private formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  private combineDateAndTime(date: string | Date, time: string): string | null {
    if (!date) return null;
    let month, day, year;
    if (date instanceof Date) {
      month = (date.getMonth() + 1).toString().padStart(2, '0');
      day = date.getDate().toString().padStart(2, '0');
      year = date.getFullYear();
    } else {
      [month, day, year] = date.split('/');
    }
    const [hours, minutes] = time ? time.split(':') : ['00', '00'];
    return `${year}-${month}-${day}T${hours}:${minutes}:00`;
  }

  // ... other helper methods
} 