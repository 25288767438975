import { Component, Inject, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { SeasonPassEntry } from '../../services/season-pass.service';

// Define a type specifically for our form data
interface EntryFormData {
  id: string;
  entry: number | string;
  name: string;
  owner: string;
  riders: string;
  trainer: string;
  purchaseDate: Date;
  status: string;
}

@Component({
  selector: 'app-entry-edit-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  templateUrl: './entry-edit-dialog.component.html',
  styleUrls: ['./entry-edit-dialog.component.scss']
})
export class EntryEditDialogComponent {
  private dialogRef = inject(MatDialogRef<EntryEditDialogComponent>);
  private data = inject<{ entry: SeasonPassEntry | null, passId: string }>(MAT_DIALOG_DATA);
  private fb = inject(FormBuilder);
  
  entryForm: FormGroup;
  isNew: boolean;
  passName: string = 'Season Pass';

  constructor() {
    this.isNew = !this.data.entry;
    
    this.entryForm = this.fb.group({
      entry: [this.data.entry?.entry || '', Validators.required],
      name: [this.data.entry?.name || '', Validators.required],
      ec: [this.data.entry?.ec || ''],
      owner: [this.data.entry?.owner || ''],
      riders: [this.data.entry?.riders || ''],
      trainer: [this.data.entry?.trainer || ''],
      purchaseDate: [this.data.entry?.purchaseDate || new Date()],
      status: [this.data.entry?.status || 'active', Validators.required]
    });
  }

  generateId(): string {
    return 'entry_' + new Date().getTime() + '_' + Math.random().toString(36).substring(2, 9);
  }

  onSubmit(): void {
    if (this.entryForm.valid) {
      // Create the entry data with all required fields including id
      const entryData: SeasonPassEntry = {
        entry: this.entryForm.value.entry,
        name: this.entryForm.value.name,
        ec: this.entryForm.value.ec || '',
        owner: this.entryForm.value.owner || '',
        riders: this.entryForm.value.riders || '',
        trainer: this.entryForm.value.trainer || '',
        purchaseDate: this.entryForm.value.purchaseDate,
        status: this.entryForm.value.status
      };

      // Return just the entry data - the component already has the passId
      this.dialogRef.close(entryData);
    }
  }
} 