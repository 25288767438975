<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{ isEditing ? 'Edit' : 'Add New' }} Circuit</h4>
    <button type="button" class="close" (click)="onCancel()">
      <span>&times;</span>
    </button>
  </div>

  <form [formGroup]="circuitForm" (ngSubmit)="onSubmit()">
    <div class="modal-body" style="max-height: calc(100vh - 210px); overflow-y: auto;">
      <div class="form-group">
        <label for="name">Circuit Name</label>
        <input type="text" 
               class="form-control" 
               id="name" 
               formControlName="name" 
               required>
      </div>

      <div class="form-group">
        <label for="season">Season</label>
        <input type="number" 
               class="form-control" 
               id="season" 
               formControlName="season" 
               [min]="currentYear"
               [max]="currentYear + 1"
               required>
        <small class="form-text text-muted" *ngIf="circuitForm.get('season').errors?.['min'] || circuitForm.get('season').errors?.['max']">
          Season must be current or next year
        </small>
      </div>

      <div class="form-group">
        <label for="tracking_type">Track Points By</label>
        <select class="form-control" 
                id="tracking_type" 
                formControlName="tracking_type" 
                required>
          <option value="">Select tracking type</option>
          <option value="horse">Horse</option>
          <option value="rider">Rider</option>
          <option value="combination">Horse/Rider Combination</option>
        </select>
      </div>

      <div class="form-group">
        <label for="points_system">Points System</label>
        <select class="form-control" 
                id="points_system" 
                formControlName="points_system" 
                required>
          <option value="">Select a points system</option>
          <option value="Winner 10, 8 * competitors (Top 8)">Winner 10, 8 * competitors (Top 8)</option>
          <option value="Winner 11, 9, 8... (competitors / 3 max 10)">Winner 11, 9, 8... (competitors / 3 max 10)</option>
          <option value="Participants +1">Participants +1</option>
          <option value="10, 6, 4, 2, 1, 0.5">10, 6, 4, 2, 1, 0.5</option>
        </select>
      </div>

      <div class="form-group">
        <label>Classes</label>
        <!-- Show circuit classes first -->
        <div *ngIf="circuitPruebas.length > 0" class="mb-3">
          <label class="text-muted">Classes in this circuit:</label>
          <div class="class-list mb-2">
            <div *ngFor="let class of circuitPruebas" class="custom-control custom-checkbox">
              <input type="checkbox" 
                     class="custom-control-input" 
                     [id]="'class_' + class.id_prueba_concurso"
                     [checked]="isClassSelected(class.id_prueba_concurso)"
                     (change)="handleCheckboxChange(class.id_prueba_concurso, $event)">
              <label class="custom-control-label" [for]="'class_' + class.id_prueba_concurso">
                {{class.numero_prueba}} - {{class.fullname}} 
                <small class="text-muted">
                  ({{class.concurso.fullname}})
                </small>
              </label>
            </div>
          </div>
        </div>

        <!-- Search for additional classes -->
        <div class="d-flex align-items-center mb-2">
          <div class="custom-control custom-switch mr-3">
            <input type="checkbox" 
                   class="custom-control-input" 
                   id="searchAllOrganizers"
                   [checked]="searchAllOrganizers"
                   (change)="toggleSearchAllOrganizers()">
            <label class="custom-control-label" for="searchAllOrganizers">
              Search across all organizers
            </label>
          </div>
        </div>

        <input type="text" 
               class="form-control mb-2" 
               placeholder="Type at least 3 characters to search more classes..."
               (input)="onSearch($event)">
        
        <!-- Show search results -->
        <div *ngIf="pruebas.length > 0" class="class-list" style="max-height: 300px; overflow-y: auto;">
          <div *ngFor="let class of pruebas" class="custom-control custom-checkbox">
            <input type="checkbox" 
                   class="custom-control-input" 
                   [id]="'class_' + class.id_prueba_concurso"
                   [checked]="isClassSelected(class.id_prueba_concurso)"
                   (change)="handleCheckboxChange(class.id_prueba_concurso, $event)">
            <label class="custom-control-label" [for]="'class_' + class.id_prueba_concurso">
              {{class.numero_prueba}} - {{class.fullname}} 
              <small class="text-muted">
                ({{class.concurso.fullname}})
              </small>
            </label>
          </div>
        </div>
        
        <div *ngIf="!circuitPruebas.length && !pruebas.length" class="text-muted">
          Type at least 3 characters to search for classes
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" 
              class="btn btn-secondary" 
              (click)="onCancel()">Cancel</button>
      <button type="submit" 
              class="btn btn-primary" 
              [disabled]="!circuitForm.valid || loading">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Save
      </button>
    </div>
  </form>
</div> 