import { Component, OnInit } from '@angular/core';
import { ConcursoService } from '../services/concurso.service';
import { AuthService } from '../services/auth.service';
import { EntriesService } from '../services/entries.service';
import { EntrenadorService } from '../services/entrenador.service';
import { InscripcionesRemotasService } from '../services/inscripcionesRemotas.service';
import { environment } from '../../environments/environment';
import * as XLSX from 'xlsx';
declare var $: any;

@Component({
    selector: 'inicio',
    templateUrl: './inicio.component.html',
    styleUrls: ['./inicio.component.css']
})

export class InicioComponent implements OnInit {
    public concursos: Array<any>;
    public windowHeight: number;
    public idConcurso: string | boolean;
    public nombreConcurso: string;
    public currentDate = new Date();
    private token: string;
    private sessionData: any;
    public entrenadores: any[];
    public entries: any[];
    public header: string;
    public header2: string;
    public footer: string;
    public caballerizas: any[];
    public authorized: boolean = false;
    public privilegios: number;
    sortColumn: string = '';
    sortDirection: 'asc' | 'desc' = 'asc';


    constructor(
        private concursoService: ConcursoService,
        private authService: AuthService,
        private inscripcionesRemotasService: InscripcionesRemotasService,
        private entrenadorService: EntrenadorService
    ) {
        this.concursos = [];
        this.windowHeight = window.innerHeight;
        this.idConcurso = false;
        this.nombreConcurso = this.authService.getNombreConcurso();
        this.token = '';
        this.sessionData = {};
        this.entrenadores = [];
        this.entries = [];
        this.header = '';
        this.header2 = '';
        this.footer = '';
        this.caballerizas = [];
        this.privilegios = 0;
    }

    ngOnInit() {
        if (!this.authService.isLoggedIn()) {
            this.authService.logOut();
            return;
        } else {
            this.token = this.authService.getAuthorizationToken();
            this.sessionData = this.authService.getSessionData(this.token);
            this.privilegios = this.sessionData.privilegios;
        }

        this.idConcurso = this.authService.validarConcurso();
        this.getConcursos();
        if (this.idConcurso) {
            console.log('Concurso set');
            if (this.privilegios >= 100) {
                console.log('Get data');
                this.getInfoResumenEntrenadores();
                this.getInfoConcurso();
                this.resumenCaballerizas();
            } else {
                console.log('Dont get data');
                $('#loader').hide();
            }
        }

        console.log('Privilegios: ', this.privilegios);
    }

    private getConcursos() {
        this.concursoService.getConcursos().subscribe(
            response => {
                if (!response.error) {
                    //Si el usuario no es admin filtrar concursos por club
                    if (this.sessionData.privilegios < 300) {
                        this.concursos = response.consursos.filter(concurso => {
                            return concurso.id_club == this.sessionData.id_club;
                        });
                    } else {
                        this.concursos = response.consursos;
                    }
                } else {
                    $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                    console.log(response.message);
                }
            },
            error => {

                $.NotificationApp.send("Error", "It was not possible to query the shows.", 'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );
    }

    private resumenCaballerizas() {
        console.log('Resumen caballerizas');
        this.inscripcionesRemotasService.resumenCaballerizas(this.idConcurso).subscribe(
            response => {
                if (!response.error) {
                    this.caballerizas = response.caballerizas;
                } else {
                    $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                    console.log(response.message);
                }
            },
            error => {
                $.NotificationApp.send("Error", "It was not possible to consult the stall reservations.", 'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );
    }

    public seleccionarConcurso(idConcurso, nombreConcurso, id_club, public_key) {
        sessionStorage.setItem('concurso', idConcurso);
        sessionStorage.setItem('nombreConcurso', nombreConcurso);
        sessionStorage.setItem('club', id_club);
        sessionStorage.setItem('public_key', public_key);
        sessionStorage.setItem('reining', environment.reiningClubs.includes(id_club) ? '1' : '0');
        this.idConcurso = idConcurso;
        this.nombreConcurso = nombreConcurso;

        if (this.privilegios >= 100) {
            this.getInfoResumenEntrenadores();
            this.getInfoConcurso();
            this.resumenCaballerizas();
           // $('#loader').show();
        }


    }

    public cambiarConcurso() {
        sessionStorage.removeItem('concurso');
        sessionStorage.removeItem('nombreConcurso');
        sessionStorage.clear();
        this.idConcurso = false;
        this.nombreConcurso = '';
    }

    public updateTopbar() {
        var element = document.getElementById("menu-topbar");
    }

    public getInfoResumenEntrenadores() {
        console.log('Get info resumen entrenadores');
        this.entrenadorService.getInfoResumen(this.idConcurso).subscribe(
            response => {
                console.log(response);
                if (!response.error) {
                    this.entrenadores = /*$.map(*/response.entrenadores/*, (entrenador) => {
                        return entrenador;
                    })*/.sort((a, b) => {
                        /*let totalA = a.total < 0 ? 0 :(a.total > 0 ? 1 : 2);
                        let totalB = b.total < 0 ? 0 :(b.total > 0 ? 1 : 2);*/
                        let totalA = a.saldo < 0 ? 0 : (a.saldo > 0 ? 1 : 2);
                        let totalB = b.saldo < 0 ? 0 : (b.saldo > 0 ? 1 : 2);

                        if (totalA > totalB) return 1;
                        if (totalA < totalB) return -1;

                        if (a.lastname < b.lastname) return -1;
                        if (a.lastname > b.lastname) return 1;

                        /*if(a.entries.length < b.entries.length) return 1;
                        if(a.entries.length > b.entries.length) return -1;*/

                        return 0;
                    });
                    this.entries = response.entries.sort((a, b) => {
                        let totalA = a.saldo < 0 ? 0 : (a.saldo > 0 ? 1 : 2);
                        let totalB = b.saldo < 0 ? 0 : (b.saldo > 0 ? 1 : 2);

                        if (totalA > totalB) return 1;
                        if (totalA < totalB) return -1;

                        if (a.apellidoEntrenador < b.apellidoEntrenador) return -1;
                        if (a.apellidoEntrenador > b.apellidoEntrenador) return 1;

                        return 0;
                    });
                    console.log(this.entries)
                    $('#loader').hide();
                } else {
                    $('#loader').hide();
                    $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                    console.log(response.message);
                }
            },
            error => {
                $('#loader').hide();
                $.NotificationApp.send("Error", "It was not possible to consult the trainers list.", 'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );
    }

    public sumaArrayJson(array, campo) {
        return array.reduce(function (a, b) {
            return a + b[campo];
        }, 0);
    }

    public getInfoConcurso(): void {
        console.log('Get info concurso');
        this.concursoService.getConcurso(this.idConcurso).subscribe(
            response => {
                if (!response.error) {
                    this.header = response.concurso.header;
                    this.header2 = response.concurso.header2;
                    this.footer = response.concurso.footer;
                } else {
                    $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                    console.log(response.message);
                }
            },
            error => {
                $.NotificationApp.send("Error", "It was not possible to consult the show info.", 'bottom-right', '#fa5c7c', 'error');
                console.log(error);
            }
        );
    }

    sortTable(column: string) {
        // If clicking the same column, reverse direction
        if (this.sortColumn === column) {
            this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
            this.sortColumn = column;
            this.sortDirection = 'asc';
        }

        this.entries = [...this.entries].sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];
            
            let comparison = 0;
            if (aValue < bValue) comparison = -1;
            if (aValue > bValue) comparison = 1;
            
            return this.sortDirection === 'asc' ? comparison : -comparison;
        });
    }

    exportToExcel(): void {
        // Prepare the data
        const data = this.entries.map(entry => ({
            Entry: entry.entry,
            Horse: entry.horse,
            Trainer: entry.trainer,
            Balance: entry.saldo // Will format this as currency below
        }));

        // Create worksheet
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

        // Set column widths
        ws['!cols'] = [
            { wch: 10 },  // Entry
            { wch: 30 },  // Horse
            { wch: 30 },  // Trainer
            { wch: 15 }   // Balance
        ];

        // Format the Balance column as currency
        for (let i = 2; i <= data.length + 1; i++) {
            const cell = XLSX.utils.encode_cell({ r: i - 1, c: 3 }); // Balance column
            if (!ws[cell]) continue;
            ws[cell].z = '"$"#,##0.00;[Red]"$"#,##0.00';
        }

        // Style the header row
        const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
        for (let C = range.s.c; C <= range.e.c; ++C) {
            const cell = XLSX.utils.encode_cell({ r: 0, c: C });
            if (!ws[cell]) continue;
            ws[cell].s = {
                font: { bold: true },
                fill: { fgColor: { rgb: "CCCCCC" } }
            };
        }

        // Create workbook
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        // Add title to the sheet
        XLSX.utils.book_append_sheet(wb, ws, `${this.nombreConcurso} - Entries`);

        XLSX.writeFile(wb, `${this.nombreConcurso} - Entries ${new Date().toLocaleDateString()}.xlsx`);
    }
}
