<div *ngIf="storedCards.length > 0">
  <h4>Select a Stored Card</h4>
  <ul class="card-list">
    <li *ngFor="let card of storedCards" class="card-row" (click)="onCardSelect(card)"
      [hidden]="selectedCardId && selectedCardId !== card.customer_vault_billing_id">
      <!-- <i [class]="getCardIconClass(card.cc_type)"></i> -->
      <span style="width:65px; text-align: center;">
        <img [src]="getCardLogo(card.cc_type)" alt="Card Logo" style="height: 20px;">
      </span>
      <span>**** {{ card.cc_number | slice: -4 }}</span>
      <span>{{ card.cc_exp }}</span>
      <span>{{ card.cardholder }}</span>
    </li>
  </ul>
  <button class="show-all-cards-btn btn btn-outline-alternate m-2" *ngIf="selectedCardId" (click)="resetSelection()">
    <i class="fas fa-eye"></i> Show All Cards
  </button>
</div>
<div *ngIf="storedCards.length === 0">
  <p>No stored cards found.</p>
</div>