import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FeedOrdersService } from '../services/feed-orders.service';
import { Router } from '@angular/router';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ConceptoService } from '../services/concepto.service';
import { EntrenadorService } from '../services/entrenador.service';
import { PersonaService } from '../services/persona.service';
import { EntriesService } from '../services/entries.service';
import { firstValueFrom } from 'rxjs';
declare var $: any;

interface OrderResponse {
  error?: string;
  order?: any;
  message?: string;
}

@Component({
  selector: 'app-feed-orders',
  templateUrl: './feed-orders.component.html',
  styleUrls: ['./feed-orders.component.css'],
  providers: [
    DatePipe,
    CurrencyPipe
  ]
})
export class FeedOrdersComponent implements OnInit, AfterViewInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public table: any;
  private orders: any[];
  public mostrarPanelAgregar: boolean;
  public mostrarPanelVer: boolean;
  public charges: any[];
  public form: UntypedFormGroup;
  public concept: string;
  public notes: string;
  public qty: string;
  public amount: string;
  public conceptos: any[];
  public entrenadores: any[];
  public ordenSeleccionada: string;
  public summaryOrders: any[];
  public entries: any[];
  public printSummary: boolean;
  public printOrder: boolean;
  public loadingSave: boolean;
  public trainers: any[];

  constructor(
    private authService: AuthService,
    private feedOrdersService: FeedOrdersService,
    private router: Router,
    private datePipe: DatePipe,
    private conceptoService: ConceptoService,
    private entrenadorService: EntrenadorService,
    private entriesService: EntriesService,
    private personaService: PersonaService) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.table = {};
    this.orders = [];
    this.mostrarPanelAgregar = false;
    this.mostrarPanelVer = false;
    this.charges = [];
    this.form = new UntypedFormGroup({
      stall: new UntypedFormControl('', [Validators.required]),
      trainer: new UntypedFormControl('', []),
      entry: new UntypedFormControl('', []),
      delivered_by: new UntypedFormControl('', []),
      delivery_date: new UntypedFormControl('', []),
     // online: new UntypedFormControl(false, [])
    });
    this.concept = '';
    this.notes = '';
    this.qty = '';
    this.amount = '';
    this.conceptos = [];
    this.entrenadores = [];
    this.ordenSeleccionada = '';
    this.summaryOrders = [];
    this.entries = [];
    this.printSummary = true;
    this.printOrder = false;
    this.loadingSave = false;
    this.trainers = [];
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getShowTrainers();
  }

  ngAfterViewInit(): void {
    this.table = $('#orders').DataTable({
      dom: 'Bfrtip',
      buttons:[{
        extend: 'print',
        title: function () {
          //return $('#tableTitle').text();
          return 'Feed Orders';
        }
      }],
      language:{
        paginate:{
          previous:"<i class='mdi mdi-chevron-left'>",
          next:"<i class='mdi mdi-chevron-right'>"
        }
      },
      rowId: 0,
      drawCallback: function () {
        $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
      }
    });
    this.clickTable();
    this.getFeedConcepts();
  }

  private clickTable(){
    $('#orders tbody').on('click', 'tr .table-action a.ver', (event) => {
      const order = event.currentTarget.getAttribute('order');
      this.abrirPanelVer(order);
    });
    $('#orders tbody').on('click', 'tr .table-action a.eliminar', (event) => {
      const order = event.currentTarget.getAttribute('order');
      this.deleteOrder(order);
    });
  }

  public getFeedConcepts(){
    this.conceptoService.getFeedConcepts(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.conceptos = response.conceptos;
          this.getEntries();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 7000);
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the concept list.", 'bottom-right', '#fa5c7c', 'error', 7000);
        console.log(error);
      }
    );
  }

  public getSummary(){
    this.feedOrdersService.summary(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.summaryOrders = response.summary.sort((a, b) => {
            if(a.producto > b.producto) return 1;
            if(a.producto < b.producto) return -1;

            return 0;
          });
          $('#loader').hide();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 7000);
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the summary.", 'bottom-right', '#fa5c7c', 'error', 7000);
        console.log(error);
      }
    );
  }

  public getEntries(){
    this.entriesService.getEntries(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.entries = response.data;
          this.getFeedOrders();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 7000);
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the entries list.", 'bottom-right', '#fa5c7c', 'error', 7000);
        console.log(error);
      }
    );
  }

  public getFeedOrders(){
    this.feedOrdersService.getOrders(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.orders = response.orders;
          console.log(this.orders);
          this.table.rows.add(this.orders.map(o => {
            return [o.id, o.stall, o.trainer, this.datePipe.transform(o.created_at, 'dd/MMM'), this.datePipe.transform(o.delivered, 'dd/MMM'), o.online == 1 ? '<i class="mdi mdi-earth d-print" style="color:black"></i><span style="color:white">O</span>' : '',
              `<div class="table-action text-center"><a href="javascript: void(0);" class="action-icon ver" order="${o.id}"> <i class="mdi mdi-pencil"></i></a></div>`,
              `<div class="table-action text-center"><a href="javascript: void(0);" class="action-icon eliminar" order="${o.id}"> <i class="mdi mdi-trash-can-outline"></i></a></div>`
            ]
          })).draw(false);
          this.getSummary();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 7000);
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the order list.", 'bottom-right', '#fa5c7c', 'error', 7000);
        console.log(error);
      }
    );
  }

  public async getShowTrainers(){
    const response = await firstValueFrom(this.entrenadorService.getEntrenadoresPorConcurso(this.idConcurso));
    if(response.error){
      $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error', 10000);
      return;
    }
    this.trainers = response.entrenadores.map(e => e.id_persona);
  }

  public abrirPanelAgregar(){
    this.ordenSeleccionada = '';
    this.charges = [];
    this.entrenadores = [];
    this.concept = '';
    this.amount = '';
    this.qty = '';
    this.notes = '';
    this.form.setValue({
      stall: '',
      trainer: '',
      entry: '',
      delivered_by: '',
      delivery_date: ''
    });
    this.mostrarPanelAgregar = true;
    //this.mostrarPanelVer = false;
  }

  public cerrarPanelAgregar(){
    this.charges = [];
    this.mostrarPanelAgregar = false;
    this.mostrarPanelVer = false;
  }

  public abrirPanelVer(orderId){
    this.ordenSeleccionada = orderId;
    const order = this.orders.find(o => o.id == orderId);
    const [entryNumber] = (order.entry||'').split(' - ');
    if(!order) return;
    this.charges = typeof(order.data) == 'string' ? JSON.parse(order.data) : order.data;
    this.entrenadores = [];
    this.concept = '';
    this.amount = '';
    this.qty = '';
    this.notes = '';
    if(order.trainer && order.id_entrenador){
      this.entrenadores = [{
        id_persona: order.id_entrenador,
        fullname: order.trainer,
        inShow: this.trainers.includes(order.id_entrenador)
      }];
    }
    this.form.setValue({
      stall: order.stall||'',
      trainer: order.id_entrenador||'',
      entry: entryNumber,
      delivered_by: order.delivered_by||'',
      delivery_date: order.delivered||''
    });
    this.mostrarPanelAgregar = true;
    //this.mostrarPanelVer = true;
  }

  public cerrarPanelVer(){
    this.charges = [];
    this.mostrarPanelAgregar = false;
    this.mostrarPanelVer = false;
  }

  public print(e: Event, seccion){
    e.stopPropagation();
    if( seccion == 'summary') {
      this.cerrarPanelVer();
      this.printSummary = true;
      this.printOrder = false;
    }
    if( seccion == 'order') {
      console.log('Print order');
      this.printOrder = true;
      this.printSummary = false;
    }
    setTimeout(() => {
      window.print();
    });
  }

  public calcularCargo() {
    if (this.concept && this.qty) {
      const index = this.conceptos.findIndex(concepto => {
        return concepto.id == this.concept;
      });
      this.amount = (parseFloat(this.conceptos[index].monto) * parseFloat(this.qty)).toString();
    } else if (this.concept && !this.qty) {
      const index = this.conceptos.findIndex(concepto => {
        return concepto.id == this.concept;
      });
      this.qty = '1';
      this.amount = parseFloat(this.conceptos[index].monto).toString();
    }
  }

  public addCharge(){
    if(this.concept && this.qty && this.amount){
      const index = this.conceptos.findIndex(c => c.id == this.concept);

      this.charges.push({
        id: this.concept,
        linea: this.amount,
        cantidad: this.qty,
        producto: this.conceptos[index].nombre,
        unitario: this.conceptos[index].monto,
        notas: this.notes
      });
      this.concept = '';
      this.notes = '';
      this.qty = '';
      this.amount = '';
    } else{
      $.NotificationApp.send("Warning", "Please complete all fields.", 'bottom-right', '#ffbc00', 'warning', 7000);
    }
  }

  public async saveOrder() {
    if (this.charges.length == 0) {
      $.NotificationApp.send("Warning", "At least one charge must be added to the order.", 'bottom-right', '#ffbc00', 'warning', 7000);
      return;
    }
    if (!this.form.get('trainer').value && !this.form.get('entry').value) {
      $.NotificationApp.send("Warning", "Please choose a trainer or an entry.", 'bottom-right', '#ffbc00', 'warning', 7000);
      return;
    }
    if (!this.form.valid) return;

    this.loadingSave = true;
    this.form.disable();

    try {
      const total = this.charges.reduce((a, b) => a + parseFloat(b.linea || '0'), 0);

      if (this.ordenSeleccionada) {
        // Update existing order
        const selectedOrder = this.orders.find(o => o.id == this.ordenSeleccionada);
        const newOrder = {
          id: this.ordenSeleccionada,
          id_concurso: this.idConcurso,
          created_at: selectedOrder.created_at,
          id_entrenador: this.form.value.trainer || '',
          trainer: selectedOrder.trainer,
          entry: this.form.value.entry || '',
          id_usuario: this.idUsuario,
          stall: this.form.value.stall || '',
          total,
          data: this.charges.map(c => ({ 
            cantidad: `${c.cantidad}`, 
            producto: c.producto, 
            id: c.id, 
            linea: c.linea, 
            unitario: c.unitario, 
            notas: c.notas 
          })),
          online: selectedOrder.online,
          delivered: selectedOrder.delivered ? new Date(selectedOrder.delivered) : ''
        };

        const { order, error } = await this.feedOrdersService.saveOrderNest(newOrder);
        if (error) {
          throw new Error(error);
        }
        $.NotificationApp.send("Saved", `The order ${order?.id} has been updated successfully`, 'bottom-right', '#0acf97', 'success', 10000);
      } else {
        // Create new order

        const newOrder = {
          id_concurso: this.idConcurso,
          id_entrenador: this.form.value.trainer || '',
          trainer: this.entrenadores.find(e => e.id_persona == this.form.value.trainer)?.fullname || '',
          entry: this.form.value.entry || '',
          id_usuario: this.idUsuario,
          stall: this.form.value.stall || '',
          total,
          data: this.charges.map(c => ({ 
            cantidad: `${c.cantidad}`, 
            producto: c.producto, 
            id: c.id, 
            linea: c.linea, 
            unitario: c.unitario, 
            notas: c.notas 
          })),
          online: false,

        }


        // const order = await this.feedOrdersService.save(
        //   this.idConcurso, 
        //   this.form.value.trainer, 
        //   this.form.value.entry, 
        //   this.idUsuario,
        //   this.form.value.stall, 
        //   total, 
        //   this.charges
        // );

        const { order, error } = await this.feedOrdersService.saveOrderNest(newOrder);
        if (error) {
          throw new Error(error);
        }
        $.NotificationApp.send("Saved", `The order ${order?.id} has been created successfully`, 'bottom-right', '#0acf97', 'success', 10000);
      }

      // Refresh the orders list
      this.table.clear();
      await this.getFeedOrders();
      
      // Reset form
      $('form').removeClass('was-validated');
      this.cerrarPanelVer();
      this.loadingSave = false;
      this.form.enable();

    } catch (error) {
      console.log('Error: ', error);
      this.loadingSave = false;
      this.form.enable();
      $.NotificationApp.send("Error", 'Line 442: ' + error.message, 'bottom-right', '#fa5c7c', 'error', 10000);
    }
  }

  public async syncOrder(){
    const order = this.orders.find(o => o.id == this.ordenSeleccionada);
    if(!order) return;
    const data = typeof(order.data) == 'string' ? JSON.parse(order.data) : order.data;
    const newOrderFS = {
      data: data.map(d => ({ cantidad: `${d.cantidad}`, producto: d.producto, id: d.id, linea: d.linea, unitario: d.unitario, notas: d.notas })),
      entry: order.entry,
      id: order.id,
      id_concurso: this.idConcurso,
      stall: order.stall,
      delivered: order.delivered ? new Date(order.delivered) : null,
      created_at: new Date(),
      trainer: order.trainer,
      id_entrenador: order.id_entrenador,
      id_usuario: this.idUsuario,
      online: order.online

    };
    await this.feedOrdersService.saveOrderNest(newOrderFS);


  }

  public removeCharge(index){
    this.charges.splice(index, 1);
  }

  public displayEntrenador(idPersona): string{
    const entrenador = this.entrenadores.find(p => p.id_persona == idPersona);

    return entrenador ? entrenador.fullname : idPersona;
  }

  public async getPersonas(e){
    //Solo hacer consulta de personas cuando la cadena tiene mas de 3 letras
    const filtro: string = e.target.value;
    if(filtro.length > 3){
      const response = await firstValueFrom(this.personaService.getPersonasFiltradas(filtro, this.idConcurso));
      if(response.error){
        $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error', 10000);
        return;
      }
      this.entrenadores = response.personas.map(p => ({ id_persona: p.id_persona, fullname: p.fullname, inShow: this.trainers.includes(p.id_persona) }));
    } else{
      this.entrenadores = [];
      console.log('Busqueda de personas muy corta');
    }
  }

  public async markDelivered(order){
    console.log("Order: ", order);
    await this.feedOrdersService.markDelivered(order).then( (response: any) => {
      this.table.clear().draw();
      this.getFeedOrders();
      this.ordenSeleccionada = null;
      this.cerrarPanelVer();
    });

  }

  public async deleteOrder(orderId) {
    $('#loader').show();
    try {
      const response = await this.feedOrdersService.delete(orderId, this.idConcurso, this.idUsuario) as OrderResponse;
      if (response.error) {
        throw new Error(response.message);
      }
      
      // Refresh the orders list
      this.table.clear();
      await this.getFeedOrders();
      
      $.NotificationApp.send("Deleted", `Order ${orderId} has been deleted successfully`, 'bottom-right', '#0acf97', 'success', 7000);
    } catch (error) {
      $.NotificationApp.send("Error", error.message || 'Failed to delete order', 'bottom-right', '#fa5c7c', 'error', 7000);
    } finally {
      $('#loader').hide();
    }
  }

  public getTotal(): number {
    return this.charges?.reduce((sum, charge) => sum + Number(charge.linea), 0) || 0;
  }
}
