<div class="card">
  <div class="card-body">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h4 class="card-title">Circuits</h4>
      <div>
        <a href="https://ecuestre.digital/circuits/{{idClub}}/{{season}}" 
           target="_blank" 
           class="btn btn-success mr-2">
          <i class="mdi mdi-open-in-new"></i> View on Web
        </a>
        <button class="btn btn-primary" (click)="openAddCircuitDialog()">
          <i class="mdi mdi-plus"></i> Add Circuit
        </button>
      </div>
    </div>
    
    <div *ngIf="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div *ngIf="error" class="alert alert-danger">
      {{ error }}
    </div>

    <div *ngIf="!loading && !error" class="table-responsive">
      <table class="table table-centered table-hover mb-0">
        <thead>
          <tr>
            <th>Calculate</th>
            <th>Name</th>
            <th>Season</th>
            <th>Points System</th>
            <th>Tracking Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let circuit of circuits">
            <td>
              <button class="btn btn-sm btn-primary" 
                      (click)="calculateCircuit(circuit)"
                      [disabled]="calculatingCircuits.has(circuit.id)">
                <i class="mdi" [ngClass]="calculatingCircuits.has(circuit.id) ? 'mdi-loading mdi-spin' : 'mdi-calculator'"></i>
              </button>
            </td>
            <td>{{ circuit.name }}</td>
            <td>{{ circuit.season }}</td>
            <td>
              <span [class]="'badge badge-success'">
                {{ circuit.points_system }}
              </span>
            </td>
            <td class="text-center">
              <ng-container [ngSwitch]="circuit.tracking_type">
                <span *ngSwitchCase="'horse'" title="Tracking by Horse">
                  <i class="fas fa-horse text-primary h4 mb-0"></i>
                </span>
                <span *ngSwitchCase="'rider'" title="Tracking by Rider">
                  <i class="fas fa-user text-primary h4 mb-0"></i>
                </span>
                <span *ngSwitchCase="'combination'" title="Tracking by Horse/Rider Combination">
                  <i class="fas fa-horse text-primary h4 mb-0 mr-1"></i>
                  <i class="fas fa-plus text-primary h4 mb-0"></i>
                  <i class="fas fa-user text-primary h4 mb-0"></i>
                </span>
              </ng-container>
            </td>
            <td>
              <button class="btn btn-sm btn-info mr-2" (click)="editCircuit(circuit)">
                <i class="mdi mdi-pencil"></i>
              </button>
              <button class="btn btn-sm btn-danger" (click)="confirmDelete(circuit)">
                <i class="mdi mdi-delete"></i>
              </button>
            </td>
          </tr>
          <tr *ngIf="circuits.length === 0">
            <td colspan="6" class="text-center">No circuits found</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div id="modal-delete-circuit" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Delete Circuit</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body p-4">
        <p>Are you sure you want to delete this circuit?</p>
        <p class="text-muted">This action cannot be undone.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="deleteCircuit()">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Delete
        </button>
      </div>
    </div>
  </div>
</div> 