import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder, NonNullableFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { CaballoService } from 'src/app/services/caballo.service';
import { firstValueFrom } from 'rxjs';
import { PersonaService } from 'src/app/services/persona.service';


@Component({
  selector: 'app-form-horse',
  templateUrl: './form-horse.component.html',
  styleUrls: ['./form-horse.component.css']
})

export class FormHorseComponent implements OnInit, OnChanges {

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private caballoService: CaballoService,
    private personaService: PersonaService
  ) {
    this.actions = {
      registerNewHorse: false,
      updatingHorse: false,
    };
    this.horseForm = this.formBuilder.group({
      fei: [''],
      usef_id: [''],
      ec_id: [''],
      ushja_id: [''],
      fullname: [''],
      chip_id: [''],
      owner_id: [''],
      country_of_birth: [''],
      color_code: [''],
      color_complement: [''],
      breed: [''],
      gender: [''],
      date_of_birth: [''],
      sire: [''],
      dam: [''],
      height: [''],
      height_category: [''],
      notas: ['']
    });


  }
  @Input() public horse_id: string | number;
  @Input() public showId: number;
  @Output() public onCreate: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();

  public horse: any;
  public horseForm: UntypedFormGroup;
  public actions: any;
  public token: string;
  public sessionData: any;
  public userId: string;
  // public showId: string | false;
  public loaders: any;
  public owners: any[] = [];
  public selectedHorse: string;
  public showPanel: boolean;
  public feiError: string;
  public usefError: string;
  public ecError: string;

  isLoading = false;

  ngOnInit(): void {
  }

  // este método se ejecuta cada vez que el valor de la variable id_caballo cambia
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.horse_id) { // si el id_caballo cambia y es diferente de null
      this.actions.registerNewHorse = !this.horse_id;
      this.actions.updatingHorse = !!this.horse_id;
      this.horseForm.reset('');
      this.getHorseInfo(this.horse_id);
      return;
    }
  }

  public displayOwner(idPersona): string {
    if (!idPersona) {
      return '';
    }
    const owner = this.owners.find(p => p.id_persona === idPersona);

    return owner ? owner.fullname : idPersona;
  }

  public getPersonas(e) {
    const filtro: string = e.target.value;
    if (filtro.length > 3) {
      firstValueFrom(this.personaService.getPersonasFiltradas(filtro, this.showId))
        .then(response => {
          if (!response.error) {
            this.owners = response.personas;
          }
        })
        .catch(error => console.log(error));
    } else {
      this.owners = [];
    }
  }

  private formatDateToYYYYMMDD(dateString: string): string {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }

  public async getHorseInfo(horse_id) {
    if (!horse_id) {
      return;
    }

    const { data, error } = await firstValueFrom(this.caballoService.getInfo(this.horse_id))
      .then(r => ({ data: r?.error ? null : r?.data, error: r?.error ? r?.message : null }))
      .catch(e => ({ data: null, error: e?.message || e }));
    if (error) {
      console.log('Error getHorseInfo: ', error);
      return;
    }
    this.owners = [{
      id_persona: data.owner_id,
      fullname: data.owner_name
    }];

    

    this.horseForm.patchValue({
      fei: data?.fei,
      usef_id: data?.usef_id,
      ushja_id: data?.ushja_id,
      ec_id: data?.ec_id,
      fullname: data?.fullname,
      chip_id: data?.chip_id,
      owner_id: data?.owner_id,
      country_of_birth: data?.country_of_birth,
      color_code: data?.color_code,
      color_complement: data?.color_complement,
      breed: data?.breed,
      gender: data?.gender,
      date_of_birth: this.formatDateToYYYYMMDD(data?.date_of_birth),
      sire: data?.sire,
      dam: data?.dam,
      height: data?.height,
      height_category: data?.height_category,
      notas: data?.notes
    });
  }

  public async getHorseFeiInfo() {
    console.log('Getting horse FEI info');
    try {
      this.isLoading = true;
      const response = await firstValueFrom(this.caballoService.getFeiInfo(this.horseForm.value.fei));
      if (response.error) {
        this.feiError = response.message;
        this.isLoading = false;
        console.log('FEI Error: ', response.message);
      } else {
        this.feiError = '';
        const caballo = response?.caballo ?? {};
        this.horseForm.patchValue({
          fullname: caballo.name || this.horseForm.value.fullname,
          chip_id: caballo.chipId || this.horseForm.value.chip_id,
          owner_id: caballo.owner || this.horseForm.value.owner_id,
          country_of_birth: caballo.countryOfOrigin || this.horseForm.value.country_of_birth,
          color_code: caballo.color || this.horseForm.value.color_code,
          color_complement: caballo.colorComplement || this.horseForm.value.color_complement,
          breed: caballo.breed || this.horseForm.value.breed,
          date_of_birth: caballo.dateOfBirth || this.horseForm.value.date_of_birth,
          sire: caballo.sire || this.horseForm.value.sire,
          dam: caballo.dam || this.horseForm.value.dam,
          gender: caballo.gender || this.horseForm.value.gender,
        });

        this.isLoading = false;
      }
    } catch (error) {
      // this.handleError(error, "It has not been possible to perform the query.");
      console.log(error);
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  public async getHorseUsefData() {

    try {
      this.isLoading = true;

      const { usefData, error } = await firstValueFrom(this.caballoService.getUsefData(this.horseForm.value.usef_id, this.showId))
        .then(r => ({ usefData: r?.error ? null : r?.usefData, error: r?.error ? r?.message : null }))
        .catch(e => ({ usefData: null, error: e?.message || e }));
      if (error) {
        this.usefError = error;
        console.log('USEF Error: ', error);
        this.isLoading = false;
        return;
      }
      this.usefError = '';
      const caballo = usefData ?? {};
      this.horseForm.patchValue({
        fullname: caballo.name || this.horseForm.value.fullname,
        chip_id: caballo.chip || this.horseForm.value.chip_id,
        country_of_birth: caballo.countryOfOrigin || this.horseForm.value.country_of_birth,
        color_code: caballo.color || this.horseForm.value.color_code,
        breed: caballo.breed || this.horseForm.value.breed,
        gender: caballo.gender || this.horseForm.value.gender,
        date_of_birth: caballo.dateofBirth || this.horseForm.value.date_of_birth,
        sire: caballo.sire || this.horseForm.value.sire,
        dam: caballo.dam || this.horseForm.value.dam,
        owner_id: caballo.owner.id ? caballo.owner.id : this.horseForm.value.owner_id,
        fei: caballo.fei.trim() || this.horseForm.value.fei,
        height: caballo.height || this.horseForm.value.height
      });
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }

  }

  public async getHorseECData() {
    const ec_horse = this.horseForm.value.ec_id;
    if (!ec_horse) {
      this.isLoading = false;
      // TODO: Add notification service
      return;
    }

    this.isLoading = true;
    let ecData = null;

    try {
      ecData = await firstValueFrom(this.caballoService.getECData(ec_horse));
      this.ecError = ecData?.error ?? false;
    } catch (err) {
      this.ecError = err?.message ?? 'An unknown error occurred form-horse-component';
      this.isLoading = false;
      return;
    } finally {
      this.isLoading = false;
    }

    this.ecError = '';
    const caballo = ecData ?? {};
    console.log(caballo);
    this.horseForm.patchValue({
      fullname: caballo.name || this.horseForm.value.fullname, // 4
      chip_id: caballo.chip || this.horseForm.value.chip_id,
      country_of_birth: caballo.countryOfOrigin || this.horseForm.value.country_of_birth,
      color_code: caballo.color || this.horseForm.value.color_code, // 3
      breed: caballo.breed || this.horseForm.value.breed, // 2
      gender: caballo.gender || this.horseForm.value.gender, // 6
      date_of_birth: caballo.dateofBirth || this.horseForm.value.date_of_birth, // 1
      sire: caballo.sire || this.horseForm.value.sire,
      dam: caballo.dam || this.horseForm.value.dam,
      owner_id: caballo.owner?.id || this.horseForm.value.owner_id,
      fei: caballo.fei ? caballo.fei.trim() : this.horseForm.value.fei,
      height: caballo.height || this.horseForm.value.height // 5
    });

    this.isLoading = false;
  }


  public cancel() {
    this.onCancel.emit();
    this.horseForm.reset('');
  }

  public async submit() {
    // Validar que el valor de owner sea tomado desde la lista
    if (!this.owners.find(p => p.id_persona === this.horseForm.value.owner)) {
      this.horseForm.get('owner_id').setValue('');
    }

    if (this.horseForm.valid) {
      const caballo = {
        id_caballo: this.horse_id || null,
        breed: this.horseForm.value.breed || '',
        chip_id: this.horseForm.value.chip_id || '',
        color_code: this.horseForm.value.color_code || '',
        color_complement: this.horseForm.value.color_complement || '',
        country_of_birth: this.horseForm.value.country_of_birth || '',
        dam: this.horseForm.value.dam || '',
        date_of_birth: this.horseForm.value.date_of_birth || '',
        fei: this.horseForm.value.fei || '',
        fullname: this.horseForm.value.fullname || '',
        gender: this.horseForm.value.gender || '',
        height: this.horseForm.value.height || '',
        height_category: this.horseForm.value.height_category || '',
        notas: this.horseForm.value.notas || '',
        owner_id: this.horseForm.value.owner_id || null,
        sire: this.horseForm.value.sire || '',
        usef_id: this.horseForm.value.usef_id || '',
        ec_id: this.horseForm.value.ec_id || '',
        ushja_id: this.horseForm.value.ushja_id || '',
      };
      // Save/Edit Horse nest
      const { data, error } = await this.caballoService.save(caballo)
        .then(r => ({ data: r?.data, error: null }))
        .catch(e => ({ data: null, error: e?.message || e }));
      if (error) {
        console.log('Error saving horse');
        return;
      }
      if (this.actions.updatingHorse) {
        this.onUpdate.emit(data);
      } else if (this.actions.registerNewHorse) {
        this.onCreate.emit(data);
      }
    } else {
      console.log('Form is invalid');
      // Specify what's wrong with the form
      console.log(this.horseForm.value);
      // this.horseForm.markAllAsTouched();

      Object.keys(this.horseForm.controls).forEach(key => {
        const control = this.horseForm.get(key);
        if (control.errors) {
          console.log(`${key} is invalid:`, control.errors);
        }
      });
      console.log('Form value:', this.horseForm.value);
      console.log('Form errors:', this.horseForm.errors);

      
    }
  }

  // TODO: TEST FEI ID FORMAT
  // TODO: TEST USEF ID FORMAT

}
