import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Circuit } from '../circuit.interface';
import { CircuitsService } from '../../services/circuits.service';
import { Class } from '../../interfaces/class.interface';
declare var $: any;

@Component({
  selector: 'app-add-circuit',
  templateUrl: './add-circuit.component.html',
  styleUrls: ['./add-circuit.component.scss']
})
export class AddCircuitsComponent {
  circuitForm: FormGroup;
  loading = false;
  currentYear = new Date().getFullYear();
  isEditing = false;
  pruebas: Class[] = [];
  circuitPruebas: Class[] = [];
  selectedClasses: string[] = [];
  searchText: string = '';
  removedClasses: string[] = [];
  searchAllOrganizers = false;

  private searchTimeout: any;
  minSearchLength = 3;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddCircuitsComponent>,
    private circuitsService: CircuitsService,
    @Inject(MAT_DIALOG_DATA) public data: { 
      idClub: string, 
      season: string,
      circuit?: Circuit // Optional circuit for editing
    }
  ) {
    this.isEditing = !!data.circuit;
    this.circuitForm = this.fb.group({
      name: [data.circuit?.name || '', [Validators.required]],
      points_system: [data.circuit?.points_system || '', [Validators.required]],
      season: [data.circuit?.season || data.season, [
        Validators.required,
        Validators.min(this.currentYear),
        Validators.max(this.currentYear + 1)
      ]],
      id_club: [data.circuit?.id_club || data.idClub, [Validators.required]],
      tracking_type: [data.circuit?.tracking_type || '', [Validators.required]]
    });

    if (this.data.circuit?.pruebas) {
      this.selectedClasses = this.data.circuit.pruebas;
    }

    if (this.isEditing) {
      this.loadCircuitClasses();
    }
  }

  private loadClasses(search?: string) {
    this.circuitsService.getClassesByClubAndSeason(
      this.data.idClub,
      this.data.season,
      search,
      this.searchAllOrganizers
    ).subscribe({
      next: (response) => {
        if (!response.error) {
          // Filter out classes that are already in circuitPruebas
          this.pruebas = response.data.filter(p => 
            !this.circuitPruebas.some(cp => cp.id_prueba_concurso === p.id_prueba_concurso)
          );
        }
      },
      error: (error) => {
        console.error('Error loading pruebas:', error);
      }
    });
  }

  private loadCircuitClasses() {
    this.circuitsService.getCircuitClasses(this.data.circuit.id)
      .subscribe({
        next: (response) => {
          if (!response.error) {
            this.circuitPruebas = response.data;
            this.selectedClasses = this.circuitPruebas.map(p => p.id_prueba_concurso);
          }
        },
        error: (error) => {
          console.error('Error loading circuit classes:', error);
        }
      });
  }

  isClassSelected(classId: string): boolean {
    const inSelectedClasses = this.selectedClasses.includes(classId);
    const prueba = this.pruebas.find(p => p.id_prueba_concurso === classId);
    const inExistingCircuit = prueba?.circuit_id === this.data.circuit?.id;
    
    return inSelectedClasses || inExistingCircuit;
  }

  toggleClass(classId: string, checked: boolean) {
    const index = this.selectedClasses.indexOf(classId);
    
    // If unchecking a class from circuitPruebas, add to removedClasses
    const circuitPruebaIndex = this.circuitPruebas.findIndex(p => p.id_prueba_concurso === classId);
    if (circuitPruebaIndex !== -1) {
      if (!checked) {
        this.circuitPruebas.splice(circuitPruebaIndex, 1);
        this.removedClasses.push(classId); // Track removed class
      }
    }

    // Update selectedClasses array
    if (checked && index === -1) {
      this.selectedClasses.push(classId);
      // If it was previously removed, remove from removedClasses
      const removeIndex = this.removedClasses.indexOf(classId);
      if (removeIndex !== -1) {
        this.removedClasses.splice(removeIndex, 1);
      }
    } else if (!checked && index !== -1) {
      this.selectedClasses.splice(index, 1);
    }
  }

  onSubmit() {
    if (this.circuitForm.valid) {
      this.loading = true;
      const circuit: Circuit = {
        ...this.circuitForm.value,
        pruebas: this.selectedClasses,
        removedPruebas: this.removedClasses
      };
      
      const request = this.isEditing ? 
        this.circuitsService.updateCircuit(this.data.circuit.id, circuit) :
        this.circuitsService.createCircuit(circuit);

      request.subscribe({
        next: (response) => {
          if (!response.error) {
            this.dialogRef.close(response.data);
            $.NotificationApp.send(
              "Success",
              `Circuit ${this.isEditing ? 'updated' : 'created'} successfully`,
              'bottom-right',
              '#06d5a1',
              'success'
            );
          } else {
            $.NotificationApp.send(
              "Error",
              response.message,
              'bottom-right',
              '#fa5c7c',
              'error'
            );
          }
          this.loading = false;
        },
        error: (error) => {
          console.error('Error saving circuit:', error);
          $.NotificationApp.send(
            "Error",
            `Failed to ${this.isEditing ? 'update' : 'create'} circuit`,
            'bottom-right',
            '#fa5c7c',
            'error'
          );
          this.loading = false;
        }
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSearch(event: any) {
    const searchValue = event.target.value;
    
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    // Only search if we have enough characters
    if (searchValue.length >= this.minSearchLength) {
      this.searchTimeout = setTimeout(() => {
        this.loadClasses(searchValue);
      }, 300);
    } else {
      this.pruebas = []; // Clear results if search is too short
    }
  }

  get displayedPruebas(): Class[] {
    return [...this.circuitPruebas, ...this.pruebas.filter(p => !this.circuitPruebas.find(cp => cp.id_prueba_concurso === p.id_prueba_concurso))];
  }

  public handleCheckboxChange(id: string, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    this.toggleClass(id, checkbox.checked);
  }

  toggleSearchAllOrganizers() {
    this.searchAllOrganizers = !this.searchAllOrganizers;
    if (this.searchText.length >= this.minSearchLength) {
      this.loadClasses(this.searchText);
    }
  }
} 