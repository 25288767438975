<h2 mat-dialog-title>{{ isNew ? 'Add Entry' : 'Edit Entry' }} for {{ passName }}</h2>

<form [formGroup]="entryForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Entry #</mat-label>
      <input matInput formControlName="entry" placeholder="Enter entry #">
      <mat-error *ngIf="entryForm.get('entry')?.hasError('required')">
        Entry # is required
      </mat-error>
    </mat-form-field>  
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Horse Name</mat-label>
      <input matInput formControlName="name" placeholder="Enter horse name">
      <mat-error *ngIf="entryForm.get('name')?.hasError('required')">
        Horse name is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>EC</mat-label>
      <input matInput formControlName="ec" placeholder="Enter EC">
    </mat-form-field>
    
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Owner Name</mat-label>
      <input matInput formControlName="owner" placeholder="Enter owner name">
    </mat-form-field>
    
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Riders</mat-label>
      <input matInput formControlName="riders" placeholder="Enter riders">
    </mat-form-field>
    
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Trainer Name</mat-label>
      <input matInput formControlName="trainer" placeholder="Enter trainer name">
    </mat-form-field>
    
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Purchase Date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="purchaseDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option value="active">Active</mat-option>
        <mat-option value="expired">Expired</mat-option>
        <mat-option value="cancelled">Cancelled</mat-option>
        <mat-option value="">Inactive</mat-option>
      </mat-select>
      <mat-error *ngIf="entryForm.get('status')?.hasError('required')">
        Status is required
      </mat-error>
    </mat-form-field>
  </div>
  
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button 
      mat-raised-button 
      color="primary" 
      type="submit" 
      [disabled]="entryForm.invalid">
      {{ isNew ? 'Add' : 'Save' }}
    </button>
  </div>
</form> 