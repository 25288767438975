<div class="text-center" *ngIf="loading">
  <div class="spinner-border text-primary m-2" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <p>Loading class data...</p>
</div>

<form
  [formGroup]="classForm"
  (ngSubmit)="onSubmit()"
  *ngIf="!loading"
  class="class-form"
>
  <!-- Basic Information -->
  <div class="card mb-3">
    <div class="card-header">
      <h5 class="mb-0">
        <i class="mdi mdi-information-outline mr-1"></i>Basic Information
      </h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label><i class="mdi mdi-pound mr-1"></i>Number</label>
            <input
              type="text"
              class="form-control"
              formControlName="numero_prueba"
              [ngClass]="{
                'is-invalid':
                  classForm.get('numero_prueba')?.invalid &&
                  classForm.get('numero_prueba')?.touched
              }"
              required
            />
          </div>
        </div>
        <div class="col-9">
          <div class="form-group">
            <label><i class="mdi mdi-format-title mr-1"></i>Name</label>
            <input
              type="text"
              class="form-control"
              formControlName="nombre"
              [ngClass]="{
                'is-invalid':
                  classForm.get('nombre')?.invalid &&
                  classForm.get('nombre')?.touched
              }"
              required
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label
              ><i class="mdi mdi-text-box-outline mr-1"></i>Description</label
            >
            <textarea
              class="form-control"
              formControlName="description"
            ></textarea>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label><i class="mdi mdi-text-box-outline mr-1"></i>Division</label>
            <select class="form-control" formControlName="division">
              <option value="">-- Select a division --</option>
              <option *ngFor="let division of divisions" [value]="division.id">
                {{ division.name }} ({{ division.id }})
              </option>
            </select>
          </div>
        </div>
        <!-- Temporary debug info -->
      </div>
    </div>
  </div>

  <!-- Technical Details -->
  <div class="card mb-3">
    <div class="card-header">
      <h5 class="mb-0">
        <i class="mdi mdi-clipboard-list-outline mr-1"></i>Technical Details
      </h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label
              ><i class="mdi mdi-file-document-outline mr-1"></i
              >Article/Table</label
            >
            <select
              class="form-control"
              formControlName="modalidad"
              [ngClass]="{
                'is-invalid':
                  classForm.get('modalidad')?.invalid &&
                  classForm.get('modalidad')?.touched
              }"
              required
            >
              <option value="" disabled>-- Select an Article/Table --</option>
              <option
                *ngFor="let modalidad of modalidades"
                [value]="modalidad.id"
              >
                {{ modalidad.nombre }} ({{ modalidad.clave }})
              </option>
            </select>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label><i class="mdi mdi-ruler mr-1"></i>Height</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                formControlName="altura"
              />
              <div class="input-group-append">
                <select class="form-control" formControlName="height_unit">
                  <option value="M">M</option>
                  <option value="E">E</option>
                  <option value="A">A</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4" *ngIf="concurso?.organizing_country === 'CAN'">
          <div class="form-group">
            <label>
              <i class="mdi mdi-trophy-variant mr-1"></i>
              Competition Level
            </label>
            <select
              class="form-control"
              formControlName="type"
              placeholder="Select competition level"
            >
              <option value="" disabled selected>
                Select competition level
              </option>
              <option value="gold">Gold</option>
              <option value="silver">Silver</option>
              <option value="bronze">Bronze</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label
              ><i class="mdi mdi-account-group mr-1"></i>Open to
              <small>(ctrl + click to select multiple)</small></label
            >
            <select multiple class="form-control" formControlName="categories">
              <option value="junior">Junior</option>
              <option value="amateur">Amateur</option>
              <option value="pro">Pro</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Scheduling -->
  <!-- <div class="card mb-3">
    <div class="card-header">
      <h5 class="mb-0"><i class="mdi mdi-calendar-clock mr-1"></i>Scheduling</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label><i class="mdi mdi-stadium mr-1"></i>Ring</label>
            <select class="form-control" formControlName="pista">
              <option value=""></option>
              <option [value]="pista" *ngFor="let pista of pistas">{{ pista }}</option>
            </select>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label><i class="mdi mdi-calendar mr-1"></i>Start Date</label>
            <div class="input-group">
              <input type="text"
                     class="form-control"
                     formControlName="startDate"
                     placeholder="MM/DD/YYYY"
                     [matDatepicker]="picker"
                     (click)="picker.open()">
              <div class="input-group-append">
                <button type="button" 
                        class="btn btn-light" 
                        (click)="picker.open()">
                  <i class="mdi mdi-calendar"></i>
                </button>
              </div>
            </div>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label><i class="mdi mdi-clock-outline mr-1"></i>Time</label>
            <input type="time" 
                   class="form-control" 
                   formControlName="startTime">
          </div>
        </div>
        
        <div class="col-3 d-flex" *ngIf="classForm.get('startTime').value">
          <div class="form-group my-auto">
            <div class="custom-control custom-checkbox mt-2">
              <input type="checkbox" 
                     class="custom-control-input" 
                     id="hardStartCheck" 
                     formControlName="hard_start">
              <label class="custom-control-label" for="hardStartCheck">
                <i class="mdi mdi-clock-alert mr-1"></i>Hard Start
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Financial Information -->
  <div class="card mb-3">
    <div class="card-header">
      <h5 class="mb-0">
        <i class="mdi mdi-currency-usd mr-1"></i>Financial Information
      </h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label><i class="mdi mdi-currency-usd mr-1"></i>Cost</label>
            <input
              type="number"
              min="0"
              class="form-control"
              formControlName="costo"
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label><i class="mdi mdi-trophy-outline mr-1"></i>Prize</label>
            <input
              type="number"
              min="0"
              class="form-control"
              formControlName="premio"
            />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label><i class="mdi mdi-podium mr-1"></i>Prize Distribution</label>
            <select class="form-control" formControlName="prize_distribution">
              <option value=""></option>
              <option
                [value]="distribucion.id"
                *ngFor="let distribucion of distribuciones"
              >
                {{ distribucion.nombre }}
              </option>
            </select>
          </div>
        </div>
        <!-- Charges section -->
        <div class="col-12">
          <div class="form-group">
            <label><i class="mdi mdi-cash-multiple mr-1"></i>Charges</label>
            <div class="input-group">
              <select class="form-control" formControlName="selectedCharge">
                <option value="">-- Select a charge --</option>
                <option
                  [value]="concepto.id"
                  *ngFor="let concepto of availableConcepts"
                >
                  {{ concepto.nombre }}
                </option>
              </select>
              <div class="input-group-append">
                <button
                  class="btn btn-info"
                  type="button"
                  (click)="addCharge()"
                >
                  Add
                </button>
              </div>
            </div>
            <p class="mb-0 mt-2">
              <span
                style="font-size: 0.75rem"
                class="badge badge-primary badge-pill mr-1"
                *ngFor="let charge of selectedCharges"
              >
                {{ charge.name }}
                <a
                  href="javascript:void(0)"
                  class="text-white"
                  (click)="removeCharge(charge.id)"
                >
                  <i class="mdi mdi-close"></i>
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Additional Information -->
  <div class="card mb-3">
    <div class="card-header">
      <h5 class="mb-0">
        <i class="mdi mdi-card-text-outline mr-1"></i>Additional Information
      </h5>
    </div>
    <div class="card-body">
      <div class="row">
        <!-- Section codes -->
        <div class="col-6">
          <div class="form-group">
            <label><i class="mdi mdi-barcode mr-1"></i>USEF Section code</label>
            <input
              type="text"
              class="form-control"
              formControlName="usef_section"
            />
          </div>
        </div>
        <div class="col-6" *ngIf="concurso?.organizing_country === 'CAN'">
          <div class="form-group">
            <label><i class="mdi mdi-barcode mr-1"></i>EC Section code</label>
            <input
              type="text"
              class="form-control"
              formControlName="ec_section"
              placeholder="Search by code or description..."
              [matAutocomplete]="autoEc"
              (blur)="onEcCodeBlur()"
            />
            <mat-autocomplete
              #autoEc="matAutocomplete"
              [displayWith]="displayEcCode"
              (optionSelected)="onEcCodeSelected($event)"
              [autoActiveFirstOption]="true"
              [panelWidth]="'auto'"
              class="ec-code-autocomplete"
            >
              <mat-option
                *ngFor="let code of filteredEcCodes | async"
                [value]="code.code"
                class="ec-code-option"
              >
                <div class="ec-code-content">
                  <span class="ec-code">{{ code.code }}</span>
                  <span class="ec-description">{{ code.description }}</span>
                </div>
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <!-- Officials -->
        <div class="col-6">
          <div class="form-group">
            <label><i class="mdi mdi-gavel mr-1"></i>Judge</label>
            <select class="form-control" formControlName="juez">
              <option value="" disabled>-- Select a Judge --</option>
              <option *ngFor="let juez of jueces" [value]="juez.id">
                {{ juez.nombre }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label><i class="mdi mdi-pencil-ruler mr-1"></i>Designer</label>
            <input
              type="text"
              class="form-control"
              formControlName="designer"
              [matAutocomplete]="autoDesigner"
            />
            <mat-autocomplete
              #autoDesigner="matAutocomplete"
              [displayWith]="displayPersona"
            >
              <mat-option
                *ngFor="let designer of personas"
                [value]="designer.id_persona"
              >
                <img [src]="designer.flag" style="height: 0.9em" />
                <span style="font-weight: bold">{{
                  designer.fullname || "-"
                }}</span>
                <div>
                  <span>
                    <img
                      src="/assets/images/icons/usef.svg"
                      alt="USEF: "
                      style="height: 1em; padding-right: 5px"
                    />
                    {{ designer.usef }}
                  </span>
                  <span *ngIf="designer.fei">
                    <img
                      src="/assets/images/icons/fei.svg"
                      alt="FEI: "
                      style="
                        height: 1em;
                        padding-left: 10px;
                        padding-bottom: 5px;
                      "
                    />
                    {{ designer.fei }}
                  </span>
                </div>
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Media -->
  <div class="card mb-3">
    <div class="card-header">
      <h5 class="mb-0"><i class="mdi mdi-image-multiple mr-1"></i>Media</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <!-- Video options -->
        <div class="col-9">
          <div class="form-group">
            <label><i class="mdi mdi-youtube mr-1"></i>YouTube URL</label>
            <input
              type="text"
              class="form-control"
              formControlName="youtube"
              placeholder="Enter YouTube video URL"
            />
          </div>
        </div>
        <div class="col-3 d-flex">
          <div class="form-group my-auto">
            <div class="custom-control custom-checkbox mt-2">
              <input
                class="custom-control-input"
                id="video"
                type="checkbox"
                formControlName="video"
              />
              <label class="custom-control-label" for="video"
                >Video available</label
              >
            </div>
          </div>
        </div>
        <!-- Images -->
        <div class="col-6">
          <!-- Sponsor banner upload -->
          <div class="form-group">
            <button
              class="btn"
              [class.btn-outline-alternate]="!class?.sponsorImage"
              [class.btn-outline-success]="class?.sponsorImage"
              (click)="sponsorBannerInput.click()"
              type="button"
            >
              <i class="dripicons-pin"></i>
              Sponsor banner
              <i class="mdi mdi-check ml-1" *ngIf="class?.sponsorImage"></i>
            </button>
            <a
              *ngIf="class?.sponsorImage"
              [href]="class.sponsorImage"
              target="_blank"
              class="btn btn-link btn-sm"
            >
              <i class="mdi mdi-eye"></i> View image
            </a>
            <button
              *ngIf="class?.sponsorImage"
              class="btn btn-link btn-sm text-danger"
              type="button"
              (click)="clearImage('sponsor')"
            >
              <i class="mdi mdi-close"></i> Clear
            </button>
            <input
              #sponsorBannerInput
              class="d-none"
              accept="image/jpeg"
              type="file"
              (change)="onSponsorImage($event)"
            />
          </div>
        </div>
        <div class="col-6">
          <!-- Course design upload -->
          <div class="form-group">
            <button
              class="btn"
              [class.btn-outline-alternate]="!class?.croquis"
              [class.btn-outline-success]="class?.croquis"
              (click)="courseDesignInput.click()"
              type="button"
            >
              <i class="dripicons-map"></i>
              Course design
              <i class="mdi mdi-check ml-1" *ngIf="class?.croquis"></i>
            </button>
            <a
              *ngIf="class?.croquis"
              [href]="class.croquis"
              target="_blank"
              class="btn btn-link btn-sm"
            >
              <i class="mdi mdi-eye"></i> View image
            </a>
            <button
              *ngIf="class?.croquis"
              class="btn btn-link btn-sm text-danger"
              type="button"
              (click)="clearImage('course')"
            >
              <i class="mdi mdi-close"></i> Clear
            </button>
            <input
              #courseDesignInput
              class="d-none"
              accept=".jpg,.jpeg,.png"
              type="file"
              (change)="onCourseImage($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Form Actions -->
  <div class="text-right">
    <button type="button" class="btn btn-secondary" (click)="cancel.emit()">
      Cancel
    </button>
    <button type="submit" class="btn btn-success ml-2">Save Class</button>
  </div>
</form>
