import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';
@Injectable()
export class PruebaService extends Api{

  constructor(private http: HttpClient, private authService: AuthService) {
    super();
  }

  public getPruebas(idConcurso, horario = false): Observable<any>{
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}v2/pruebas/?c=${idConcurso}&h=${horario}`, {headers: headers});
  }

  public getPruebasList(idConcurso): Observable<any>{
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}pruebas/getList/?c=${idConcurso}`, {headers: headers});
  }

  public getPruebasNest(idConcurso): Observable<any>{
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8',
      'authorization': 'Bearer ' + this.authService.getAuthorizationToken() || '',
    };

    return this.http.get(`${environment.api.nest.uri}pruebas/?idConcurso=${idConcurso}`, {headers: headers});
  }

  public getInscritos(idConcurso): Observable<any>{
    const params = JSON.stringify({  idConcurso });
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.post(`${environment.api.uri}pruebas/inscritos/`, params, {headers: headers});
  }


  public createPrueba(prueba, idConcurso): Observable<any>{
    const params = JSON.stringify({ prueba, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/add/`, params, {headers: headers});
  }

  public createPruebaNest(prueba, idConcurso): Observable<any>{
    prueba.id_concurso = idConcurso;
    const params = JSON.stringify( prueba );
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8',
    };

    return this.http.post(`${environment.api.nest.uri}pruebas/`, params, {headers: headers});
  }

  public updatePrueba(prueba, idConcurso): Observable<any>{
    const params = JSON.stringify({ prueba, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/update/`, params, {headers: headers});
  }

  public updatePruebaNest(prueba, id): Observable<any>{
    const params = JSON.stringify(prueba);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.put(`${environment.api.nest.uri}pruebas/${id}`, params, {headers: headers});
  }

  public deletePrueba(ipc, idUsuario, idConcurso): Observable<any>{
    const params = JSON.stringify({ ipc, idUsuario, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/delete/`, params, {headers: headers});
  }

  public getPremios(ipc): Observable<any>{
    const params = JSON.stringify({ipc});
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/getPremios/`, params, {headers: headers});
  }

  public getPremiosNest(ipc): Observable<any>{
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.get(`${environment.api.nest.uri}pruebas/getPrizes/${ipc}`, {headers: headers});
  }

  public agregarPremio(datos): Observable<any>{
    const params = JSON.stringify(datos);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/agregarPremio/`, params, {headers: headers});
  }

  public eliminarPremio(idPremio): Observable<any>{
    const params = JSON.stringify({idPremio});
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/eliminarPremio/`, params, {headers: headers});
  }

  public getPremiosPagarAparte(idConcurso): Observable<any>{
    const params = JSON.stringify({idConcurso});
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/premiosPagarAparte/`, params, {headers: headers});
  }

  public guardarReferenciaPremio(idPremio, referencia): Observable<any>{
    const params = JSON.stringify({idPremio, referencia});
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/guardarReferenciaPremio/`, params, {headers: headers});
  }

  public getPrueba(ipc: string): Observable<any>{
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}pruebas/get/?p=${ipc}`, {headers: headers});
  }

  public getPruebaNest(ipc: string): Observable<any>{
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.nest.uri}pruebas/${ipc}`, {headers: headers});
  }

  public getEntrada(ipc: string): Observable<any>{
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}jueces/entrada/?p=${ipc}`, {headers: headers});
  }

  public getPruebasFei(idConcurso): Observable<any>{
    const params = JSON.stringify({});
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}fei/get_classes.php?c=${idConcurso}`, params, {headers: headers});
  }

  public saveFeiClasses(pruebas, idConcurso): Observable<any>{
    const params = JSON.stringify({ pruebas, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}fei/save_classes.php`, params, {headers: headers});
  }

  public guardarNumeroCheque(idConcurso, entry, numero, monto, idUsuario): Observable<any>{
    const params = JSON.stringify({ idConcurso, entry, numero, monto, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/guardarNumeroCheque/`, params, {headers: headers});
  }

  public cambiarNumeroCheque(id, entry, amount, idConcurso, numero, idUsuario): Observable<any>{
    const params = JSON.stringify({ id, entry, amount, idConcurso, numero, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/cambiarNumeroCheque/`, params, {headers: headers});
  }

  public actualizarPruebasHunters(pruebas: any[]): Observable<any>{
    const params = JSON.stringify({ pruebas });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/actualizar-pruebas-hunters/`, params, {headers: headers});
  }

  public getDivisions(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/divisiones/`, params, {headers: headers});
  }

  public getDivisionEntries(idDivision): Observable<any>{
    const params = JSON.stringify({ idDivision });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/division-entries/`, params, {headers: headers});
  }

  public saveChampionsAndReserves(idDivision, champions, reserves): Observable<any>{
    const params = JSON.stringify({ idDivision, champions, reserves });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/save-champions-reserves/`, params, {headers: headers});
  }

  public getDivision(id, idConcurso): Observable<any>{
    const params = JSON.stringify({ id , idConcurso});
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/division/`, params, {headers: headers});
  }

  public addDivision(divison, idConcurso): Observable<any>{
    const params = JSON.stringify({ divison, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/agregar-division/`, params, {headers: headers});
  }

  public updateDivision(divison, id, pruebas): Observable<any>{
    const params = JSON.stringify({ divison, id, pruebas });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/editar-division/`, params, {headers: headers});
  }

  public updateDivisionNest(division, id, pruebas): Observable<any>{
    const params = JSON.stringify( division);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.put(`${environment.api.nest.uri}divisiones/${id}`, params, {headers: headers});
  }

  public deleteDivision(id, recursive = false): Observable<any>{
    const params = JSON.stringify({ id, recursive });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/delete-division/`, params, {headers: headers});
  }

  public addDistribution(name, type, distribution, idConcurso): Observable<any>{
    const params = JSON.stringify({ name, type, distribution, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/agregar-distribucion/`, params, {headers: headers});
  }

  public editDistribution(id, name, type, distribution): Observable<any>{
    const params = JSON.stringify({ id, name, type, distribution });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/editar-distribucion/`, params, {headers: headers});
  }

  public getPrizeDistributions(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/distribuciones-premio/`, params, {headers: headers});
  }

  public guardarCampo(nombreCampo, tipoCampo, valor, idConcurso, idPrueba): Observable<any>{
    const params = JSON.stringify({ nombreCampo, tipoCampo, valor, idConcurso, idPrueba });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}pruebas/guardarCampo/`, params, {headers: headers});
  }

  // YOUTUBE
  public getInfoLivestream(ipc, idConcurso): Observable<any>{
    const params = JSON.stringify({ ipc: ipc, idConcurso: idConcurso });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}youtube/`, params, {headers: headers});
  }

  public createLivestream(datos, idConcurso): Observable<any>{
    const params = JSON.stringify({ datos: datos, idConcurso: idConcurso });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}youtube/add/`, params, {headers: headers});
  }

  public addVideoToPlaylist(datos, idConcurso): Observable<any>{
    const params = JSON.stringify({ datos: datos, idConcurso: idConcurso });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}youtube/playlist/add-video.php`, params, {headers: headers});
  }

  public editLivestream(datos, idConcurso): Observable<any>{
    const params = JSON.stringify({ datos: datos, idConcurso: idConcurso });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}youtube/edit/`, params, {headers: headers});
  }

  public statusLivestream(datos, idConcurso): Observable<any>{
    const params = JSON.stringify({ datos: datos, idConcurso: idConcurso });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}youtube/status/`, params, {headers: headers});
  }

  public thumbnailLivestream(datos, idConcurso): Observable<any>{
    const params = JSON.stringify({ datos: datos, idConcurso: idConcurso });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}youtube/thumbnail/`, params, {headers: headers});
  }

  public getPlaylistsYoutube(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}youtube/playlist/`, params, {headers: headers});
  }

  public applyPrizeDistribution(ipc: string, idUsuario: string): Observable<any>{
    const params = JSON.stringify({ ipc, idUsuario });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}pruebas/aplicar-distribucion-premios/`, params, {headers: headers});
  }

  public actualizarHorarios(pruebas): Observable<any>{
    const params = JSON.stringify({ pruebas });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}pruebas/actualizar-horarios/`, params, {headers: headers});
  }

  public splitClass(ipc: string | number, sections): Promise<any>{
    const params = JSON.stringify({ ipc, sections });
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return firstValueFrom(this.http.post(`${environment.api.nest.uri}pruebas/splitClass/`, params, {headers: headers})).catch(reason =>  ({ error: true, message: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.'))))}));
  }

  public async saveStatus(ipc: string, estatus: string): Promise<any>{
    const params = JSON.stringify({ ipc, estatus });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return firstValueFrom(this.http.post(`${environment.api.uri}pruebas/actualizarEstatus/`, params, { headers })).catch(reason =>  ({ error: true, message: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.'))))}));
  }

  public async assignUserToRing(pista: string, idUsuario: string | number, idConcurso: string | boolean): Promise<any>{
    const params = JSON.stringify({ pista, idUsuario, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return firstValueFrom(this.http.post(`${environment.api.nest.uri}pruebas/assignUserToRing/`, params, { headers })).catch(reason =>  ({ error: true, message: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.'))))}));
  }
}
