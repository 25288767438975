<div class="modal fade" id="loadingmodal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myCenterModalLabel">Getting information...</h4>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="loading">
          <p>
            obtaining data from the USHJA, this may take a few minutes.
            <br><br>
            please wait
          </p>
          <div style="margin: 10px 0px;">
            <div class="progress mb-0 progress-sm">
              <div class="progress-bar progress-lg bg-success" role="progressbar" [style.width.%]="avance"
                [attr.aria-valuenow]="avance" [attr.aria-valuemin]="0" [attr.aria-valuemax]="totalItems"></div>
            </div>
            <small>Current progress: {{ avance }}%</small>
          </div>
        </ng-container>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4); display: block; z-index: 2;">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>

<div id="loader-usef" style="background: rgba(255, 255, 255, 0.4); opacity: 1;">
  <div class="sk-cube-grid">
    <!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="US_Equestrian" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" width="150px" height="136.98px" viewBox="0 0 150 136.98"
      style="enable-background:new 0 0 150 136.98;" xml:space="preserve">
      <path class="path" stroke="#14377D" style="fill:#14377D;" d="M111.22,25.41c-3.13-7.34-18.59-17.24-18.59-17.24c8.25,9.64,8.51,9.21,13.07,18.33
            c0,6.3,4.13,9.03,4.13,9.03c5.56,6.43,15.9,14.46,17.81,23.1c1.69,7.56-5.73,14.72-13.2,12.94c6.69,1.61,18.41,1.48,20.11-7.51
            c2.13-11.34-16.42-26.28-22.8-34.44C111.74,25.93,111.22,25.41,111.22,25.41z M92.76,53.77c-4.47-1.17-16.24-4.47-16.72-10.21
            c0.26,3,2.69,15.11,22.93,16.89c0,0,11.16,0.78,12.68,5.3C108.7,57.15,100.27,55.72,92.76,53.77z M73.39,116.39
            c-0.52-9.16-1.22-18.37-4-27.14c-4.16-13.07-13.09-24.23-23.93-32.97c-29.24,47.92,27.62,59.9,27.62,59.9
            c-59.92-7.06-52.36-40.57-34.99-65.28c-4.61-3.05-9.42-5.69-14.26-7.9C10.29,36.79-4.18,29.05,1.12,12.12
            c-3.67,15.07,23.54,26.32,40.73,33.75c4.45-5.63,9.24-10.6,13.56-14.42C76.52,12.77,102.57,0,102.57,0
            c-25.99,19.47-42.6,35.83-52.59,49.54c1.19,0.59,2.22,1.15,3.04,1.66C75.78,65.54,84.12,92.2,73.39,116.39z" />
      <path class="path-text" stroke="#DA1A32" style="fill:#DA1A32;" d="M86.98,80.95h7.77v22.02c0,4.82,2.95,7.56,7.25,7.56c4.26,0,7.25-2.74,7.25-7.56V80.95h7.73v22.02
            c0,9.47-6.25,14.11-14.98,14.11s-15.03-4.6-15.03-14.11V80.95L86.98,80.95z M125.81,107.19c3.13,1.87,6.91,3.21,10.47,3.21
            c3.82,0,5.78-1.56,5.78-3.87c0-6.64-19.2-2.17-19.2-15.63c0-5.82,4.82-10.55,13.03-10.55c4.65,0,8.82,1.13,12.77,3l-2.82,6.04
            c-3.13-1.56-6.43-2.35-9.6-2.35c-3.39,0-5.43,1.61-5.43,3.73c0,6.56,19.2,1.91,19.2,15.63c0,5.99-4.56,10.64-13.68,10.64
            c-5.34,0-10.77-1.78-14.16-3.91L125.81,107.19z" />
      <path class="path-text" stroke="#14377D" style="fill:#14377D;" d="M25.7,123.34h9.6v2.48h-6.78v2.91h6.04v2.21h-6.04v3.08h6.82v2.48H25.7V123.34z M44.16,123.12
            c4,0,6.82,2.82,6.82,6.78c0,1.69-0.48,3.13-1.39,4.3l1.48,1.26l-1.43,1.52l-1.56-1.39c-1.09,0.74-2.43,1.13-3.95,1.13
            c-4,0-6.82-2.87-6.82-6.86C37.34,125.95,40.21,123.12,44.16,123.12z M45.46,130.55l2.08,1.87c0.39-0.69,0.61-1.56,0.61-2.52
            c0-2.65-1.61-4.39-3.95-4.39c-2.3,0-3.95,1.74-3.95,4.39c0,2.69,1.65,4.43,3.95,4.43c0.74,0,1.43-0.17,2.04-0.56l-2.08-1.78
            L45.46,130.55z M53.59,123.34h2.87v8.16c0,1.78,1.09,2.82,2.69,2.82c1.56,0,2.69-1,2.69-2.82v-8.16h2.87v8.16
            c0,3.52-2.3,5.21-5.56,5.21c-3.21,0-5.56-1.69-5.56-5.21C53.59,131.51,53.59,123.34,53.59,123.34z M67.66,123.34h9.6v2.48h-6.73
            v2.91h6.04v2.21h-6.04v3.08h6.82v2.48h-9.68V123.34z M80.82,133.07c1.17,0.69,2.56,1.17,3.87,1.17c1.39,0,2.13-0.56,2.13-1.43
            c0-2.48-7.12-0.83-7.12-5.78c0-2.17,1.78-3.91,4.82-3.91c1.74,0,3.26,0.43,4.73,1.13l-1.04,2.26c-1.17-0.56-2.39-0.87-3.56-0.87
            c-1.26,0-2,0.61-2,1.39c0,2.43,7.12,0.69,7.12,5.78c0,2.21-1.69,3.95-5.08,3.95c-1.95,0-4-0.65-5.26-1.43L80.82,133.07z
            M95.67,125.86h-4.34v-2.52h11.55v2.52h-4.34v10.64h-2.82v-10.64H95.67z M105.09,123.34h5.78c3.78,0,5.08,2.08,5.08,4.17
            c0,1.56-0.78,2.95-2.39,3.65l2.52,5.34H113l-2.04-4.91h-3v4.91h-2.87V123.34z M110.83,129.51c1.52,0,2.26-0.83,2.26-1.95
            c0-1.13-0.74-1.95-2.21-1.95h-2.91v3.91H110.83z M118.73,123.34h2.87v13.16h-2.87V123.34z M128.37,123.34h3.21l4.78,13.16h-3.04
            l-0.83-2.48h-4.99l-0.83,2.48h-3.08L128.37,123.34z M128.2,131.85h3.56l-1.78-5.34h-0.04L128.2,131.85z M138.45,123.34h2.69
            l5.86,8.69v-8.69h2.69v13.16H147l-5.86-8.64v8.64h-2.69V123.34z" />
    </svg>

  </div>
</div>

<div class="wrapper reportes">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu class="navbar-custom" [concurso]="nombreConcurso" [idConcurso]="idConcurso"></menu>
      <div class="row mt-4 mb-2 d-print-none">
        <div class="col-4">
          <div class="card m-0">
            <div class="card-header px-2 py-1 bg-dark text-white">
              <div class="tools"></div><i style="margin-right: 5px" class="mdi mdi-label-outline"></i><span
                class="title">Printable Labels</span>
            </div>
            <div class="card-body p-2">
              <div class="row">
                <div class="col-12 mb-1">
                  <span>Back Number Labels</span>
                  <button type="button" class="btn btn-light ml-1" (click)="getBackLabels()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
                <div class="col-12 mb-1" *ngIf="nombreConcurso == 'Split Rock Portland 2'">
                  <span>Back Number Labels <strong>(only W2)</strong></span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getFilteredBackLabels()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
                <div class="col-12">
                  <span>Class Labels</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getClassLabels()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card m-0">
            <div class="card-header px-2 py-1 bg-dark text-white">
              <div class="tools"></div><img src="assets/images/icons/usef.svg" alt="Update USEF Data" class="white-svg"
                style="width: 20px; margin-right:5px"><span class="title">USEF Info</span>
            </div>
            <div class="card-body p-2">
              <div class="row">
                <div class="col-12 mb-1">
                  <span>USEF Results Format</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getUsefResults()">
                    <i class="mdi mdi-file-excel-box"></i>
                  </button>
                  <!--button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;" (click)="getUsefResults()">
                    <i class="mdi mdi-file-excel-box mr-1" style="color: #207245;"></i>
                    <span>Download</span>
                  </button-->
                </div>
                <div class="col-12">
                  <span>Prize money report</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getStandarUsefResults()">
                    <i class="mdi mdi-eye"></i>
                  </button>
                </div>
                <div class="col-12">
                  <span>Printable Results (all classes)</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getPrintableResults()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>USEF Suspended/Membership Report People</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getMembershipPeopleTabla()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="updateSuspendedMembershipPeople()"> USEF
                    <i class="mdi mdi-sync"></i>
                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="updateUSJASuspendedMembershipPeople()"> USHJA
                    <i class="mdi mdi-sync"></i>
                  </button>
                </div>
                <div class="col-12">
                  <span>USEF Suspended/Membership Report Horses</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getMembershipHorsesTabla()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="updateSuspendedMembershipHorses()"> USEF
                    <i class="mdi mdi-sync"></i>
                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="updateUSJASuspendedMembershipHorses()"> USHJA
                    <i class="mdi mdi-sync"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!--div class="col-6">
          <div class="card m-0">
            <div class="card-header px-2 py-1 bg-dark text-white">
              <div class="tools"></div><img src="assets/images/icons/usef.svg" class="white-svg" alt="Update USEF Data"
                style="width: 20px; ; margin-right:5px"><span class="title">USEF Suspensions </span>
            </div>
            <div class="card-body p-2">
              <div class="row">
                <div class="col-12 mb-1">
                  <span>Suspended People</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getSuspendedPeopleTabla()">
                    <i class="mdi mdi-eye mr-1"></i>
                    <span>Preview</span>
                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getSuspendedRidersExcel()">
                    <i class="mdi mdi-file-excel-box mr-1" style="color: #207245;"></i>
                    <span>Download</span>
                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Suspended Horses</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;" (click)="getSuspendedHorsesTabla()">
                    <i class="mdi mdi-eye mr-1"></i>
                    <span>Preview</span>
                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getSuspendedHorsesExcel()">
                    <i class="mdi mdi-file-excel-box mr-1" style="color: #207245;"></i>
                    <span>Download</span>
                  </button>
                </div>


                <div class="col-12 mb-1">
                  <span>Update People</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;" (click)="updateUsefPeople()">
                    <img src="assets/images/icons/usef.svg" alt="Update USEF Data" style="max-width: .875rem; max-height: .875rem;" class="mr-1">
                    <span>Synchronize</span>
                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Update Horses</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;" (click)="updateUsefHorses()">
                    <img src="assets/images/icons/usef.svg" alt="Update USEF Data" style="max-width: .875rem; max-height: .875rem;" class="mr-1">
                    <span>Synchronize</span>
                  </button>
                </div>


              </div>
            </div>
          </div>
        </div-->
        <div class="col-4">
          <div class="card m-0">
            <div class="card-header px-2 py-1 bg-dark text-white">
              <div class="tools"></div><i style="margin-right: 5px" class="mdi mdi-information-outline"></i><span
                class="title">Product Information</span>
            </div>
            <div class="card-body p-2">
              <div class="row">
                <div class="col-12 mb-1">
                  <span>Merch inventory:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getMerchInventory()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getMerchInventoryExcel()">
                    <i class="mdi mdi-file-excel-box" style="color: #207245;"></i>

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card m-0">
            <div class="card-header px-2 py-1 bg-dark text-white">
              <div class="tools"></div><i style="margin-right: 5px" class="mdi mdi-information-outline"></i><span
                class="title">Show Information</span>
            </div>
            <div class="card-body p-2">
              <div class="row">
                <div class="col-12 mb-1">
                  <div class="report-actions">
                    <span>Entries per class:</span>
                    <div class="btn-group">
                      <button type="button" class="btn btn-light" (click)="mostrarInscripciones(semanas[0].numero)">
                        <i class="mdi mdi-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-1">
                  <div class="report-actions">
                    <span>Horses in show:</span>
                    <div class="btn-group">
                      <button type="button" class="btn btn-light" (click)="getCaballosTabla()">
                        <i class="mdi mdi-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-1">
                  <span>Horses without classes:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getCaballosSinPruebasTabla()">
                    <i class="mdi mdi-eye"></i>
                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Riders in show:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getJinetesTabla()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>

                <div class="col-12 mb-1">
                  <span>People Directory</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getDirectory()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="downloadDirectory()">
                    <i class="mdi mdi-file-excel-box" style="color: #207245;"></i>

                  </button>

                </div>

                <div class="col-12 mb-1">
                  <div class="report-actions">
                    <span>Master List:</span>
                    <div class="btn-group">
                      <button type="button" class="btn btn-light" (click)="getMasterListTabla()">
                        <i class="mdi mdi-eye"></i>
                      </button>
                      <button type="button" class="btn btn-light" (click)="getMasterListExcel()">
                        <i class="mdi mdi-file-excel-box"></i>
                      </button>

                      <button *ngFor="let evento of eventos" type="button" class="btn btn-light"
                        (click)="getMasterListTabla(evento)">

                        ({{ evento }})
                        <i class="mdi mdi-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <span>FEI Vet Check Printable:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getInspeccionVeterinaria(1)">
                    <i class="mdi mdi-eye"></i>
                    <span> </span>
                  </button>
                </div>
                <!-- <div class="col-12 mt-1">
                  <span>Robin's Report:</span>
                  <button *ngFor="let semana of semanas" type="button" class="btn btn-light ml-1"
                    style="padding: .25rem .5rem;" (click)="getRobinsReport()">
                    <i class="mdi mdi-eye mr-1"></i>
                    <span> {{ semana.semana }}</span>
                  </button>
                </div> -->
                <div class="col-12 mt-1">
                  <span>Emails:</span>
                  <button type="button" class="btn btn-light ml-1 mb-1" style="padding: .25rem .5rem;"
                    (click)="getEmailJinetes()">
                    <i class="mdi mdi-file-excel-box" style="color: #207245;"></i>

                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Trainer Balances:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getCuentaEntrenadores()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>

                <div class="col-12 mb-1">
                  <span>Entries by division charged by:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getEntriesDivisionType()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>

                <div class="col-12 mb-1">
                  <span>Payees with prizes, missing TAX ID:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getMissingTaxIDs()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
                <!-- <div class="col-12 mb-1">
                  <span>Credit Card Payments (Bank Transactions):</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getCreditCardPayments()">
                    <i class="mdi mdi-eye mr-1"></i>
                     
                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Credit Card Payments (Office Transactions):</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getCreditCardPaymentsTransactions()">
                    <i class="mdi mdi-eye mr-1"></i>
                     
                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Credit Card Payments per day:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getCreditCardPaymentsPerDay()">
                    <i class="mdi mdi-eye mr-1"></i>
                     
                  </button>
                </div> -->
                <div class="col-12 mb-1">
                  <span>Trainer List with Phone Numbers</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getTrainers()">

                    <i class="mdi mdi-file-download" style="color: #207245;"></i>

                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Stalls report:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getStallsReport()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Stalls by Trainer Summary:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getStallsSummary()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Balance per division:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getBalancePerDivisionReport('por_division')">
                    <i class="mdi mdi-file-download" style="color: #207245;"></i>
                  </button>
                  <span>Hunter classics/derbies:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getBalancePerDivisionReport('hunter_classic_derbies')">
                    <i class="mdi mdi-file-download" style="color: #207245;"></i>
                  </button>
                  <span>Jumper:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getBalancePerDivisionReport('jumper')">
                    <i class="mdi mdi-file-download" style="color: #207245;"></i>
                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Feed Orders Items Report</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getFeedOrdersSummary()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="downloadFeedOrdersSummary()">
                    <i class="mdi mdi-file-excel-box" style="color: #207245;"></i>

                  </button>
                </div>
                <div class="col-12">
                  <span>Classes with no results:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getClassesWithNoResults()">
                    <i class="mdi mdi-eye"></i>
                  </button>
                </div>
                <div class="col-12 mb-1"
                  *ngIf="['491','493','494', '497', '498', '499','503','504','506','508','509','510'].includes(idConcurso)">
                  <span>BackNumbers Report</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getSeriesNumbers([491, 493, 494])">
                    <i class="mdi mdi-eye"></i> Spring Series

                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getSeriesNumbers([497, 498, 499, 503, 504, 506 ])">
                    <i class="mdi mdi-eye"></i> GLEF Series

                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getSeriesNumbers([508, 509, 510 ])">
                    <i class="mdi mdi-eye"></i> Fall Series

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card mb-0">
            <div class="card-header px-2 py-1 bg-dark text-white">
              <div class="tools"></div><i style="margin-right: 5px" class="mdi mdi-trophy"></i><span
                class="title">Prizes</span>
            </div>
            <div class="card-body p-2">
              <p class="mb-0"><b>Reports containing prize money information.</b></p>
              <div class="row">
                <div class="col-12 mb-1">
                  <span>Assigned Prizes:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getPremiosTabla()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getPremiosExcel()">
                    <i class="mdi mdi-file-excel-box" style="color: #207245;"></i>

                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Credit Balance by Recipient:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getBalanceByPrizeMoney()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Prize Money - By Entry:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getPrizeMoneyByEntry()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Prize Money - By Payee:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getPrizeMoneyByPayee()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Payees with specified mailing address (and prizes):</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getPayeesWithMailingAddresses()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="downloadPayeesWithMailingAddresses()">
                    <i class="mdi mdi-file-excel-box" style="color: #207245;"></i>

                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Prizes by class:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getPrizesByClass()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Prizes by hunters class:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getPrizesByClass('hunters')">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
                <div class="col-12 mb-1">
                  <span>Prizes by jumpers class:</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getPrizesByClass('jumpers')">
                    <i class="mdi mdi-eye"></i>

                  </button>
                </div>
                <div class="col-12">
                  <span>Unapplied Prize Money</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getUnappliedPrizeMoney()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getUnappliedPrizeMoney(true)">
                    <i class="mdi mdi-file-excel-box" style="color: #207245;"></i>
                  </button>
                </div>
                <div class="col-12">
                  <span>Applied Prize Money</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getAppliedPrizeMoney()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getAppliedPrizeMoney(true)">
                    <i class="mdi mdi-file-excel-box" style="color: #207245;"></i>
                  </button>
                </div>
                <div class="col-12">
                  <span>Outgoing Checks Report</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;" (click)="getChecks()">
                    <i class="mdi mdi-eye"></i>

                  </button>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getChecksExcel()">
                    <i class="mdi mdi-file-excel-box" style="color: #207245;"></i>

                  </button>
                </div>
                <div class="col-12">
                  <span>Quickbooks</span>
                  <button type="button" class="btn btn-light ml-1" style="padding: .25rem .5rem;"
                    (click)="getQuickbooks()">
                    <i class="mdi mdi-file-excel-box" style="color: #207245;"></i>

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card m-0">
            <div class="card-header px-2 py-1 bg-dark text-white">
              <div class="tools"></div>
              <i class="mdi mdi-cash-multiple" style="margin-right: 5px"></i>
              <span class="title">Financial Reports</span>
            </div>
            <div class="card-body p-2">
              <div class="row">
                <div class="col-12 mb-1">
                  <div class="report-actions">
                    <span>Credit Card Payments:</span>
                    <div class="btn-group">
                      <button type="button" class="btn btn-light" (click)="getCreditCardPaymentsTransactions()">
                        <i class="mdi mdi-eye"></i>
                      </button>

                    </div>
                  </div>
                </div>

                <div class="col-12 mb-1">
                  <div class="report-actions">
                    <span>Daily CC Payments:</span>
                    <div class="btn-group">
                      <button type="button" class="btn btn-light" (click)="getCreditCardPaymentsPerDay()">
                        <i class="mdi mdi-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- Fin de Reportes -->

      <ng-container *ngIf="StandarUsefResultsReport && filasReporteTabla.length > 0">
        <ng-container *ngFor="let prueba of filasReporteTabla; let index = index">
          <div class="row justify-content-center"
            style="padding:20px;padding-top:0px !important; page-break-inside: avoid;">
            <div class="col-12" style="background-color:white; padding: 30px; border: solid 1px #d0d0d0">
              <div class="row">
                <div class="col-sm-2" id="logoClub" style="float: left; width:16%">
                  <img style="max-width:75%; max-height: 100px; float: left" [src]="logoClub" />
                </div>
                <div class="col-sm-8" style="float: left; width:66.6%">
                  <h4 class="text-center"><b>{{ nombreConcurso }}</b></h4>
                  <h4 class="text-center">
                    <b> Prize money report - {{ prueba.numero +' - '+ prueba.nombre }}</b>
                  </h4>
                  <p class="text-center" *ngIf="prueba.id_prueba == '2'">
                    PM for Championship Series is excluded from the total in the bottom of the sheet
                  </p>
                </div>
                <div class="col-sm-2" id="logoPatrocinadores" style="float: left; width:16%" *ngIf="index == 0">
                  <button mat-mini-fab (click)="imprimir('')" class="hidden-print"
                    style="float: right; margin-top: -1em; background-color: #8dcadf;">
                    <mat-icon>print</mat-icon>
                  </button>
                  <img style="max-width:75%; max-height: 100px;  float: right" [src]="header" />
                </div>
              </div>
              <table class="table table-hover table-condensed" style="margin-top:25px;">
                <thead>
                  <th *ngFor="let columna of columnas">{{ columna }}</th>
                </thead>
                <tbody>
                  <tr *ngFor="let fila of prueba.resultados">
                    <td *ngFor="let columna of columnas"
                      [innerHTML]="isNumber(fila[columna]) ? (fila[columna]|currency) : fila[columna]"></td>
                  </tr>
                </tbody>
              </table>
              <p class="d-none d-print-block pl-2 mt-3">
                <span>Powered by </span> <img src="assets/images/logo.png" style="width: 15px;">
                <span>EquestrianDigital</span>
              </p>
              <p class="info-panel" style="float:right">
                Download the App for Live Results, Live Video and Add/Scratches
              </p>
            </div>
          </div>
        </ng-container>
        <div class="row justify-content-center"
          style="padding:20px;padding-top:0px !important; page-break-inside: avoid;">
          <div class="col-12 text-right" style="background-color:white; padding: 30px; border: solid 1px #d0d0d0">
            <p><strong>Total Awarded: {{ totalAwarded | currency }}</strong></p>
            <p class="mb-0"><strong>Total Projected: {{ totalProjected | currency }}</strong></p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="PrintResultsReport && filasReporteTabla.length > 0">
        <ng-container *ngFor="let prueba of filasReporteTabla; let index = index">
          <div class="row justify-content-center"
            style="padding:20px;padding-top:0px !important; page-break-inside: avoid;">
            <div class="col-12" style="background-color:white; padding: 30px; border: solid 1px #d0d0d0">
              <div class="row">
                <div class="col-sm-2" id="logoClub" style="float: left; width:16%">
                  <img style="max-width:75%; max-height: 100px; float: left" [src]="logoClub" />
                </div>
                <div class="col-sm-8" style="float: left; width:66.6%">
                  <h4 style="text-align: center;"><b>{{ nombreConcurso }}</b></h4>
                  <h4 style="text-align: center;">
                    <b> Printable Results Report - {{ prueba.numero +' - '+ prueba.nombre }}</b>
                  </h4>
                </div>
                <div class="col-sm-2" id="logoPatrocinadores" style="float: left; width:16%" *ngIf="index == 0">
                  <button mat-mini-fab (click)="imprimir('')" class="hidden-print"
                    style="float: right; margin-top: -1em; background-color: #8dcadf;">
                    <mat-icon>print</mat-icon>
                  </button>
                  <img style="max-width:75%; max-height: 100px;  float: right" [src]="header" />
                </div>
              </div>
              <table class="table table-hover table-condensed" style="margin-top:25px;">
                <thead>
                  <th *ngFor="let columna of columnas">{{ columna }}</th>
                </thead>
                <tbody>
                  <tr *ngFor="let fila of prueba.resultados">
                    <td *ngFor="let columna of columnas" [innerHTML]="isNumber(fila[columna]) ? (fila[columna]|currency) :
                    fila[columna]"></td>
                  </tr>
                </tbody>
              </table>
              <p class="d-none d-print-block pl-2 mt-3">
                <span>Powered by </span> <img src="assets/images/logo.png" style="width: 15px;">
                <span>EquestrianDigital</span>
              </p>
              <p class="info-panel" style="float:right">Download the App for Class Results, Live Video and Add/Scratches
              </p>
            </div>
          </div>
        </ng-container>
        <div class="row justify-content-center"
          style="padding:20px;padding-top:0px !important; page-break-inside: avoid;">
          <div class="col-12 text-right" style="background-color:white; padding: 30px; border: solid 1px #d0d0d0">
            <p><strong>Total Awarded: {{ totalAwarded | currency }}</strong></p>
            <p class="mb-0"><strong>Total Projected: {{ totalProjected | currency }}</strong></p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="entriesByDivision && filasReporteTabla.length > 0">
        <div class="row justify-content-center"
          style="padding:20px;padding-top:0px !important; page-break-inside: avoid;">
          <div class="col-12" style="background-color:white; padding: 30px; border: solid 1px #d0d0d0">
            <div class="row">
              <div class="col-2" id="logoClub" style="float: left; width:16%">
                <img style="max-width:75%; max-height: 100px; float: left" [src]="logoClub" />
              </div>
              <div class="col-8" style="float: left; width:66.6%">
                <h4 style="text-align: center;"><b>{{ nombreConcurso }}</b></h4>
                <h4 style="text-align: center;">
                  {{ tituloReporteEN }}
                </h4>
              </div>
              <div class="col-2" id="logoPatrocinadores" style="float: left; width:16%">
                <!--button mat-mini-fab (click)="imprimir('')" class="hidden-print"
                    style="float: right; margin-top: -1em; background-color: #8dcadf;">
                    <mat-icon>print</mat-icon>
                  </button-->
                <img style="max-width:75%; max-height: 100px;  float: right" [src]="header" />
              </div>
            </div>
            <div class="row">
              <div class="col" *ngFor="let chargedBy of filasReporteTabla">
                <h4>{{ chargedBy.charged_by }}</h4>
                <table>
                  <thead>
                    <tr>
                      <th>Division</th>
                      <th>Entries</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let division of chargedBy.divisions">
                      <td>{{ division.name }}</td>
                      <td>{{ division.entries }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--table class="table table-hover table-condensed" style="margin-top:25px;">
                <thead>
                  <th *ngFor="let columna of columnas">{{ columna }}</th>
                </thead>
                <tbody>
                  <tr *ngFor="let fila of prueba.resultados">
                    <td *ngFor="let columna of columnas" [innerHTML]="isNumber(fila[columna]) ? (fila[columna]|currency) :
                    fila[columna]"></td>
                  </tr>
                </tbody>
              </table-->
            <p class="d-none d-print-block pl-2 mt-3">
              <span>Powered by </span> <img src="assets/images/logo.png" style="width: 15px;">
              <span>EquestrianDigital</span>
            </p>
            <p class="info-panel" style="float:right">Download the App for Class Results, Live Video and Add/Scratches
            </p>
          </div>
        </div>
      </ng-container>

      <div class="row justify-content-center" style="padding:20px;padding-top:0px !important"
        *ngIf="trainerStalls.length > 0 && stallsReport">
        <div class="col-12" style="background-color:white; padding: 30px; border: solid 1px #d0d0d0">
          <div class="row">
            <div class="col-sm-2" id="logoClub" style="float: left; width:16%">
              <img style="max-width:75%; max-height: 100px; float: left" [src]="logoClub" />
            </div>
            <div class="col-sm-8" style="float: left; width:66.6%">
              <h4 style="text-align: center;"><b>{{ nombreConcurso }}</b></h4>
              <h4 style="text-align: center;">
                <b>Stalls report</b>
              </h4>
            </div>
            <div class="col-sm-2" id="logoPatrocinadores" style="float: left; width:16%">
              <button mat-mini-fab (click)="imprimir('')" class="hidden-print"
                style="float: right; margin-top: -1em; background-color: #8dcadf;">
                <mat-icon>print</mat-icon>
              </button>
              <img style="max-width:75%; max-height: 100px;  float: right" [src]="header" />
            </div>
          </div>
          <div class="row">
            <ng-container *ngFor="let entrenador of trainerStalls">
              <h5 class="mb-0">{{ entrenador.nombre }}</h5>
              <table class="table table-hover table-condensed table-sm" style="margin-top:15px;">
                <thead>
                  <tr>
                    <th>Entry</th>
                    <th>Horse</th>
                    <th *ngFor="let concepto of entrenador?.conceptos">{{ concepto?.name }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let entry of entrenador.entries">
                    <td>{{ entry['Entry'] }}</td>
                    <td>{{ entry['Horse'] }}</td>
                    <td *ngFor="let concepto of entrenador?.conceptos">{{ entry[ concepto?.name ] }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <td colspan="2" class="text-center"><b>Total</b></td>
                  <td *ngFor="let concepto of entrenador?.conceptos"><b>{{ concepto?.total }}</b></td>
                </tfoot>
              </table>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row justify-content-center" style="padding:20px;padding-top:0px !important"
        *ngIf="filasReporteTabla.length > 0 && reporteGenerico">
        <div class="col-12" style="background-color:white; padding: 30px; border: solid 1px #d0d0d0">
          <div class="row">
            <div class="col-sm-2" id="logoClub" style="float: left; width:16%">
              <img style="max-width:75%; max-height: 100px; float: left" [src]="logoClub" />
            </div>
            <div class="col-sm-8" style="float: left; width:66.6%">
              <h4 style="text-align: center;"><b>{{ nombreConcurso }}</b></h4>
              <h4 style="text-align: center;">
                <b>{{ tituloReporteEN }}</b>
              </h4>
            </div>
            <div class="col-sm-2" id="logoPatrocinadores" style="float: left; width:16%">
              <button mat-mini-fab (click)="imprimir('')" class="hidden-print"
                style="float: right; margin-top: -1em; background-color: #8dcadf;">
                <mat-icon>print</mat-icon>
              </button>
              <img style="max-width:75%; max-height: 100px;  float: right" [src]="header" />

            </div>
          </div>
          <table class="table table-hover table-condensed" style="margin-top:25px;">
            <thead>
              <th *ngFor="let columna of columnas">{{ columna }}</th>
            </thead>
            <tbody>
              <tr *ngFor="let fila of filasReporteTabla">
                <td *ngFor="let columna of columnas" [innerHTML]="fila[columna]"></td>
              </tr>
            </tbody>
          </table>
          <p class="d-none d-print-block pl-2 mt-3">
            <span>Powered by </span> <img src="assets/images/logo.png" style="width: 15px;">
            <span>EquestrianDigital</span>
          </p>
          <p class="info-panel" style="float:right">Download the App for Class Results, Live Video and Add/Scratches</p>
        </div>
      </div>
      <div class="row justify-content-center" *ngIf="reporteInscripciones">
        <div class="col-md-10" style="background-color:white; padding: 0px;">
          <div class="row">
            <div class="col-sm-2" id="logoClub" style="float: left; width:16%">
              <img style="max-width:75%; max-height: 100px; float: left" [src]="logoClub" />
            </div>
            <div class="col-sm-8" style="float: left; width:66.6%">
              <h4 style="text-align: center;"><b>{{ nombreConcurso }}</b></h4>
              <h4 style="text-align: center;"><b>{{ tituloReporteEN }}</b></h4>
            </div>
            <div class="col-sm-2" id="logoPatrocinadores" style="float: left; width:16%">
              <img style="max-width:75%; max-height: 100px;  float: right" [src]="header" />
            </div>
          </div>
          <div>
            <div class="row hidden-print">
              <div class="col-md-3">
                <mat-form-field>
                  <label for="tiempoEntrePruebas"><span>Time between classes</span></label>
                  <input id="tiempoEntrePruebas" type="number" min="1" matInput placeholder="Time between classes"
                    [(ngModel)]="tiempoEntrePruebas">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="panel panel-info" style="margin-top:50px;">
            <div class="card-header" style="background-color: #0d0d41;">
              <h2><span>Total entries in show</span>: {{ totalInscritos }}</h2>
            </div>
            <mat-accordion>
              <mat-expansion-panel *ngFor="let prize of testPrizes; let index = index"
                (opened)="prize.panelOpenState = true" (closed)="prize.panelOpenState = false"
                [ngClass]="{'d-print-none': !prize.panelOpenState}">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{prize.fecha_prueba}}
                  </mat-panel-title>
                  <mat-panel-description>
                    <span>Entries</span>: <strong>{{prize.inscritos}}</strong>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col" *ngFor="let pista of prize.pistas;let index = index">
                    <div class="card">
                      <h6 class="card-header" style="padding: 0.75rem .5rem;">{{ pista.numero_pista }}</h6>
                      <div class="card-body p-0">
                        <table class="table table-hover">
                          <thead style="font-size: .75rem;">
                            <th style="padding: .5rem !important;">Class</th>
                            <th style="padding: .5rem !important;">Entries</th>
                            <th style="padding: .5rem !important;">Len.</th>
                            <th style="padding: .5rem !important;">Schedule</th>
                          </thead>
                          <tbody>
                            <tr *ngFor="let p of pista.pistas; let ind = index" style="font-size: .75rem;">
                              <td style="padding: .5rem !important;">
                                <strong>
                                  {{ p.prueba }}
                                </strong>
                                <strong *ngIf="p.categoria != null">
                                  {{ p.categoria }}
                                </strong>
                              </td>
                              <td style="padding: .5rem !important;">{{ p.cantidad }}</td>
                              <td style="padding: .5rem !important;">{{ cortarDecimales(p.cantidad *
                                tiempoPista(p.idModalidad)) }} Min.</td>
                              <td style="padding: .5rem !important;" *ngIf='ind == 0'>{{
                                horarioInicio(pista.horario[0], (p.acumulado * tiempoPista(p.idModalidad) * 60 * ind) )
                                }} - {{ horarioInicio(pista.horario[0], ( ((p.acumulado) * tiempoPista(p.idModalidad) *
                                60))) }} hrs</td>
                              <td style="padding: .5rem !important;" *ngIf='ind != 0'>{{
                                horarioInicio(pista.horario[0], ( ((p.acumulado - p.cantidad) *
                                tiempoPista(p.idModalidad) * 60) + (tiempoEntrePruebas * 60 * ind) )) }} - {{
                                horarioInicio(pista.horario[0], ( ((p.acumulado) * tiempoPista(p.idModalidad) * 60) +
                                (tiempoEntrePruebas * 60 * (ind)) )) }} hrs</td>
                            </tr>
                          </tbody>
                          <tfoot style="font-size: .75rem;">
                            <tr>
                              <td colspan="4" style="padding: .5rem !important;">
                                <span>Entries in ring</span>: {{ pista.inscritos_por_prueba }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4" style="padding: .5rem !important;">
                                <span>Aproximate duration per ring</span>: {{ tiempo( tiempoTotalPista(pista) ) }}
                                <span>hours</span>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <br />
        </div>
      </div>
      <div class="row justify-content-center" style="padding-top: 0 !important; padding: 20px;"
        *ngIf="filasInspeccionVeterinaria.length > 0 && reporteVeterinaria">
        <div class="col-12 padding-print report-border" style="background-color:white; padding: 30px;">
          <div class="col-md-12 hidden-print" style="margin-bottom: 20px;">
            <!-- <button mat-mini-fab (click)="imprimir('')"
              style="float: right; margin-top: -1em; background-color: #8dcadf;">
              <mat-icon>print</mat-icon>
            </button> -->
            <!-- <button mat-raised-button style="float: right; margin-top: -1em; margin-right: 15px;"
              (click)="imprimir('7')">
              <mat-icon>print</mat-icon> 7yr
            </button>
            <button mat-raised-button style="float: right; margin-top: -1em; margin-right: 15px;"
              (click)="imprimir('6')">
              <mat-icon>print</mat-icon> 6yr
            </button>
            <button mat-raised-button style="float: right; margin-top: -1em; margin-right: 15px;"
              (click)="imprimir('5')">
              <mat-icon>print</mat-icon> 5yr
            </button> -->
            <button mat-raised-button style="margin-top: -1em; margin-right: 15px;" (click)="imprimir()">
              <mat-icon>print</mat-icon> Print
            </button>
          </div>
          <div style="width: 100%; display: flex; align-items: center;">
            <div style="flex-grow: 1;">
              <img src="assets/images/icons/fei.svg" style="width: 130px;">
            </div>
            <div style="flex-grow: 1; text-align: center; color: #000;">
              <h2><b>{{ nombreConcurso }}</b></h2>
              <h3 class="visible-print-block" *ngIf="categoriaVetcheck"><b>{{ categoriaVetcheck }}</b></h3>
            </div>
            <div style="flex-grow: 1; text-align: right">
              <img src="assets/images/logo-ed/logo-ed.svg" style="width: 70px;">
            </div>
          </div>
          <div
            style="width: 100%; background-color: #dcdcdc; text-align: center;color: black; border: 1px solid #000;padding: 5px 0;-webkit-print-color-adjust: exact; margin-top: 15px; margin-bottom: 20px;">
            <h3 style="margin:0"><b>Horse Inspection</b></h3>
            <!--h3 style="margin:0"><b>Jumping</b></h3-->
          </div>
          <table class="table table-bordered table-condensed" style="color: #000;">
            <thead style="background-color: #dcdcdc;">
              <tr>
                <th></th>
                <th style="text-align: center;vertical-align: middle; padding: .5rem"><b>CNR</b></th>
                <th style="vertical-align: middle; padding: .5rem"><b>Horse</b></th>
                <th style="vertical-align: middle; padding: .5rem"><b>Rider</b></th>
                <!--th style="text-align: center;vertical-align: middle;"><b>Country</b></th-->
                <th style="text-align: center;vertical-align: middle; padding: .5rem"><b>Accepted</b></th>
                <th style="text-align: center; padding: .5rem"><b>Holding</b></th>
                <th style="text-align: center; vertical-align: middle; padding: .5rem;"><b>Re Inspect</b></th>
                <!--th style="text-align: center;width: 4%;"><b>not accepted</b></th-->
                <th style="text-align: center;vertical-align: middle;padding: .5rem"><b>Remarks</b></th>
              </tr>
            </thead>
            <tbody>
              <tr style="display: revert;"
                *ngFor="let caballo of filasInspeccionVeterinaria | filterJovenes : filtro; let i = index;"
                [ngClass]="{'hidden-print': caballo.hidden}">
                <td>{{i + 1}}</td>
                <td style="text-align: center;-webkit-print-color-adjust: exact !important;"><b>{{
                    caballo.cucarda }}</b></td>
                <td style="padding: 0 5px;"><b>{{ caballo.caballo }}</b> {{ caballo.fei }}<br><span
                    style="float: left; padding-right: 5px;">{{
                    caballo.infoCaballo }}</span><br>{{caballo.microchip}}</td>
                <td><b>{{ caballo.pais }}</b> {{ caballo.jinete }} <br>
                  {{caballo.riderFei}}
                </td>
                <!--td></td-->
                <td style="text-align: center;color: #dcdcdc;">Accepted</td>
                <td style="text-align: center;color: #dcdcdc;">Holding</td>
                <td style="text-align: center;color: #dcdcdc;">ReInspect</td>
                <!--td style="text-align: center;color: #dcdcdc;">not accepted</td-->
                <td></td>
              </tr>
              <tr *ngFor="let i of getNumber(20)" style="display: revert;">
                <td></td>
                <td style="background-color: #dcdcdc;text-align: center;"><b></b></td>
                <td style="padding: 0 5px;"><b></b><br></td>
                <td></td>
                <!--td></td-->
                <td style="text-align: center;color: #dcdcdc;">Accepted</td>
                <td style="text-align: center;color: #dcdcdc;">Holding Box</td>
                <td style="text-align: center;color: #dcdcdc;">ReInspect</td>
                <!--td style="text-align: center;color: #dcdcdc;">not accepted</td-->
                <td></td>
              </tr>
            </tbody>
          </table>
          <div class="visible-print-block" style="margin-top: 15px;"><b><span>Total horses</span>: {{ contarCaballos()
              }}</b></div>
        </div>
      </div>
      <div id="backLabels" *ngIf="backLabels">
        <div class="label-grid" style="page-break-inside:auto">
          <div *ngFor="let caballo of filasReporteTabla; let i = index" class="text-center label" [ngClass]="{
                 'last-row': (i + 1) % 30 === 0,
                 'first-row-of-page': i % 30 === 0 && i !== 0
               }" style="page-break-inside:avoid">
            <div style="font-size:1em; margin-top: 5px">{{ nombreConcurso }}</div>
            <div style="font-size:1.2em; color:black; font-weight: bold;">{{caballo['Back Number']}} : {{caballo['Horse
              Name']}}</div>
            <div style="font-size:1.1em">{{caballo.Rider}}</div>
            <div style="font-size:1.1em; margin-bottom: 10px;">{{caballo.Trainer}}</div>
          </div>
        </div>
      </div>
      <div id="classLabels" class="container" *ngIf="classLabels">
        <div class="row" style="page-break-inside:auto">
          <div *ngFor="let prueba of filasReporteTabla; let i = index" class="col-4 text-center classLabel d-flex"
            style="height: 4.8rem; overflow: hidden;"
            [ngClass]="{'last-row': (i + 1) %30 == 0, 'first-row-of-page': (i + 1) %30 == 1}"
            style="page-break-inside:avoid">
            <div class="m-auto">
              <p class="mb-0" style="font-size:1em; line-height: 1.3rem; height: 1.3rem; overflow: hidden;">{{
                nombreConcurso }}</p>
              <p class="mb-0" style="font-size:1.3em; line-height: 1.5rem; height: 1.5rem; overflow: hidden;">
                {{prueba['numero']}}</p>
              <p class="mb-0"
                style="font-size:1.1em; line-height: 1.1rem; height: 1.1rem; color:black; height: 1.1rem; overflow: hidden;">
                {{prueba['nombre']}}</p>
              <p class="mb-0" style="font-size:.9em; line-height: .9rem; color:black; height: .9rem; overflow: hidden;">
                {{prueba['description']}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center m-0 m-print-0 p-print-0 p-2 container" style="max-width: 100%;"
        *ngIf="balanceByPrizeMoney && filasBalanceByPrizeMoney.length > 0">
        <div class="col-12 padding-print report-border" style="background-color:white;">
          <div class="col-12 d-print-none mb-3 text-right pt-3">
            <button mat-mini-fab onclick="print();" style="background-color: #8dcadf;">
              <mat-icon>print</mat-icon>
            </button>
          </div>
          <div style="display: flex; justify-content: space-between; width:100%;">
            <div style="flex: 1;" class="text-left">
              <img [src]="header" style="max-height: 90px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
            <div>
              <h2>{{ nombreConcurso }}</h2>
              <h4 style="text-align:center"><span>Credit Balance Report by Prize Money Recipient</span></h4>
            </div>
            <div style="flex: 1;" class="text-right">
              <img [src]="header2" style="max-height: 90px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
          </div>
          <table class="table table-borderless mt-3 balanceByPrizeMoney">
            <thead>
              <tr>
                <td><b>Entry</b></td>
                <td><b>Horse</b></td>
                <td><b>Owner</b></td>
                <td><b>Total</b></td>
                <td><b>Payments</b></td>
                <td><b>Prizes</b></td>
                <td><b>Balance</b></td>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let responsable of filasBalanceByPrizeMoney">
                <tr class="border-top">
                  <td colspan="2">{{ responsable.payee }}</td>
                  <td colspan="5">{{ responsable.address }}</td>
                </tr>
                <tr *ngFor="let caballo of responsable.premios">
                  <td>{{ caballo.entry }}</td>
                  <td>{{ caballo.horse }}</td>
                  <td>{{ caballo.owner }}</td>
                  <td>{{ caballo.charges | currency }}</td>
                  <td>{{ caballo.payments | currency }}</td>
                  <td>{{ caballo.prize | currency }}</td>
                  <td>{{ caballo.balance | currency }}</td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td><b>{{ responsable.totalCargos | currency }}</b></td>
                  <td><b>{{ responsable.totalPagos | currency }}</b></td>
                  <td><b>{{ responsable.totalPremios | currency }}</b></td>
                  <td><b>{{ responsable.totalBalance | currency }}</b></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div class="row d-none d-print-flex mt-3 mb-1">
            <div class="col-12 text-center">
              <img [src]="footer" style="max-height: 120px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
          </div>
          <div class="poweredBy d-none d-print-block">Powered by Equestrian Digital <img src="assets/images/logo.png">
          </div>
        </div>
      </div>
      <div class="row justify-content-center m-0 m-print-0 p-print-0 p-2 container" style="max-width: 100%;"
        *ngIf="prizeMoneyByEntry && filasPrizeMoneyByEntry.length > 0">
        <div class="col-12 padding-print report-border" style="background-color:white;">
          <div class="col-12 d-print-none mb-3 text-right pt-3">
            <button mat-mini-fab onclick="print();" style="background-color: #8dcadf;">
              <mat-icon>print</mat-icon>
            </button>
          </div>
          <div style="display: flex; justify-content: space-between; width:100%;">
            <div style="flex: 1;" class="text-left">
              <img [src]="header" style="max-height: 90px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
            <div>
              <h2>{{ nombreConcurso }}</h2>
              <h4 style="text-align:center"><span>Prize Money - By Entry</span></h4>
            </div>
            <div style="flex: 1;" class="text-right">
              <img [src]="header2" style="max-height: 90px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
          </div>
          <table class="table table-borderless mb-0 mt-3 balanceByPrizeMoney">
            <tbody>
              <ng-container *ngFor="let caballo of filasPrizeMoneyByEntry">
                <tr class="borde">
                  <td colspan="1">{{ caballo.entry }}</td>
                  <td colspan="3">{{ caballo.horse }}</td>
                  <td colspan="3">{{ caballo.payee }}</td>
                </tr>
                <tr class="borde">
                  <td colspan="4">{{ caballo.payeeAddress }}</td>
                  <td colspan="3">{{ caballo.payeeSSN }}</td>
                </tr>
                <tr *ngFor="let premio of caballo.premios" class="border-top">
                  <td>{{ premio.posicion }}</td>
                  <td colspan="2">{{ premio.numPrueba }} - {{ premio.prueba }}</td>
                  <td>{{ premio.jinete }}</td>
                  <td>{{ premio.premioSinDecuccion | currency }}</td>
                  <td>{{ premio.deduccion }}%</td>
                  <td>{{ premio.prize | currency }}</td>
                </tr>
                <tr class="border-top">
                  <td colspan="5"></td>
                  <td class="text-right pb-2"><b>Total</b></td>
                  <td class="pb-2"><b>{{ caballo.totalPremios | currency }}</b></td>
                </tr>
                <!--tr>
                  <td colspan="3"></td>
                  <td><b>{{ responsable.totalCargos | currency }}</b></td>
                  <td><b>{{ responsable.totalPagos | currency }}</b></td>
                  <td><b>{{ responsable.totalPremios | currency }}</b></td>
                  <td><b>{{ responsable.totalBalance | currency }}</b></td>
                </tr-->
              </ng-container>
            </tbody>
          </table>
          <div class="row d-none d-print-flex mt-3 mb-1">
            <div class="col-12 text-center">
              <img [src]="footer" style="max-height: 120px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
          </div>
          <div class="poweredBy d-none d-print-block">Powered by Equestrian Digital <img src="assets/images/logo.png">
          </div>
        </div>
      </div>
      <div class="row justify-content-center m-0 m-print-0 p-print-0 p-2 container" style="max-width: 100%;"
        *ngIf="prizeMoneyByPayee && filasPrizeMoneyByPayee.length > 0">
        <div class="col-12 padding-print report-border" style="background-color:white;">
          <div class="col-12 d-print-none mb-3 text-right pt-3">
            <button mat-mini-fab onclick="print();" style="background-color: #8dcadf;">
              <mat-icon>print</mat-icon>
            </button>
          </div>
          <div style="display: flex; justify-content: space-between; width:100%;">
            <div style="flex: 1;" class="text-left">
              <img [src]="header" style="max-height: 90px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
            <div>
              <h2>{{ nombreConcurso }}</h2>
              <h4 style="text-align:center"><span>Prize Money - By Payee</span></h4>
            </div>
            <div style="flex: 1;" class="text-right">
              <img [src]="header2" style="max-height: 90px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
          </div>
          <table class="table table-borderless mb-0 mt-3 balanceByPrizeMoney">
            <tbody>
              <ng-container *ngFor="let caballo of filasPrizeMoneyByPayee">
                <tr class="borde">
                  <td colspan="4">{{ caballo.payee }}</td>
                  <td colspan="3">W9 Year: {{ caballo.payeeW9 }}</td>

                </tr>
                <tr class="borde">
                  <td colspan="4">{{ caballo.payeeAddress }}</td>
                  <td colspan="3">{{ caballo.payeeSSN }}</td>
                </tr>
                <tr *ngFor="let premio of caballo.premios" class="border-top">
                  <td>{{ premio.posicion }}</td>
                  <td>{{ premio.numPrueba }} - {{ premio.prueba }}</td>
                  <td>{{ premio.jinete }}</td>
                  <td>{{ premio.horse }}</td>
                  <td>{{ premio.premioSinDecuccion | currency }}</td>
                  <td>{{ premio.deduccion }}%</td>
                  <td>{{ premio.prize | currency }}</td>
                </tr>
                <tr class="border-top">
                  <td colspan="5"></td>
                  <td class="text-right pb-2"><b>Total</b></td>
                  <td class="pb-2"><b>{{ caballo.totalPremios | currency }}</b></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div class="row d-none d-print-flex mt-3 mb-1">
            <div class="col-12 text-center">
              <img [src]="footer" style="max-height: 120px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
          </div>
          <div class="poweredBy d-none d-print-block">Powered by Equestrian Digital <img src="assets/images/logo.png">
          </div>
        </div>
      </div>
      <div class="row justify-content-center m-0 m-print-0 p-print-0 p-2 container" style="max-width: 100%;"
        *ngIf="unappliedPrizeMoney && unappliedPrizeMoneyRows.length > 0">
        <div class="col-12 padding-print report-border" style="background-color:white;">
          <div class="col-12 d-print-none mb-3 text-right pt-3">
            <button mat-mini-fab onclick="print();" style="background-color: #8dcadf;">
              <mat-icon>print</mat-icon>
            </button>
          </div>
          <div style="display: flex; justify-content: space-between; width:100%;">
            <div style="flex: 1;" class="text-left">
              <img [src]="header" style="max-height: 90px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
            <div>
              <h2>{{ nombreConcurso }}</h2>
              <h4 style="text-align:center"><span>{{ tituloReporteEN }}</span></h4>
            </div>
            <div style="flex: 1;" class="text-right">
              <img [src]="header2" style="max-height: 90px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
          </div>
          <table class="table table-borderless mb-0 mt-3 balanceByPrizeMoney">
            <thead style="background-color: lightgrey;
                          border-style: ridge;
                          border-color: black;
                          color: black;
                          border-width: thin;">
              <tr>
                <td><strong>Entry</strong></td>
                <td><strong>Horse</strong></td>
                <td><strong>Owner</strong></td>
                <td><strong>Trainer</strong></td>
                <td><strong>Prize $</strong></td>
              </tr>
            </thead>
            <br>
            <tbody>
              <ng-container *ngFor="let payee of unappliedPrizeMoneyRows">
                <tr>
                  <td colspan="5"><strong>{{ payee.payee }}</strong></td>
                </tr>
                <tr *ngFor="let premio of payee.premios" class="border-top">
                  <td>{{ premio.entry }}</td>
                  <td>{{ premio.horse }}</td>
                  <td>{{ premio.owner }}</td>
                  <td>{{ premio.trainer }}</td>
                  <td>{{ premio.prize | currency }}</td>
                </tr>
                <tr class="border-top">
                  <td colspan="3"></td>
                  <td class="text-right pb-2"><b>Total</b></td>
                  <td class="pb-2"><b>{{ payee.totalPremios | currency }}</b></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div class="row d-none d-print-flex mt-3 mb-1">
            <div class="col-12 text-center">
              <img [src]="footer" style="max-height: 120px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
          </div>
          <div class="poweredBy d-none d-print-block">Powered by Equestrian Digital <img src="assets/images/logo.png">
          </div>
        </div>
      </div>
      <div class="row justify-content-center m-0 m-print-0 p-print-0 p-2 container" style="max-width: 100%;"
        *ngIf="appliedPrizeMoney && appliedPrizeMoneyRows.length > 0">
        <div class="col-12 padding-print report-border" style="background-color:white;">
          <div class="col-12 d-print-none mb-3 text-right pt-3">
            <button mat-mini-fab onclick="print();" style="background-color: #8dcadf;">
              <mat-icon>print</mat-icon>
            </button>
          </div>
          <div style="display: flex; justify-content: space-between; width:100%;">
            <div style="flex: 1;" class="text-left">
              <img [src]="header" style="max-height: 90px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
            <div>
              <h2>{{ nombreConcurso }}</h2>
              <h4 style="text-align:center"><span>{{ tituloReporteEN }}</span></h4>
            </div>
            <div style="flex: 1;" class="text-right">
              <img [src]="header2" style="max-height: 90px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
          </div>
          <table class="table table-borderless mb-0 mt-3 balanceByPrizeMoney">
            <thead style="background-color: lightgrey;
                          border-style: ridge;
                          border-color: black;
                          color: black;
                          border-width: thin;">
              <tr>
                <td><strong>Entry</strong></td>
                <td><strong>Horse</strong></td>
                <td><strong>Owner</strong></td>
                <td><strong>Trainer</strong></td>
                <td><strong>Prize $</strong></td>
              </tr>
            </thead>
            <br>
            <tbody>
              <ng-container *ngFor="let payee of appliedPrizeMoneyRows">
                <tr>
                  <td colspan="5"><strong>{{ payee.payee }}</strong></td>
                </tr>
                <tr *ngFor="let premio of payee.premios" class="border-top">
                  <td>{{ premio.entry }}</td>
                  <td>{{ premio.horse }}</td>
                  <td>{{ premio.owner }}</td>
                  <td>{{ premio.trainer }}</td>
                  <td>{{ premio.prize | currency }}</td>
                </tr>
                <tr class="border-top">
                  <td colspan="3"></td>
                  <td class="text-right pb-2"><b>Total</b></td>
                  <td class="pb-2"><b>{{ payee.totalPremios | currency }}</b></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div class="row d-none d-print-flex mt-3 mb-1">
            <div class="col-12 text-center">
              <img [src]="footer" style="max-height: 120px;" onerror="this.style.display='none'"
                onload="this.style.display='-webkit-inline-box'">
            </div>
          </div>
          <div class="poweredBy d-none d-print-block">Powered by Equestrian Digital <img src="assets/images/logo.png">
          </div>
        </div>
      </div>

    </div>
  </div>
</div>