<div class="card-header bg-success text-white d-print-none" style="padding: 0.25rem 1rem">
  <h3 class="d-flex m-0 justify-content-between">
    <span>Saved Cards</span>
    <a href="javascript:;" class="text-white" (click)="showAddPaymentMethod()">
      <i class="mdi mdi-credit-card-plus"></i>
    </a>
  </h3>
</div>
<ng-container *ngIf="showCardForm">
  <form id="card-form" class="p-1 d-print-none">
    <div class="row">
      <div class="col-6">
        <div class="mb-1">
          <label for="cardholder-name">Stripe Customer</label>
          <select class="form-control form-control-sm" id="customer-selected" name="customer-selected"
            [(ngModel)]="customerSelected">
            <option value="">Select Customer</option>
            <option *ngFor="let customer of customersPaymentsMethods" [value]="customer.id">{{ customer.name }}</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-1">
          <label for="cardholder-name">Cardholder name</label>
          <input class="form-control form-control-sm" placeholder="Cardholder name" id="cardholder-name" type="text">
        </div>
      </div>
      <!-- placeholder for Elements -->
      <div class="col-6">
        <div class="form-group mb-1">
          <label for="card-number">Card number</label>
          <div id="card-number"></div>
          <div id="card-number-errors" class="text-danger"></div>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group mb-1">
          <label for="card-expiry">Expiration</label>
          <div id="card-expiry"></div>
          <div id="card-expiry-errors" class="text-danger"></div>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group mb-1">
          <label for="card-expiry">CVV</label>
          <div id="card-cvv"></div>
          <div id="card-cvv-errors" class="text-danger"></div>
        </div>
      </div>
      <div class="col-12">
        <div id="card-result"></div>
      </div>
      <div class="col-12">
        <div class="form-group mb-1 text-center">
          <button type="button" class="btn btn-sm btn-light">Cancel</button>
          <button type="button" class="btn btn-sm btn-success ml-3" (click)="createPaymentMethod()">Save Card</button>
        </div>
      </div>
    </div>
  </form>
</ng-container>
<ul class="list-group d-print-none mb-2">
  <ng-container *ngFor="let customer of customersPaymentsMethods">
    <ng-container *ngIf="customer?.paymentMethods?.length">
      <li class="list-group-item d-flex py-1 px-2">
        <h5 class="m-0"><b>{{ customer?.name }}</b></h5>
      </li>
      <ng-container *ngFor="let paymentMethod of customer?.paymentMethods">
        <li class="list-group-item d-flex py-1 px-3">
          <div class="mr-3">
            <span [ngSwitch]="paymentMethod?.card?.brand">
              <span *ngSwitchCase="'amex'"><img src="assets/img/card_brands/amex.png" alt="amex"
                  style="max-width: 1.5rem; max-height: 1.5rem;"></span>
              <span *ngSwitchCase="'discover'"><img src="assets/img/card_brands/discover.png" alt="discover"
                  style="max-width: 1.5rem; max-height: 1.5rem;"></span>
              <span *ngSwitchCase="'jcb'"><img src="assets/img/card_brands/jcb.png" alt="jcb"
                  style="max-width: 1.5rem; max-height: 1.5rem;"></span>
              <span *ngSwitchCase="'mastercard_debit'"><img src="assets/img/card_brands/mastercard_debit.png"
                  alt="mastercard_debit" style="max-width: 1.5rem; max-height: 1.5rem;"></span>
              <span *ngSwitchCase="'mastercard_prepaid'"><img src="assets/img/card_brands/mastercard_prepaid.png"
                  alt="mastercard_prepaid" style="max-width: 1.5rem; max-height: 1.5rem;"></span>
              <span *ngSwitchCase="'mastercard'"><img src="assets/img/card_brands/mastercard.png" alt="mastercard"
                  style="max-width: 1.5rem; max-height: 1.5rem;"></span>
              <span *ngSwitchCase="'unionpay'"><img src="assets/img/card_brands/unionpay.png" alt="unionpay"
                  style="max-width: 1.5rem; max-height: 1.5rem;"></span>
              <span *ngSwitchCase="'visa_debit'"><img src="assets/img/card_brands/visa_debit.png" alt="visa_debit"
                  style="max-width: 1.5rem; max-height: 1.5rem;"></span>
              <span *ngSwitchCase="'visa'"><img src="assets/img/card_brands/visa.png" alt="visa"
                  style="max-width: 1.5rem; max-height: 1.5rem;"></span>
              <span *ngSwitchDefault><i class="mdi mdi-credit-card"></i></span>
            </span>
            <span class="mx-1">•⁠ ⁠•⁠ ⁠•⁠ ⁠•⁠ </span> {{ paymentMethod?.card?.last4 }}
          </div>
          <div class="mr-3" style="text-transform: capitalize;">
            {{ paymentMethod?.card?.funding }} Card
          </div>
          <div class="mr-3">
            {{ paymentMethod?.card?.exp_month }}/{{ paymentMethod?.card?.exp_year }}
          </div>
          <div class="mr-3">
            {{ paymentMethod?.billing_details?.name }}
          </div>
          <div class="ml-auto">
            <!--a href="javascript:;"><i class="mdi mdi-trash-can-outline"></i></a-->
          </div>
        </li>
      </ng-container>
    </ng-container>
  </ng-container>
</ul>