import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-btrpay-stored-cards',
  templateUrl: './btrpay-stored-cards.component.html',
  styleUrls: ['./btrpay-stored-cards.component.scss'],
  imports: [CommonModule]
})
export class BtrpayStoredCardsComponent implements OnInit {
  @Input() personId: string;
  @Output() cardSelected = new EventEmitter<{ customer_vault_billing_id: string, customer_vault_id: string }>();
  @Output() customerVaultId = new EventEmitter<string>();
  public storedCards: any[] = [];
  public selectedCardId: string | null = null;
  public cardSelectedFlag: boolean = false;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.fetchStoredCards();
  }

  fetchStoredCards(): void {
    this.http.get<any[]>(`${environment.api.nest.uri}betterpay/stored-cards/${this.personId}`).subscribe(
      (response: any) => {
        this.storedCards = response.data;
        if (this.storedCards.length > 0) {
          this.customerVaultId.emit(this.storedCards[0].customer_vault_id);
        }
      },
      (error) => {
        console.error('Error fetching stored cards:', error);
      }
    );
  }

  onCardSelect(card: any): void {
    this.cardSelected.emit({
      customer_vault_billing_id: card.customer_vault_billing_id,
      customer_vault_id: card.customer_vault_id
    });
    this.selectedCardId = card.customer_vault_billing_id;
  }

  resetSelection(): void {
    this.selectedCardId = null;
  }

  getCardLogo(cardType: string): string {
    switch (cardType.toLowerCase()) {
      case 'visa':
        return '/assets/img/card_brands/visa.png';
      case 'mastercard':
        return '/assets/img/card_brands/mastercard.png';
      case 'amex':
        return '/assets/img/card_brands/amex.png';
      case 'disc':
        return '/assets/img/card_brands/discover.png';
      case 'jcb':
        return '/assets/img/card_brands/jcb.png';
      case 'unionpay':
        return '/assets/img/card_brands/unionpay.png';
      case 'dc':
        return '/assets/img/card_brands/diners.png';
      default:
        return 'default-logo.svg';
    }
  }

  getCardIconClass(cardType: string): string {
    switch (cardType.toLowerCase()) {
      case 'visa':
        return 'fab fa-cc-visa';
      case 'mastercard':
        return 'fab fa-cc-mastercard';
      case 'amex':
        return 'fab fa-cc-amex';
      case 'discover':
        return 'fab fa-cc-discover';
      default:
        return 'fas fa-credit-card';
    }
  }
}