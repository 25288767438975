import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { ADD_AUTH_HEADER } from '../http-interceptors';


@Injectable({
  providedIn: 'root'
})
export class LogService {
  private headersGET: any;
  constructor(
    private http: HttpClient, private authService: AuthService
  ) {
    this.headersGET = {
      'authorization': 'Bearer ' + this.authService.getAuthorizationToken() || '',
    };
  }
  /**
   * Get logs filtered by competition ID and entry ID
   * @param idConcurso Competition ID
   * @param entryId Entry ID
   * @returns Observable with log data
   */
  getLogsByShowAndEntry(idConcurso: string, entryId: string): Observable<any> {
    return this.http.get(`${environment.api.nest.uri}logs/concurso/${idConcurso}/entry/${entryId}`, { headers: this.headersGET, ...ADD_AUTH_HEADER });
  }
}
