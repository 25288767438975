import {Component, OnInit} from '@angular/core';
// import { Timestamp } from 'firebase/firestore';
import {AuthService} from 'src/app/services/auth.service';
import {ConcursoService} from 'src/app/services/concurso.service';
import {FormGroup, FormBuilder, Validators, ReactiveFormsModule} from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-show-messages',
  templateUrl: './show-messages.component.html',
  styleUrls: ['./show-messages.component.css'],
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule]
})
export class ShowMessagesComponent implements OnInit {
  messages: any[] = [];
  messageForm: FormGroup;
  editForm: FormGroup;
  message = { title: '', body: '', link: '' };
  idConcurso: any;
  editingIndex: number | null = null;

  constructor(
    private authService: AuthService,
    private concursoService: ConcursoService,
    private formBuilder: FormBuilder
  ) {
    console.log('ShowMessagesComponent instantiated');
    this.idConcurso = this.authService.validarConcurso();
    this.messageForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(50)]],
      body: [''],
      link: ['']
    });

    this.editForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(50)]],
      body: [''],
      link: ['']
    });
  }

  ngOnInit() {
    this.getMessages().then(r => console.log('Messages: ', r));
  }

  onEditorContentChanged(event: any) {
    this.message.body = event.target.value;
  }

  onEditContentChanged(event: any) {
    this.editForm.patchValue({ body: event.target.value });
  }

  async getMessages() {
    console.log('Getting messages');
    try {
      const response = await firstValueFrom(this.concursoService.getConcursoNest(`${this.idConcurso}`));
      console.log('Concurso: ', response);
      if (response.data.concurso.messages) {
        this.messages = response?.data?.concurso?.messages.map(
          (message) => ({
            ...message,
            posted: (message.posted)
          })
        ) || [];
      }
      return this.messages;
    } catch (error) {
      console.error('Error getting messages: ', error);
      return [];
    }
  }

  onSubmit() {
    if (this.messageForm.invalid) {
      console.log('Form is invalid');
      return;
    }
    
    const formValues = this.messageForm.value;
    
    const newMessage = {
      title: formValues.title,
      body: formValues.body,
      link: formValues.link || '',
      posted: new Date().getTime()
    };
    
    this.messages.push(newMessage);
    console.log('Items: ', this.messages);

    try {
      this.concursoService.updateNest(
        {
          id_concurso: this.idConcurso,
          messages: this.messages
        }).then(r => {
          console.log('Response: ', r);
          // Reset form after successful submission
          this.messageForm.reset({
            title: '',
            body: '',
            link: ''
          });
        });
    } catch (error) {
      console.error('Error saving message: ', error);
    }
  }

  startEditing(index: number) {
    this.editingIndex = index;
    const message = this.messages[index];
    this.editForm.patchValue({
      title: message.title,
      body: message.body,
      link: message.link || ''
    });
  }

  saveEdit() {
    if (this.editForm.invalid || this.editingIndex === null) {
      return;
    }

    const formValues = this.editForm.value;
    const updatedMessage = {
      ...this.messages[this.editingIndex],
      title: formValues.title,
      body: formValues.body,
      link: formValues.link || '',
      edited: new Date().getTime()
    };

    this.messages[this.editingIndex] = updatedMessage;

    this.concursoService.updateNest({
      id_concurso: this.idConcurso,
      messages: this.messages
    }).then(r => {
      console.log('Response: ', r);
      this.editingIndex = null;
    });
  }

  cancelEdit() {
    this.editingIndex = null;
  }

  deleteMessage(index: number) {
    this.messages.splice(index, 1);
    this.concursoService.updateNest({
      id_concurso: this.idConcurso, 
      messages: this.messages
    })
      .then(r => console.log('Response: ', r));
  }
}
