<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid">
        <div class="row justify-content-center mt-4">
          <div class="col-12">
            <div class="card print-header d-none">
              <div class="row">
                <div class="col-4 text-center">
                  <p class="mb-0 entries-due mt-3"><b>ENTRIES DUE<br>{{ entriesDue | date : 'MMM dd, yyyy' | titlecase
                      }}</b></p>
                </div>
                <div class="col-4 text-center">
                  <img [src]="logoClub">
                  <h5 class="m-0">{{ show.nombre }}</h5>
                  <h4 class="m-0">Entry Form</h4>
                </div>
                <div class="col-4 text-center mt-auto">
                  <p class="mb-0 info">ONE HORSE PER ENTRY FORM<br>
                    <b>{{ entryAmount | currency }} Deposit Due {{ entriesDue | date : 'MMM dd, yyyy' | titlecase
                      }}</b><br>
                    <span>All horses must have membership cards,<br>
                      a valid coggins and health certificate.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- Horse -->
          <div class="col-8 w-p-100">
            <div class="row d-print-none mb-3">
              <div class="col-12">
                <button type="button" class="btn btn-primary mr-3" onclick="print()">
                  <i class="mdi mdi-printer mr-1"></i>
                  <span>Print</span>
                </button>
                <button *ngIf="solicitud.estatus == 1" type="button" class="btn btn-primary mr-3"
                  (click)="markAsPrinted()">
                  <i class="mdi mdi-printer mr-1"></i> <span>Mark as printed</span>
                </button>
                <button *ngIf="solicitud.estatus == 1 || solicitud.estatus == 4 || solicitud.estatus == 0" type="button"
                  class="btn btn-danger mr-3" (click)="markAsCancelled()">
                  <i class="mdi mdi-cancel mr-1"></i> <span>Mark as cancelled</span>
                </button>
                <!--button *ngIf="solicitud.estatus == 2" type="button" class="btn btn-primary" (click)="markAsSigned()">
                  <i class="mdi mdi-signature-freehand mr-1"></i> <span>Mark as signed</span> </button-->
                <button *ngIf="solicitud.estatus == 4 || solicitud.estatus == 1 || solicitud.estatus == 0" type="button"
                  class="btn btn-success" (click)="aceptarSolicitud()" style="float: right;">Accept</button>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-3">
                    <h5>Sumbitted:</h5>
                    <div>{{ solicitud.created | date : 'MMM dd, yyyy' }}</div>
                  </div>
                  <div class="col-6">
                    <h5>Email: </h5>
                    <div>{{solicitud.email}}</div>
                  </div>

                  <div class="col-3 text-right" >
                    <div class="form-group mb-0">
                      <input type="text" id="horseNumber"  *ngIf="solicitud.estatus == 4 || solicitud.estatus == 1|| solicitud.estatus == 0" [ngClass]="{
                        'form-control': solicitud.estatus == 4 || solicitud.estatus == 1|| solicitud.estatus == 0,
                        'form-control-plaintext': solicitud.estatus != 4 && solicitud.estatus != 1 && solicitud.estatus != 0
                        }"
                        [attr.readonly]="solicitud.estatus != 4 && solicitud.estatus != 1 && solicitud.estatus != 0 ? '' : null"
                        [(ngModel)]="horse.number" [ngModelOptions]="{standalone: true}" placeholder="Assign Entry #">
                        <h4 *ngIf="horse.number">Back Number: {{horse.number}} &nbsp;</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Horse</h5>
                <form *ngIf="horse">
                  <div class="row">
                    <div class="col-3">
                      <div class="form-group mb-0">
                        <div><b>Name:</b> {{horse?.name}}</div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group mb-0">
                        <div><b>Usef:</b> {{horse?.usef}}</div>
                      </div>
                    </div>
                    <div class="col-3" *ngIf="show.organizingCountry == 'CAN'">
                      <div class="form-group mb-0">
                        <div><b>EC:</b> {{horse?.ec}}</div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group mb-0">
                        <div><b>Fei #:</b> {{horse?.fei}}</div>
                      </div>
                    </div>
                    <div class="col-3">

                    </div>
                    <div class="form-group mb-0">
                      <div><b>Emergency contact:</b> {{ emergencyContact.value.name }} - {{ emergencyContact.value.phone
                        }}</div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Person Template -->
          <ng-template #personTemplate let-person="person" let-title="title" let-showSignature="showSignature">
            <div class="col-8 w-p-50">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{title}}</h5>
                  <form *ngIf="person">
                    <div class="row">
                      <div class="w-50">
                        <div class="form-group mb-0">
                          <div><b>Name:</b> {{person?.name}}</div>
                        </div>
                      </div>
                      <div class="w-25">
                        <div class="form-group mb-0">
                          <div><b>Usef:</b> {{person?.usef}}</div>
                        </div>
                      </div>
                      <div class="w-25" *ngIf="show.organizingCountry == 'CAN'">
                        <div class="form-group mb-0">
                          <div><b>EC:</b> {{person?.ec}}</div>
                        </div>
                      </div>
                      <div class="w-25">
                        <div class="form-group mb-0">
                          <div><b>Fei #:</b> {{person?.fei}}</div>
                        </div>
                      </div>
                      <div class="w-50" *ngIf="person?.phone">
                        <div class="form-group mb-0">
                          <div><b>Phone:</b> {{person?.phone}}</div>
                        </div>
                      </div>
                      <div class="w-50" *ngIf="person?.email">
                        <div class="form-group mb-0">
                          <div><b>Email:</b> {{person?.email}}</div>
                        </div>
                      </div>
                      <div class="w-100" *ngIf="person?.address">
                        <div class="form-group mb-0">
                          <div><b>Address:</b> {{person?.address}}</div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </ng-template>

          <!-- Riders -->
          <ng-container *ngFor="let rider of riders">
            <ng-container
              *ngTemplateOutlet="personTemplate; context: {person: rider, title: 'Rider', showSignature: false}"></ng-container>
          </ng-container>

          <!-- Owner -->
          <ng-container
            *ngTemplateOutlet="personTemplate; context: {person: owner, title: 'Owner', showSignature: false}"></ng-container>

          <!-- Trainer -->
          <ng-container
            *ngTemplateOutlet="personTemplate; context: {person: trainer, title: 'Trainer', showSignature: false}"></ng-container>

          <!-- Payee -->
          <ng-container
            *ngTemplateOutlet="personTemplate; context: {person: payee, title: 'Payee', showSignature: false}"></ng-container>

           <!-- Person Responsible -->
           <ng-container
           *ngTemplateOutlet="personTemplate; context: {person: personResponsible, title: 'Person Responsible', showSignature: false}"></ng-container>

          <!-- Stabling information -->
          <div class="col-8 w-p-50">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Stabling information</h5>
                <form [formGroup]="formStable">
                  <div class="row">
                    <div class="d-flex col-6">
                      <div class="form-group mb-0">
                        <label for="stableWith">Stable with</label>
                        <div>{{solicitud.otros?.stableWith || ''}}</div>
                      </div>
                    </div>
                    <div class="d-flex col-6">
                      <div class="form-group mb-0">
                        <label for="arrivalDate">Arrival Date</label>
                        <div>{{solicitud.otros?.arrivalDate || ''}}</div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Classes -->
          <div class="col-8 w-p-50">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Classes</h5>
                <div class="row">
                  <div class="col-12">
                    <ul class="list-unstyled mb-1" style="column-count: 2; column-gap: 10px;">
                      <li class="d-flex prueba mb-1" *ngFor="let prueba of solicitud.pruebas">
                        <div class="numero">{{ prueba.numero_prueba }}</div>
                        <div> - {{ prueba.name }}</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Charges -->
          <div class="col-8 w-p-100">
            <div class="card charges">
              <div class="card-body">
                <h5 class="card-title">Charges</h5>
                <div class="row">
                  <div class="col-12">
                    <ul class="list-unstyled mb-1" style="column-count: 3;">
                      <li *ngFor="let cargo of solicitud.cargos" class="charge">{{ cargo.nombre }} ({{ cargo.monto |
                        currency }}) x {{ cargo.qty }} = {{ cargo.total | currency }}</li>
                    </ul>
                    <p class="text-right lead mb-0">Total: {{ totalCargos() | currency }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Tax ID -->
          <div class="col-8 w-p-100 d-print-none">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Tax id</h5>
                <p class="mb-0">{{ taxId }}</p>
              </div>
            </div>
          </div>
          <!-- Payment (Con formulario para aceptar pago) -->
          <!--div class="col-8 d-print-none" *ngIf="solicitud.estatus == 0">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Payment</h5>
                <form [formGroup]="formPago" class="needs-validation">
                  <div class="mb-3">
                    <p><b>Transaction ID: </b> {{ solicitud.pago?.chaseId || (solicitud.pago?.transactionId || solicitud.pago?.notas) }}</p>
                  </div>
                  <div class="mb-3">
                    <p><b>Amount: </b> {{ solicitud.pago?.entry_charge | currency }}</p>
                  </div>
                  <div class="mb-3">
                    <p><b>Method: </b> {{ solicitud.pago?.metodo }}</p>
                  </div>
                  <div class="mb-3">
                    <p><b>Status: </b> {{ solicitud.pago?.pagado ? 'Paid' : 'Pending' }}</p>
                  </div>
                  <div class="form-group mb-3">
                    <label for="notas-cheque">Notes</label>
                    <input type="text" id="notas-cheque" class="form-control" formControlName="notas">
                  </div>
                  <div class="col-12 text-center">
                    <button type="button" class="btn btn-outline-primary mr-1" [disabled]="procesandoPago" (click)="pagarConCheque()">Register payment</button>
                  </div>
                  <div class="invalid-feedback text-center mt-2">
                    Please select a valid payment method.
                  </div>
                </form>
              </div>
            </div>
          </div-->
          <!-- Payment *ngIf="solicitud.estatus > 0" -->
          <div class="col-8 d-print-none">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Payment</h5>
                <p><b>Transaction ID: </b> {{ solicitud.pago?.chaseId || (solicitud.pago?.transactionId ||
                  solicitud.pago?.notas) }}</p>
                <p *ngIf="paymentDetails.paymentMethod">
                  <b> Method: </b>

                  <span *ngIf="paymentDetails.paymentMethod.type && paymentDetails.paymentMethod.type == 'link'">
                    <img src="assets/img/card_brands/link.png" alt="Link"
                      style="max-width: 1rem;max-height: 1rem;margin-bottom: 4px;">
                  </span>
                  {{ paymentDetails.paymentMethod.type || solicitud.pago?.metodo }}
                </p>
                <p><b>Amount: </b> {{ solicitud.pago?.entry_charge | currency }}</p>
                <p *ngIf="paymentDetails.paymentMethod && paymentDetails.paymentMethod.card"><b>Card Last 4 Digits: </b>
                  <span [ngSwitch]="paymentDetails.paymentMethod.card.brand">
                    <span *ngSwitchCase="'amex'"><img src="assets/img/card_brands/amex.png" alt="amex"
                        style="max-width: 2rem; max-height: 2rem;"></span>
                    <span *ngSwitchCase="'discover'"><img src="assets/img/card_brands/discover.png" alt="discover"
                        style="max-width: 2rem; max-height: 2rem;"></span>
                    <span *ngSwitchCase="'jcb'"><img src="assets/img/card_brands/jcb.png" alt="jcb"
                        style="max-width: 2rem; max-height: 2rem;"></span>
                    <span *ngSwitchCase="'mastercard_debit'"><img src="assets/img/card_brands/mastercard_debit.png"
                        alt="mastercard_debit" style="max-width: 2rem; max-height: 2rem;"></span>
                    <span *ngSwitchCase="'mastercard_prepaid'"><img src="assets/img/card_brands/mastercard_prepaid.png"
                        alt="mastercard_prepaid" style="max-width: 2rem; max-height: 2rem;"></span>
                    <span *ngSwitchCase="'mastercard'"><img src="assets/img/card_brands/mastercard.png" alt="mastercard"
                        style="max-width: 2rem; max-height: 2rem;"></span>
                    <span *ngSwitchCase="'unionpay'"><img src="assets/img/card_brands/unionpay.png" alt="unionpay"
                        style="max-width: 2rem; max-height: 2rem;"></span>
                    <span *ngSwitchCase="'visa_debit'"><img src="assets/img/card_brands/visa_debit.png" alt="visa_debit"
                        style="max-width: 2rem; max-height: 2rem;"></span>
                    <span *ngSwitchCase="'visa'"><img src="assets/img/card_brands/visa.png" alt="visa"
                        style="max-width: 2rem; max-height: 2rem;"></span>
                    <span *ngSwitchDefault><i class="mdi mdi-credit-card"></i></span>
                  </span>
                  <span class="ml-1">{{ paymentDetails.paymentMethod.card.last4 }}</span>
                </p>
                <p><b>Status: </b> {{ solicitud.pago?.pagado ? 'Paid' : 'Pending' }}</p>
              </div>
            </div>
          </div>
          <!-- FEDERATION ENTRY AGREEMENT -->
          <div *ngIf="show.organizingCountry == 'USA'" id="usef-agreement" class="col-12 legal d-none d-print-block">
            <div class="card">
              <h5>FEDERATION ENTRY AGREEMENT</h5>
              <p>By entering a Federation-licensed Competition and signing this entry blank as the Owner, Lessee,
                Trainer, Manager, Agent, Coach, Driver, Rider, Handler,
                Vaulter or Longeur and on behalf of myself and my principals, representatives, employees and agents, I
                agree that I am subject to the Bylaws and Rules
                of The United States Equestrian Federation, Inc. (the "Federation") and the local rules of
                __________________________(Competition). I agree to be
                bound by the Bylaws and Rules of the Federation and of the competition. I will accept as final the
                decision of the Hearing Committee on any question arising
                under the Rules, and agree to release and hold harmless the competition, the Federation, their
                officials, directors and employees for any action taken under
                the Rules. I represent that I am eligible to enter and/or participate under the Rules, and every horse I
                am entering is eligible as entered. I also agree that as
                a condition of and in consideration of acceptance of entry, the Federation and/or the Competition may
                use or assign photographs, videos, audios, cable -
                casts, broadcasts, internet, film, new media or other likenesses of me and my horse taken during the
                course of the competition for the promotion, coverage
                or benefit of the competition, sport, or the Federation. Those likenesses shall not be used to advertise
                a product and they may not be used in such a way as
                to jeopardize amateur status. I hereby expressly and irrevocably waive and release any rights in
                connection with such use, including any claim to compensation, invasion of privacy, right of publicity,
                or to misappropriation. The construction and application of Federation rules are governed by the laws of
                the State
                of New York, and any action instituted against the Federation must be filed in New York State. See
                GR908.4.</p>
              <p><b>I HAVE CAREFULLY READ THIS DOCUMENT IN ITS ENTIRETY, UNDERSTAND ALL OF ITS TERMS AND CONDITIONS, AND
                  KNOW IT CONTAINS AN ASSUMPTION
                  OF RISK, RELEASE AND WAIVER FROM LIABILITY, AS WELL AS A HOLD HARMLESS AND INDEMNIFICATION
                  OBLIGATIONS.</b></p>
              <p>By signing below, I (as the participant or as the Parent/Legal Guardian of the minor identified below)
                hereby accept and agree to the terms and conditions of this Agreement in connection with my (or the
                minor's) participation in any USEF Event. If, despite this Agreement, I, or anyone on my behalf or the
                minor's behalf, makes a claim for Liability against any of the Released Parties, I will indemnify,
                defend and hold harmless each of the Released Parties
                from any such Liabilities as the result of such claim.</p>
              <p><b>BY SIGNING BELOW, I AGREE</b> to be bound by all applicable Federation Rules and all terms and
                provisions of this entry blank and all terms and provisions
                of this Prize List. If I am signing and submitting this Agreement electronically, I acknowledge that my
                electronic signature shall have the same validity,
                force and effect as if I affixed my signature by my own hand.</p>
              <h5>WAIVER AND RELEASE OF LIABILITY, ASSUMPTION OF RISK AND INDEMNITY AGREEMENT</h5>
              <p>For and in consideration of United States Equestrian Federation, Inc. dba US Equestrian ("USEF")
                allowing me, the undersigned, to participate in any
                capacity (including as a rider, driver, handler, vaulter, longeur, lessee, owner, agent, coach,
                official, trainer or volunteer) in a USEF sanctioned, licensed or
                approved event or activity, including but not limited to equestrian clinics, practices, shows,
                competitions and related or incidental activities and
                ________________________________(<b>"USEF Event" or "USEF Events"</b>); I, for myself, and on
                behalf of my spouse, children, heirs and next of kin,
                and any legal and personal representatives, executors, administrators, successors,, and assigns, hereby
                agree to and make the following contractual repre- sentations pursuant to this Agreement (the
                "Agreement"):
              </p>
              <p>
                A. RULES AND REGULATIONS: I hereby agree that I have read, understand, and agree to be bound by all
                applicable Federation Bylaws, rules, and policies
                including the USEF Safe Sport Policy and Minor Athlete Abuse Prevention Policies (MAAPP) as published at
                www.usef.org, as amended from time to time.
              </p>
              <p>B. ACKNOWLEDGMENT OF RISK: I knowingly, willingly, and voluntarily acknowledge the inherent risks
                associated with the sport of equestrian and know that
                horseback riding and related equestrian activities are inherently dangerous, and that participation in
                any USEF Event involves risks and dangers including, without limitation, the potential for serious
                bodily injury (including broken bones, head or neck injuries), sickness and disease (including
                communicable diseases),
                trauma, pain & suffering, permanent disability, paralysis and death; loss of or damage to personal
                property (including my mount & equipment) arising out of the
                unpredictable behavior of horses; exposure to extreme conditions and circumstances; accidents involving
                other participants, eventstaff, volunteersor spectators; contact orcollision with other participants and
                horses, natural or manmadeobjects; adverseweather conditions; facilities issues and premises conditions;
                failure of protective equipment (including helmets); inadequate safety measures; participants of varying
                skill levels; situations beyond the immediate control of
                the USEF Event organizers and competition management; and other undefined, not readily foreseeable and
                presently unknown risks and dangers <b>("Risks")</b>.
              </p>
              <p class="text-center"><b>EQUINE ACTIVITY LIABILITY ACT WARNING:</b><br>
                CAUTION: HORSEBACK RIDING AND EQUINE ACTIVITIES CAN BE DANGEROUS. RIDE AT YOUR OWN RISK.<br>
                <b>Under the laws of most States, an equine activity sponsor or equine professional is not liable for
                  any injury to, or the death of, a participant in
                  equine activities resulting from the inherent risks of equine activities.</b>
              </p>
              <p>C. ASSUMPTION OF RISK: I understand that the aforementioned Risks may be caused in whole or in part or
                result directly or indirectly from the negligence
                of my own actions or inactions, the actions or inactions of others participating in the USEF Events, or
                the negligent acts or omissions of the Released Parties defined below, and I hereby voluntarily and
                knowingly assume all such Risks and responsibility for any damages, liabilities, losses, or expenses
                that I
                incur as a result of my participation in any USEF Events. I also agree to be responsible for any injury
                or damage caused by me, my horse, my employees or
                contractors under my direction and control at any USEF Event.
              </p>
              <p>D. WAIVER AND RELEASE OF LIABILITY, HOLD HARMLESS AND INDEMNITY: In conjunction with my participation
                in any USEF Event, I hereby release, waive
                and covenant not to sue, and further agree to indemnify, defend and hold harmless the following parties:
                USEF, USEF Recognized Affiliate Associations,
                the United States Olympic & Paralympic Committee (USOPC), USEF clubs, members, Event participants
                (including athletes/riders, coaches, trainers, judges/officials, and other personnel), the Event owner,
                licensee, and competition managers; the promoters, sponsors, or advertisers of any USEF Event; any
                charity or other beneficiary which may benefit from the USEF Event; the owners, managers, or lessors of
                any facilities or premises where a USEF Event may
                be held; and all directors, officers, employees, agents, contractors, and volunteers of any of the
                aforementioned parties <b>("Released Parties" or "Event Organizers")</b>, with respect to any liability,
                claim(s), demand(s),
                cause(s) of action, damage(s), loss, or expense (including
                court costs and reasonable attorney fees) of any kind or nature <b>("Liability")</b> which may arise out
                of, result from, or relate in any way to my participation in
                the USEF Events, including claims for Liability caused in whole or in part by the negligent acts or
                omissions of the Released Parties.
              </p>
              <p>E. COMPLETE AGREEMENT AND SEVERABILITY CLAUSE: This Agreement represents the complete understanding
                between the parties regarding these issues
                and no oral representations, statements or inducements have been made apart from this Agreement. If any
                provision of this Agreement is held to be
                unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this
                Agreement and shall not affect the validity and
                enforceability of any remaining provisions.
              </p>
              <p><b>I HAVE CAREFULLY READ THIS DOCUMENT IN ITS ENTIRETY, UNDERSTAND ALL OF ITS TERMS AND CONDITIONS, AND
                  KNOW IT CONTAINS AN ASSUMPTION
                  OF RISK, RELEASE AND WAIVER FROM LIABILITY, AS WELL AS A HOLD HARMLESS AND INDEMNIFICATION
                  OBLIGATIONS.</b>
              </p>
              <p>By signing below, I (as the participant or as the Parent/Legal Guardian of the minor identified below)
                hereby accept and agree to the terms and conditions of this Agreement in connection with my (or the
                minor's) participation in any USEF Event. If, despite this Agreement, I, or anyone on my behalf or the
                minor's behalf, makes a claim for Liability against any of the Released Parties, I will indemnify,
                defend and hold harmless each of the Released Parties
                from any such Liabilities as the result of such claim.
              </p>
              <p><b>The parties agree that this agreement may be electronically signed. The parties agree that the
                  electronic signatures appearing on this agreement
                  are the same as handwritten signatures for the purposes of validity, enforceability, and
                  admissibility.</b>
              </p>
            </div>
          </div>
          <div *ngIf="show.organizingCountry == 'CAN'" id="ec-agreement" class="col-12 legal d-none d-print-block">
            <div class="card">
              <h5>EQUESTRIAN CANADA & USEF EQUESTRIAN ENTRY AGREEMENT</h5>
              <p>This document will effect your legal rights &
                liabilities. Please read carefully and sign below:
                Every entry at <b>{{ show.nombre }}</b> shall constitute an agreement and affirmation that all
                participants
                (which include, without limitations, the owner, lessee, trainer, manager, agent, coach, driver, handler,
                and the horse), for themselves,
                their principals, representative, employees, and agents: (1) Shall be subject and bound by the
                constitution and rules of Equestrian Canada and the local rules of the competition; (2) Represent that
                every horse, rider, driver, and handler, is
                eligible as entered; (3 )Agree that they participate voluntarily in the competition fully aware that
                horse
                sports and the competition involve inherently dangerous risk of serious injury or death, and accept that
                no helmet or protective equipment
                can protect against all foreseeable injury, and by participation they expressively assume any and all
                risks of injury or loss, and they agree to indemnify and hold EC, Angelstone Inc., the competitions and
                their officials, directors, employees
                and agent harmless from and against all claims including for any injury or loss resulted, directly or
                indirectly, from the negligent acts or omissions of said officials, directors, employees or agents of
                Angelstone Inc., EC or the competition.If
                the responsible trainer for any horse registered on this entry form, and/or the exhibitor riding any
                horse
                registered on this entry
                form, and/or the owner of any horse registered on this entry form, and/or any spectators in attendance
                at
                the horse show by invitation of the owner or exhibitor of any horse registered on this entry form,should
                have a dog on the
                showgrounds that is off leash, or gets loose from its leash, and this loose dog causes any type of
                incident that gives rise to injuries and/or losses, then this responsible trainer, exhibitor, owner,
                and/or spectator that had care, custody, and/or
                control of this loose dog just prior to the time that the injuries and/or losses occurred, shall hold
                Anglestone Tournaments Inc., Angelstone Events Inc., and any agents, employees, or representatives of
                those two entities, harmless from and
                against any and all legal claims that may arise from this incident caused by the loose dog,including all
                damages, expenses, and lawyer fees.
                I hereby certify that every horse listed on this entry form has met the requirements of Article A519,
                Vaccinations. See Rules of Equestrian Canada, Section A, General Regulations, Article A519,
                Vaccinations.
                Your signature below indicates
                your acceptance of and compliance with the above statement.</p>
            </div>
          </div>


          <div class="col-12 mt-1" id="signatures">
            <div class="card">
              <div class="row mt-3">
                <ng-container *ngFor="let rider of riders">
                  <ng-container *ngIf="rider?.signatureRider">
                    <div class="col-4 text-center">
                      <div *ngIf="rider === riders[0]" [innerHTML]="mainRider?.signatureRider" class="signature"
                        style="height: 60px; min-height: 60px; width: 100%; display: flex; align-items: center; justify-content: center;">
                      </div>
                      <div style="border-top: 1px solid black; font-size: 0.85rem;">Rider
                        <br>
                        {{ rider?.name }} (or guardian if under 18) 
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="owner && owner?.signatureOwner">
                  <div class="col-4 text-center">
                    <div [innerHTML]="owner?.signatureOwner" class="signature"
                      style="height: 60px; min-height: 60px; width: 100%; display: flex; align-items: center; justify-content: center;">
                    </div>
                    <div style="border-top: 1px solid black; font-size: 0.85rem;">Owner ({{ owner?.name }})</div>
                  </div>
                </ng-container>
                <ng-container *ngIf="trainer && trainer?.signatureTrainer">
                  <div class="col-4 text-center">
                    <div [innerHTML]="trainer?.signatureTrainer" class="signature"
                      style="height: 60px; min-height: 60px; width: 100%; display: flex; align-items: center; justify-content: center;">
                    </div>
                    <div style="border-top: 1px solid black; font-size: 0.85rem;">Trainer ({{ trainer?.name }})</div>
                  </div>
                </ng-container>
                <ng-container *ngIf="personResponsible && personResponsible?.signaturePersonResponsible">
                  <div class="col-4 text-center">
                    <div [innerHTML]="personResponsible?.signaturePersonResponsible" class="signature"
                      style="height: 60px; min-height: 60px; width: 100%; display: flex; align-items: center; justify-content: center;">
                    </div>
                    <div style="border-top: 1px solid black; font-size: 0.85rem;">Person Responsible ({{ personResponsible?.name }})</div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div id="ecWaiver" *ngIf="show.organizingCountry == 'CAN'" style="page-break-before: always;" class="d-none d-print-block">
          <div class="card">
            <div class="row"> 
              <div class="col-12 text-center">
                <img src="/assets/images/logo-ec.svg" alt="Equestrian Canada Logo" class="text-center" style="width: 100px; height: 100px;">
              </div>
            </div>
            
            <h2 class="text-center mb-4">WAIVERS</h2>
            <p>
              "In the event that <b> {{riders[0]?.name}}</b> participates in an Equestrian Canada sanctioned competition
              where protective headgear is required for juniors, he/she will wear properly fitted and fastened approved
              headgear at all times while riding or driving at the event location. It is understood that juniors not
              meeting this requirement will not be allowed to compete at these competitions."
            </p>
            <p>
              "I hereby certify that every horse, rider and/or driver is eligible as entered and agree for myself and my
              representatives to be bound by the Constitution and rules of Equestrian Canada at this competition. It is
              hereby recognized that all equestrian sports involve inherent risk and that no helmet or protective
              equipment can protect against all foreseeable injury. I hereby accept this risk and hold harmless EC, the
              competition, their officials, organizers, agents, employees and their representatives.</p>
            <p>
              “The person responsible (PR) agrees to the release of any information on the entry form to EC”
            </p>
            <div class="row">
              <div class="col-8">
                <p>
                  <b>Signature of Person Responsible: </b>   <span [innerHTML]="personResponsible?.signaturePersonResponsible" class="signature"
                  style="display: inline-block; width: 120px; height: 20px; min-height: 20px; border-bottom: 1px solid black; background-color: #f3f3f3; ">
                </span>
                </p>
                <p>
                  <b>Name of Person Responsible (please Print): {{ personResponsible?.name }} {{ personResponsible?.lastname }} </b>
                </p>
                <p>
                  (Section A, General Regulations-Glossay: Person Responsible)
                </p>
              </div>
              <div class="col-4">
                <p>
                  <b>Date: {{solicitud.created | date:'dd/MM/yyyy'}}</b>
                </p>
                <p>
                  <b>EC#: {{ personResponsible?.ec }} </b>
                </p>
              </div>
            </div>
            <p>The Person Responsible for a horse must be an adult who has, or shares, responsibility for the care,
              training, custody and performance of the horse and who has official responsibility for that horse under EC
              Rules and is liable under the penalty provisions of the Rules for any violation of the EC Rules. For the
              purpose of these Rules, the Person Responsible is normally the trainer, owner or the competitor who rides
              or drives the horse during an event, or a parent or legal guardian in the case of junior competitors. The
              Person Responsible is ultimately responsible for the condition, fitness and management of the horse and is
              alone responsible for any act performed in the stables by himself or herself or by any other person with
              authorized access to the horse, or while the horse is being ridden, driven or exercised. The Person
              Responsible must hold a valid EC Sport License in good standing at the same level or higher in which the
              horse is competing. However, when the competitor is a Junior, the competitor cannot be the Person
              Responsible and the Person Responsible may be a parent/guardian who must be at minimum an EC or USEF
              member in good standing.</p>
            <p>The entry form must list the name of the Person Responsible, their EC or USEF member number and be
              signed.</p>
            <div class="row">
              <div class="col-8">
                <b>Signature of Rider (or Parent/Guardian if Junior): </b>
                <span [innerHTML]="mainRider?.signatureRider" class="signature"
                  style="display: inline-block; width: 120px; height: 20px; min-height: 20px; border-bottom: 1px solid black; background-color: #f3f3f3; ">
                </span>
              </div>
              <div class="col-4">
                <p>
                  <b>Date: {{solicitud.created | date:'dd/MM/yyyy'}}</b>
                </p>
              </div>

            </div>
            <div class="row">
              <div class="col-12">
                <p>
                  <b>Name of Rider (please Print): {{ mainRider?.name }} </b>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <b>Signature of Owner: </b>
                <span [innerHTML]="owner?.signatureOwner" class="signature"
                  style="display: inline-block; width: 120px; height: 20px; min-height: 20px; border-bottom: 1px solid black; background-color: #f3f3f3">
                </span>
              </div>
              <div class="col-4">
                <p>
                  <b>Date: {{solicitud.created | date:'dd/MM/yyyy'}}</b>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p>
                  <b>Name of Owner (please Print): {{ owner?.name }} </b>
                </p>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-6">
                <p>
                  <b>Emergency contact Name:</b> {{ emergencyContact.value.name }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b>Phone:</b> {{ emergencyContact.value.phone }}
                </p>
              </div>
            </div>
            <div class="row" style="margin-top: 200px;">
                <div class="col-4 text-center offset-4" >
                  <p class="text-muted">
                    100 - 308 Promenade Legget Drive<br>
                    Ottawa, ON Canada K2K 1Y6<br>
                    613 287 1515  equestrian.ca
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>