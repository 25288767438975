import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ProductService } from '../services/product.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
declare let $: any;

interface Product {
  id?: string;
  name: string;
  category: string;
  cost: number;
  price: number;
  sku: string;
  qty: number;
  image?: string;
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  private token: string;
  private sessionData: any
  public idConcurso: string | boolean;
  private idUsuario: string;
  public nombreConcurso: string | null;
  public privilegios: number;
  public products: any[];
  public form: UntypedFormGroup;
  public loading: boolean;
  public productToDelete: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private productService: ProductService
  ) {
    this.token = '';
    this.sessionData = {};
    this.idConcurso = '';
    this.idUsuario = '';
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.privilegios = 0;
    this.products = [];
    this.form = new UntypedFormGroup({
      category: new UntypedFormControl('', [Validators.required]),
      cost: new UntypedFormControl('', [Validators.required]),
      sku: new UntypedFormControl('', [Validators.required]),
      qty: new UntypedFormControl('', [Validators.required]),
      price: new UntypedFormControl('', [Validators.required]),
      name: new UntypedFormControl('', [Validators.required]),
    });
    this.loading = false;
  }

  ngOnInit() {
    this.getProducts();
  }

  /**
   * Get products from Nest API
   * @returns void
   * @throws Error if it is not possible to query the products list
   */
  private async getProducts(): Promise<void> {
    try {
      const response = await firstValueFrom(this.productService.getProducts());
      console.log(response);
      if (!response.error) {
        this.products = response.data;
      } else {
        $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
        console.error(response);
      }
    } catch (error) {
      $.NotificationApp.send("Error", "It has not been possible to query the products list.", 'bottom-right', '#fa5c7c', 'error', 5000);
      console.error(error);
    } finally {
      $('#loader').hide();
    }
  }

  /**
   * Add a new product
   * @returns void
   * @throws Error if it is not possible to save the product
   */
  public async addProduct(): Promise<void> {
    if (!this.form.valid) return; 
    try {
      this.loading = true;
      this.form.disable();
      
      const product = {
        ...this.form.value,
        image: '' // TODO: Add image
      };

      const response = await firstValueFrom(this.productService.save(product));

      if (!response.error) {
        await this.getProducts();
        this.form.reset();
        $.NotificationApp.send("Success", "Product saved successfully", 'bottom-right', '#0acf97', 'success');
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      // Verifica si el error es una instancia de Error, si es así, usa el mensaje del error, si no, usa un mensaje por defecto
      const errorMessage = error instanceof Error ? error.message : "It has not been possible to save the product.";
      $.NotificationApp.send("Error", errorMessage, 'bottom-right', '#fa5c7c', 'error');
      console.error(error);
    } finally {
      this.form.enable();
      this.loading = false;
    }
  }

  /**
   * Edit a product
   * @param product - The product to edit
   * @returns void
   * @throws Error if it is not possible to edit the product
   */
  public async editarProducto(product: Product): Promise<void> {
    try {
      this.loading = true;
      const response = await firstValueFrom(this.productService.edit(product));
      
      if (!response.error) {
        await this.getProducts(); // Actualizar la lista de productos
        $.NotificationApp.send("Success", "Product updated successfully", 'bottom-right', '#0acf97', 'success');
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 
        "It has not been possible to edit the product.";
      $.NotificationApp.send("Error", errorMessage, 'bottom-right', '#fa5c7c', 'error');
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  public modalDeleteProduct(product: any): void {
    this.productToDelete = product;
    $('#modal-delete-product').modal('show');
  }

  public async deleteProduct(): Promise<void> {
    try {
      this.loading = true;
      const response = await firstValueFrom(this.productService.delete(this.productToDelete.id));
      
      if (!response.error) {
        await this.getProducts();
        $('#modal-delete-product').modal('hide');
        $.NotificationApp.send("Success", "Product deleted successfully", 'bottom-right', '#0acf97', 'success');
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 
        "It has not been possible to delete the product.";
      $.NotificationApp.send("Error", errorMessage, 'bottom-right', '#fa5c7c', 'error');
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
}
