import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable()
export class ReportesService extends Api {

  private token: string;

  constructor(private http: HttpClient) {
    super();
    this.token = localStorage.getItem('token') || '';
  }

  public getMultasPorResponsable(idConcurso): Observable<any> {
    const params = JSON.stringify({ id_concurso: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/multas_responsable/`, params, { headers: headers });
  }

  public getEmailJinetes(idConcurso): Observable<any> {
    const params = JSON.stringify({ id_concurso: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/mails-jinetes/`, params, { headers: headers });
  }

  public getInspeccionVeterinaria(idConcurso, semana): Observable<any> {
    const params = JSON.stringify({ idConcurso, semana });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/inspeccion-veterinaria/`, params, { headers: headers });
  }

  public getStallsReport(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/stalls/`, params, { headers: headers });
  }

  public getStallsSummary(idConcurso): Observable<any> {
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${this.token}`
    };

    return this.http.get(`${environment.api.nest.uri}transacciones/stallsbyTrainer/${idConcurso}`, { headers: headers });
  }

  public getCargosPorResponsables(idConcurso): Observable<any> {
    const params = JSON.stringify({ id_concurso: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/cargos_responsable/`, params, { headers: headers });
  }

  public getUsefResults(idConcurso): Observable<any> {
    const params = JSON.stringify({ id_concurso: idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/usef_results/`, params, { headers: headers });
  }

  public getBalancePerDivisionReport(idConcurso, tipo_reporte): Observable<any> {
    const params = JSON.stringify({ id_concurso: idConcurso, tipo_reporte: tipo_reporte });
    console.log(params)
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/balance_por_division/`, params, { headers: headers });
  }



  public getJinetesSinFem(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/jinetes_sin_fem/`, params, { headers: headers });
  }

  public getMissingTaxId(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/missingTaxId/`, params, { headers: headers });
  }

  public getCreditCardPayments(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/credit-card-payments/`, params, { headers: headers });
  }

  public getCreditCardPaymentsTransactions(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/credit-card-payments-transactions/`, params, { headers: headers });
  }

  public getCaballosSinFem(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/caballos_sin_fem/`, params, { headers: headers });
  }

  public getCaballos(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/caballos/`, params, { headers: headers });
  }

  public getCaballosSinPruebas(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/caballos_sin_pruebas/`, params, { headers: headers });
  }

  public getJinetes(idConcurso, alfabetico): Observable<any> {
    const params = JSON.stringify({ idConcurso, alfabetico });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/jinetes/`, params, { headers: headers });
  }

  public getJinetesNest(idConcurso): Observable<any> {
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.nest.uri}binomios/show/${idConcurso}/riders`, { headers });
  }

  public getResumenEjecutivo(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/resumen-ejecutivo/`, params, { headers: headers });
  }

  public getInscripciones(idConcurso, semana): Observable<any> {
    const params = JSON.stringify({ id_concurso: idConcurso, semana: semana });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/inscripciones_por_semana/`, params, { headers: headers });
  }

  public getPasesDeSalida(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/pases_de_salida/`, params, { headers: headers });
  }

  public getSuspendedPeople(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/suspended_people/`, params, { headers: headers });
  }

  public getLiveSuspendedPeople(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/suspended_people/live.php`, params, { headers: headers });
  }

  public getMembershipPeople(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    //return this.http.post(`${environment.api.uri}reportes/membership-people/index.php`, params, { headers: headers });
    return this.http.get(`${environment.api.nest.uri}binomios/suspendedPeople/${idConcurso}`, { headers: headers });
  }

  public getMembershipHorses(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/membership-horses/index.php`, params, { headers: headers });
  }

  public getSuspendedOwners(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/suspended_riders/owners.php`, params, { headers: headers });
  }

  public getSuspendedTrainers(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/suspended_riders/trainers.php`, params, { headers: headers });
  }

  public getSuspendedHorses(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/suspended_horses/`, params, { headers: headers });
  }

  public getLiveSuspendedHorses(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/suspended_horses/live.php`, params, { headers: headers });
  }

  public getPremios(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/premios/`, params, { headers: headers });
  }

  public getMasterList(idConcurso, evento): Observable<any> {
    const params = JSON.stringify({ idConcurso, evento });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/master_list/`, params, { headers: headers });
  }

  public getFilteredMasterList(idConcurso, evento): Observable<any> {
    const params = JSON.stringify({ idConcurso, evento });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/master_list/filtered.php`, params, { headers: headers });
  }

  public getBalanceByPrizeMoney(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    //return this.http.post(`${environment.api.uri}reportes/prizeMoneyRecipient/`, params, { headers: headers });
    return this.http.get(`${environment.api.nest.uri}reports/showBalances?showId=${idConcurso}`, { headers: headers });
  }

  public getPrizeMoneyByEntry(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/prizeMoneyByEntry/`, params, { headers: headers });
  }

  public getPrizeMoneyByPayee(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/prizeMoneyByPayee/`, params, { headers: headers });
  }

  public getPayeesWithMailingAddress(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.post(`${environment.api.uri}reportes/payeesWithMailingAddress/`, params, { headers });
  }

  public getPrizesByClass(idConcurso: string | boolean, type?: string): Observable<any> {
    const params = JSON.stringify({ idConcurso, type: type || '' });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/prizes-by-class/`, params, { headers: headers });
  }

  public getUnappliedPrizeMoney(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/unapplied-prize-money/`, params, { headers: headers });
  }

  public getAppliedPrizeMoney(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/applied-prize-money/`, params, { headers: headers });
  }


  public getStandardUsefResults(idConcurso): Observable<any> {
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}reportes/standard-usef-result/?c=${idConcurso}`, { headers: headers });
  }

  public getPrintableResults(idConcurso): Observable<any> {
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}reportes/print_results/?c=${idConcurso}`, { headers: headers });
  }



  public getClassLabels(idConcurso): Observable<any> {
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.uri}pruebas/getList/?c=${idConcurso}`, { headers: headers });
  }

  public getRobinsReport(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}reportes/robin/`, params, { headers: headers });
  }

  public getEntriesDivisionType(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/entries-division-type/`, params, { headers: headers });
  }

  public getQuickbooks(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/quickbooks/`, params, { headers: headers });
  }

  public updateSuspendedMembershipPeople(idConcurso: string | boolean, personas: any[] = []): Observable<any> {
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.get(`${environment.api.nest.uri}personas/usef/update/suspensions/${idConcurso}`, { headers: headers });
  }

  public updateSuspendedMembershipHorses(idConcurso: string | boolean, caballos: any[] = []): Observable<any> {
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.get(`${environment.api.nest.uri}caballos/usef/update/suspensions/${idConcurso}`, { headers: headers });
  }

  public getUSJASuspendedMembershipPeople(idConcurso: string | boolean, personas: any[] = []): Observable<any> {
    let params: any = { idConcurso };
    if (personas) params['personas'] = personas;
    params = JSON.stringify(params);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}personas/update-ushja-suspension-membership/get.php`, params, { headers: headers });
  }

  public getUSJASuspendedMembershipHorses(idConcurso: string | boolean, caballos: any[] = []): Observable<any> {
    let params: any = { idConcurso };
    if (caballos) params['caballos'] = caballos;
    params = JSON.stringify(params);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}caballos/update-ushja-suspension-membership/get.php`, params, { headers: headers });
  }

  public setUSHJApersonSuspensionInfoBulk(idConcurso, personas): Observable<any> {
    let params: any = { idConcurso, personas };
    params = JSON.stringify(params);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}personas/update-ushja-suspension-membership/update.php`, params, { headers: headers });
  }


  public setUSHJAHorseSuspensionInfoBulk(idConcurso, caballos): Observable<any> {
    let params: any = { idConcurso, caballos };
    params = JSON.stringify(params);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post(`${environment.api.uri}caballos/update-ushja-suspension-membership/set.php`, params, { headers: headers });
  }

  public getMerchInventory(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/merch-inventory/`, params, { headers: headers });
  }

  public getChecks(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/checks/`, params, { headers: headers });
  }

  public getCreditCardPaymentsPerDay(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/credit-card-payments-per-day/`, params, { headers: headers });
  }

  public getTrainers(idConcurso): Observable<any> {
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}reportes/trainers/contacts.php`, params, { headers: headers });
  }

  public getDirectory(idConcurso): Observable<any> {
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.nest.uri}reports/directory/?showId=${idConcurso}`, { headers });
  }

  public getSeriesNumbers(idsConcurso: Observable<any>) {
    const headers = {
      'x-api-key': this.api_key
    };

    return this.http.get(`${environment.api.nest.uri}reports/seriesNumbers?showIds=${idsConcurso}`, { headers });
  }

  public getFeedOrdersSummary(showId): Observable<any> {

    return this.http.get(`${environment.api.nest.uri}orders/summary?showId=${showId}`);
  }

  public getClassesWithNoResults(idConcurso): Observable<PruebaNest[]> {
    const headers = {
      'x-api-key': this.api_key
    };

    // The reponse comes in the format { executionTime: string, data: PruebaNest[] }
    // We need to return the data array

    return this.http.get<{ executionTime: string, data: PruebaNest[] }>(
      `${environment.api.nest.uri}reports/classesWithoutResults?showId=${idConcurso}`, 
      { headers }
    ).pipe(
      map(response => response.data)
    );
  }

  public getClassesForPrintable(id_concurso: any): Observable<any> {
    const headers = {
      'x-api-key': this.api_key
    };
    return this.http.get(`https://nest.equestrian.digital/pruebas/printResults?idConcurso=${id_concurso}`, { headers });
  }

  public getPrintable(pruebas): Observable<any> {
    const params = JSON.stringify({ pruebas });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}/reportes/print_results/getClasses.php`, params, { headers: headers });
  }
}
