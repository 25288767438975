import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes, Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { NgTempusdominusBootstrapModule } from 'ngx-tempusdominus-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore/';
import { environment } from '../environments/environment';
import localeEn from '@angular/common/locales/en';
//import { SignaturePadModule } from 'angular2-signaturepad';

import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { DirectorioPersonasComponent } from './directorio-personas/directorio-personas.component';
import { DirectorioCaballosComponent } from './directorio-caballos/directorio-caballos.component';
import { CrearClubComponent } from './crear-club/crear-club.component';
import { MenuLateralComponent } from './menu-lateral/menu-lateral.component';
import { InscripcionesRemotasComponent } from './inscripciones-remotas/inscripciones-remotas.component';
import { ImportarListasComponent } from './importar-listas/importar-listas.component';
import { LoginComponent } from './login/login.component';
import { InicioComponent } from './inicio/inicio.component';
import { CrearConcursosComponent } from './crear-concursos/crear-concursos.component';
import { ConvocatoriaComponent } from './convocatoria/convocatoria.component';
import { ResultadosComponent } from './resultados/resultados.component';
import { CajaComponent } from './caja/caja.component';
import { ContabilidadComponent } from './contabilidad/contabilidad.component';
import { ReportesComponent } from './reportes/reportes.component';
import { PaseComponent } from './pase/pase.component';
import { ResumenEjecutivoComponent } from './resumen-ejecutivo/resumen-ejecutivo.component';
import { ListasComponent } from './listas/listas.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { VideosComponent } from './videos/videos.component';
import { MenuVideosComponent } from './menu-videos/menu-videos.component';
import { PeticionesVideosComponent } from './peticiones-videos/peticiones-videos.component';
import { ClientesVideosComponent } from './clientes-videos/clientes-videos.component';
import { PerfilClienteComponent } from './perfil-cliente/perfil-cliente.component';
import { PagosSinAsignarComponent } from './pagos-sin-asignar/pagos-sin-asignar.component';
import { CrearUsuarioInscripcionesComponent } from './crear-usuario-inscripciones/crear-usuario-inscripciones.component'; //Validar permisos
import { VincularUsuarioInscripcionesComponent } from './vincular-usuario-inscripciones/vincular-usuario-inscripciones.component'; //Validar permisos
import { GestionUsuariosInscripcionesComponent } from './gestion-usuarios-inscripciones/gestion-usuarios-inscripciones.component'; //Validar permisos
import { PantallasComponent } from './pantallas/pantallas.component';
import { ControlPantallaComponent } from './control-pantalla/control-pantalla.component';
import { PantallaComponent } from './pantalla/pantalla.component';
import { LimpiarBDCaballos } from './limpiarBD/caballos/limpiarBDCaballos.component';
import { LimpiarBDCaballosFEM } from './limpiarBD/caballos/limpiarBDCaballosFEM.component';
import { InscripcionesPendientesComponent } from './inscripciones-pendientes/inscripciones-pendientes.component';
import { InscripcionPendienteComponent } from './inscripcion-pendiente/inscripcion-pendiente.component';
import { ListasEntrenadorComponent } from './listas-entrenador/listas-entrenador.component';
import { MantenimientoClubesComponent } from './mantenimiento/mantenimiento-clubes/mantenimiento-clubes.component';
import { EntriesComponent } from './entries/entries.component';
import { ListaEditableComponent } from './lista-editable/lista-editable.component';
import { DayPlanComponent } from './day-plan/day-plan.component';

import { JineteService } from './services/jinete.service';
import { AppService } from './services/app.service';
import { CaballoService } from './services/caballo.service';
import { EntrenadorService } from './services/entrenador.service';
import { ResponsablesService } from './services/responsables.service';
import { ClubService } from './services/club.service';
import { PaisService } from './services/pais.service';
import { BinomioService } from './services/binomio.service';
import { PruebaService } from './services/prueba.service';
import { InscripcionesRemotasService } from './services/inscripcionesRemotas.service';
import { ListaService } from './services/lista.service';
import { ClassListService } from './services/class-list.service';
import { AuthService } from './services/auth.service';
import { ConcursoService } from './services/concurso.service';
import { ModalidadService } from './services/modalidad.service';
import { JuezService } from './services/juez.service';
import { CategoriaService } from './services/categoria.service';
import { SerieService } from './services/serie.service';
import { ResultadoService } from './services/resultado.service';
import { ConceptoService } from './services/concepto.service';
import { ContabilidadService } from './services/contabilidad.service';
import { ReportesService } from './services/reportes.service';
import { VideoService } from './services/videos.service';
import { UsuarioService } from './services/usuario.service';
import { PantallaService } from './services/pantalla.service';
import { StripeService } from './services/stripe.service';
import { MantenimientoService } from './services/mantenimiento.service';
import { CrearAnuncioComponent } from './crear-anuncio/crear-anuncio.component';
import { CuentaEntrenadorComponent } from './cuenta-entrenador/cuenta-entrenador.component';
import { EntriesService } from './services/entries.service';
import { PersonaService } from './services/persona.service';
import { ChaseService } from './services/chase.service';
import { FeedOrdersService } from './services/feed-orders.service';
import { PrintService } from './services/print.service';
import { UsefService } from './services/usef.service';
import { EmailService } from './services/email.service';

import { FilterClubPipe } from './pipes/club.pipe';
import { FilterPaisPipe } from './pipes/pais.pipe';
import { FilterJinetePipe } from './pipes/jinete.pipe';
import { FilterClientePipe } from './pipes/clientes.pipe'
import { FilterCaballoPipe } from './pipes/caballo.pipe';
import { FilterResultadosPipe } from './pipes/resultados.pipe';
import { FilterResponsablePipe } from './pipes/responsable.pipe';
import { FilterEntrenadorPipe } from './pipes/entrenador.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { FechaPipe } from './pipes/fecha.pipe';
import { TraduccionPipe } from './pipes/traduccion.pipe';
import { FilterJovenesPipe } from './pipes/jovenes.pipe';

import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { CalendarModule } from 'primeng/calendar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { OnlyNumber } from './only-number.directive';

//Inicializar firebase app
import firebase from 'firebase/compat/app';

import { ClassSheetsComponent } from './class-sheets/class-sheets.component';
import { FeedOrdersComponent } from './feed-orders/feed-orders.component';
import { FeedOrderComponent } from './feed-order/feed-order.component';
import { CopyEntriesComponent } from './copy-entries/copy-entries.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { EntryInvoiceComponent } from './entry-invoice/entry-invoice.component';
import { ListasOrdenManualComponent } from './listas-orden-manual/listas-orden-manual.component';
import { PrizesComponent } from './prizes/prizes.component';
import { FeiShowComponent } from './fei-show/fei-show.component';
import { FeiEntriesComponent } from './fei-entries/fei-entries.component';
import { PantallaGrandeComponent } from './pantalla-grande/pantalla-grande.component';
import { ValidarMasterListComponent } from './validar-master-list/validar-master-list.component';
import { MasterListComponent } from './master-list/master-list.component';
import { PrintClasesComponent } from './print-clases/print-clases.component';
import { PrintChecksComponent } from './print-checks/print-checks.component';
import { PrintChecksFormatComponent } from './print-checks-format/print-checks-format.component';
import { PruebasPaddockComponent } from './pruebas-paddock/pruebas-paddock.component';
import { OrderPaddockComponent } from './order-paddock/order-paddock.component';
import { MergeRidersManualComponent } from './merge-riders-manual/merge-riders-manual.component';
import { MergeHorsesManualComponent } from './merge-horses-manual/merge-horses-manual.component';
import { ProductsComponent } from './products/products.component';
import { HunterClassesComponent } from './hunter-classes/hunter-classes.component';
import { RidersDuplicatedComponent } from './riders-duplicated/riders-duplicated.component';
import { TrainerReservationsComponent } from './trainer-reservations/trainer-reservations.component';
import { TrainerReservationComponent } from './trainer-reservation/trainer-reservation.component';
import { HuntersResultsComponent } from './hunters-results/hunters-results.component';
import { DivisionsComponent } from './divisions/divisions.component';
import { DivisionComponent } from './division/division.component';
import { PrizeDistributionsComponent } from './prize-distributions/prize-distributions.component';
import { PrintDivisionsComponent } from './print-divisions/print-divisions.component';
import { UsersIngateComponent } from './users-ingate/users-ingate.component';
import { PrintClassSheetsComponent } from './print-class-sheets/print-class-sheets.component';
import { LeaderboardsComponent } from './leaderboards/leaderboards.component';
import { LocationGuideComponent } from './location-guide/location-guide.component';
import { DivisionChampionsComponent } from './division-champions/division-champions.component';
import { ViewIngateComponent } from './view-ingate/view-ingate.component';
import { UnassignedPaymentsComponent } from './unassigned-payments/unassigned-payments.component';
import { CopyShowComponent } from './copy-show/copy-show.component';
import { FormatoInscripcionesComponent } from './formato-inscripciones/formato-inscripciones.component';
import { DivisionChartsComponent } from './division-charts/division-charts.component';
import { JudgeCardsComponent } from './judge-cards/judge-cards.component';
import { DayPlanPrintComponent } from './day-plan-print/day-plan-print.component';
import { ResultadosFirestoreComponent } from './resultados-firestore/resultados-firestore.component';
import { HorsesDuplicatedComponent } from './horses-duplicated/horses-duplicated.component';
import { UsersOrdersComponent } from './users-orders/users-orders.component';
import { CheckFormComponent } from './check-form/check-form.component';
import { CcPaymentsComponent } from './cc-payments/cc-payments.component';
import { FederationEntryAgreementComponent } from './federation-entry-agreement/federation-entry-agreement.component';
import { StripeCardListComponent } from './stripe-card-list/stripe-card-list.component';
import { StripeAddCardComponent } from './forms/stripe-add-card/stripe-add-card.component';
import { ClassDivisionListComponent } from './class-division-list/class-division-list.component';
import { StripePaymentFormComponent } from './forms/stripe-payment-form/stripe-payment-form.component';
import { SettleCreditsComponent } from './forms/settle-credits/settle-credits.component';

import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { ImportResultsComponent } from './import-results/import-results.component';
import { DivisionChartComponent } from './division-chart/division-chart.component';
import { SplitClassComponent } from './forms/split-class/split-class.component';
import { MlsjComponent } from './mlsj/mlsj.component';
import { TermsComponent } from './terms/terms.component';
import { PaginationComponent } from './pagination/pagination.component';
import { httpInterceptorProviders } from './http-interceptors';
import { FormHorseComponent } from './forms/form-horse/form-horse.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CircuitsModule } from "../../libs/Angular/circuits/src/components/circuits.module";
import { ShowMessagesComponent } from '../../libs/Angular/show-messages/src/show-messages.component';
import { ClassFormModule } from './shared/components/class-form/class-form.module';
import { PaymentFormComponent } from './forms/payment-form/payment-form.component';
import { SeasonPassesComponent } from './season-passes/season-passes.component';

firebase.initializeApp({
  apiKey: "AIzaSyBFhMU1VwH3l1Hf4yGoQE6Qm3SEY9fIO0Y",
  authDomain: "ecuestredigital.firebaseapp.com",
  databaseURL: "https://ecuestredigital.firebaseio.com",
  projectId: "ecuestredigital",
  storageBucket: "ecuestredigital",
  messagingSenderId: "581815221685"
});

const ROUTES: Routes = [
  {
    path: '',
    component: InicioComponent,
    pathMatch: 'full'
  },
  {
    path: 'print/:idConcurso/:nombreReporte',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      {
        path: 'entry-invoice/:idConcurso/:entries',
        component: EntryInvoiceComponent
      }
    ]
  },
  {
    path: 'orders-of-go/:idConcurso/:classes/:mostrarInfoCaballo/:mostrarPaisJinete',
    outlet: 'print',
    component: PrintClasesComponent
  },
  {
    path: 'class-sheets/:idConcurso/:classes/:mostrarInfoCaballo/:mostrarPaisJinete',
    outlet: 'print',
    component: PrintClassSheetsComponent
  },
  {
    path: 'print-checks-format/:idConcurso/:entries',
    outlet: 'print',
    component: PrintChecksFormatComponent
  },
  {
    path: 'print/:idConcurso/:nombreReporte',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      {
        path: 'print-divisions-format/:idConcurso/:divisions',
        component: PrintDivisionsComponent
      }
    ]
  },
  {
    path: 'resultados-firestore',
    component: ResultadosFirestoreComponent,
    pathMatch: 'full'
  },
  {
    path: 'reports',
    component: ReportesComponent,
    pathMatch: 'full'
  },
  {
    path: 'perfil/:id_cliente',
    component: PerfilClienteComponent,
    pathMatch: 'full'
  },
  {
    path: 'pago-de-videos',
    component: PagosSinAsignarComponent,
    pathMatch: 'full'
  },
  {
    path: 'sistema-videos',
    component: VideosComponent,
    pathMatch: 'full'
  },
  {
    path: 'clientes-videos',
    component: ClientesVideosComponent,
    pathMatch: 'full'
  },
  {
    path: 'peticiones',
    component: PeticionesVideosComponent,
    pathMatch: 'full'
  },
  {
    path: 'people',
    component: DirectorioPersonasComponent,
    pathMatch: 'full'
  },
  {
    path: 'horses',
    component: DirectorioCaballosComponent,
    pathMatch: 'full'
  },
  {
    path: 'clubes',
    component: CrearClubComponent,
    pathMatch: 'full'
  },
  {
    path: 'inscripciones-remotas/:idConcurso/:idUsuario',
    component: InscripcionesRemotasComponent,
    pathMatch: 'full'
  },
  {
    path: 'importar-listas',
    component: ImportarListasComponent,
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'crear-concurso',
    component: CrearConcursosComponent,
    pathMatch: 'full'
  },
  {
    path: 'convocatoria',
    component: ConvocatoriaComponent,
    pathMatch: 'full'
  },
  {
    path: 'show-info',
    component: ConvocatoriaComponent,
    pathMatch: 'full'
  },
  {
    path: 'resultados',
    component: ResultadosComponent,
    pathMatch: 'full'
  },
  /*{
      path: 'adds/:idConcurso',
      component: AddsComponent,
      pathMatch: 'full'
  },*/
  {
    path: 'billing',
    component: CajaComponent,
    pathMatch: 'full'
  },
  {
    path: 'feed-orders',
    component: FeedOrdersComponent,
    pathMatch: 'full'
  },
  {
    path: 'caja/:idResponsable',
    component: CajaComponent,
    pathMatch: 'full'
  },
  {
    path: 'accounting',
    component: ContabilidadComponent,
    pathMatch: 'full'
  },
  {
    path: 'pase/:idPase',
    component: PaseComponent,
    pathMatch: 'full'
  },
  {
    path: 'executive-summary',
    component: ResumenEjecutivoComponent,
    pathMatch: 'full'
  },
  {
    path: 'listas',
    component: ListasComponent,
    pathMatch: 'full'
  },
  {
    path: 'class-division-list',
    component: ClassDivisionListComponent,
    pathMatch: 'full'
  },
  {
    path: 'manually-sorted-orders-of-go',
    component: ListasOrdenManualComponent,
    pathMatch: 'full'
  },
  {
    path: 'class-sheets',
    component: ClassSheetsComponent,
    pathMatch: 'full'
  },
  {
    path: 'usuarios',
    component: UsuariosComponent,
    pathMatch: 'full'
  },
  {
    path: 'users-ingate',
    component: UsersIngateComponent,
    pathMatch: 'full'
  },
  {
    path: 'users-orders',
    component: UsersOrdersComponent,
    pathMatch: 'full'
  },
  {
    path: 'usuarios-online',
    component: CrearUsuarioInscripcionesComponent,
    pathMatch: 'full'
  },
  {
    path: 'pendientes-por-vincular',
    component: VincularUsuarioInscripcionesComponent,
    pathMatch: 'full'
  },
  {
    path: 'admin-inscripciones-remotas',
    component: GestionUsuariosInscripcionesComponent,
    pathMatch: 'full'
  },
  {
    path: 'pantallas',
    component: PantallasComponent,
    pathMatch: 'full'
  },
  {
    path: 'pantalla/control/:idConcurso/:idPantalla',
    component: ControlPantallaComponent,
    pathMatch: 'full'
  },
  {
    path: 'pantalla/:idConcurso/:idPantalla',
    component: PantallaGrandeComponent,
    pathMatch: 'full'
  },
  {
    path: 'pantalla/youtube/:idConcurso/:idPantalla',
    component: PantallaComponent,
    pathMatch: 'full'
  },
  {
    path: 'merge-riders-manual',
    component: MergeRidersManualComponent,
    pathMatch: 'full'
  },
  {
    path: 'merge-horses-manual',
    component: MergeHorsesManualComponent,
    pathMatch: 'full'
  },
  {
    path: 'limpiarBD/caballos',
    component: LimpiarBDCaballos,
    pathMatch: 'full'
  },
  {
    path: 'online-entries',
    component: InscripcionesPendientesComponent,
    pathMatch: 'full'
  },
  {
    path: 'inscripciones-pendientes/:idSolicitud',
    component: InscripcionPendienteComponent,
    pathMatch: 'full'
  },
  {
    path: 'federation-entry-agreement/:idConcurso/:entry',
    component: FederationEntryAgreementComponent,
    pathMatch: 'full'
  },
  {
    path: 'federation-entry-agreement/:idConcurso/:entry/:rider',
    component: FederationEntryAgreementComponent,
    pathMatch: 'full'
  },
  {
    path: 'listas-entrenador',
    component: ListasEntrenadorComponent,
    pathMatch: 'full'
  },
  {
    path: 'mantenimiento-clubes',
    component: MantenimientoClubesComponent,
    pathMatch: 'full'
  },
  {
    path: 'create-announcement',
    component: CrearAnuncioComponent,
    pathMatch: 'full'
  },
  {
    path: 'trainer-account',
    component: CuentaEntrenadorComponent,
    pathMatch: 'full'
  },
  {
    path: 'trainer-account/:idEntrenador',
    component: CuentaEntrenadorComponent,
    pathMatch: 'full'
  },
  {
    path: 'lista-editable',
    component: ListaEditableComponent,
    pathMatch: 'full'
  },
  {
    path: 'entries',
    component: EntriesComponent,
    pathMatch: 'full'
  },
  {
    path: 'entries/:entry',
    component: EntriesComponent,
    pathMatch: 'full'
  },
  {
    path: 'copy-entries',
    component: CopyEntriesComponent,
    pathMatch: 'full'
  },
  {
    path: 'prizes',
    component: PrizesComponent,
    pathMatch: 'full'
  },
  {
    path: 'fei-show',
    component: FeiShowComponent,
    pathMatch: 'full'
  },
  {
    path: 'division-champions',
    component: DivisionChampionsComponent,
    pathMatch: 'full'
  },
  {
    path: 'fei-entries',
    component: FeiEntriesComponent,
    pathMatch: 'full'
  },
  {
    path: 'validar-master-list',
    component: ValidarMasterListComponent,
    pathMatch: 'full'
  },
  {
    path: 'master-list',
    component: MasterListComponent,
    pathMatch: 'full'
  },
  {
    path: 'print-checks',
    component: PrintChecksComponent,
    pathMatch: 'full'
  },
  {
    path: 'paddock',
    component: PruebasPaddockComponent,
    pathMatch: 'full'
  },
  {
    path: 'order-paddock/:ipc',
    component: OrderPaddockComponent,
    pathMatch: 'full'
  },
  {
    path: 'products',
    component: ProductsComponent,
    pathMatch: 'full'
  },
  {
    path: 'class-planner',
    component: HunterClassesComponent,
    pathMatch: 'full'
  },
  {
    path: 'merge-riders',
    component: RidersDuplicatedComponent,
    pathMatch: 'full'
  },
  {
    path: 'merge-horses',
    component: HorsesDuplicatedComponent,
    pathMatch: 'full'
  },
  {
    path: 'trainer-reservations',
    component: TrainerReservationsComponent,
    pathMatch: 'full'
  },
  {
    path: 'trainer-reservation/:id',
    component: TrainerReservationComponent,
    pathMatch: 'full'
  },
  {
    path: 'results',
    component: HuntersResultsComponent,
    pathMatch: 'full'
  },
  {
    path: 'divisions',
    component: DivisionsComponent,
    pathMatch: 'full'
  },
  {
    path: 'division/:id',
    component: DivisionComponent,
    pathMatch: 'full'
  },
  {
    path: 'division/:id/:accion',
    component: DivisionComponent,
    pathMatch: 'full'
  },
  {
    path: 'prize-distributions',
    component: PrizeDistributionsComponent,
    pathMatch: 'full'
  },
  {
    path: 'leaderboards',
    component: LeaderboardsComponent,
    pathMatch: 'full'
  },
  {
    path: 'location-guide',
    component: LocationGuideComponent,
    pathMatch: 'full'
  },
  {
    path: 'view-ingate',
    component: ViewIngateComponent,
    pathMatch: 'full'
  },
  {
    path: 'unassigned-payments',
    component: UnassignedPaymentsComponent,
    pathMatch: 'full'
  },
  {
    path: 'day-plan',
    component: DayPlanComponent,
    pathMatch: 'full'
  },
  {
    path: 'day-plan-print/:dia',
    component: DayPlanPrintComponent,
    pathMatch: 'full'
  },
  {
    path: 'copy-show',
    component: CopyShowComponent,
    pathMatch: 'full'
  },
  {
    path: 'formato-inscripciones/:ids',
    component: FormatoInscripcionesComponent,
    pathMatch: 'full'
  },
  {
    path: 'divisionCharts/:id',
    component: DivisionChartsComponent,
    pathMatch: 'full'
  },
  {
    path: 'split-class',
    component: SplitClassComponent,
    pathMatch: 'full'
  },
  { path: 'sign-entry-request/:show/:entry/:type', loadChildren: () => import('./sign-entry-request/sign-entry-request.module').then(m => m.SignEntryRequestModule) },
  {
    path: 'judgeCards',
    component: JudgeCardsComponent,
    pathMatch: 'full'
  },
  {
    path: 'cc-payments',
    component: CcPaymentsComponent,
    pathMatch: 'full'
  },
  {
    path: 'mlsj',
    component: MlsjComponent,
    pathMatch: 'full'
  },
  {
    path: 'terms',
    component: TermsComponent,
    pathMatch: 'full'
  },
  {
    path: 'seasonPasses',
    component: SeasonPassesComponent,
    data: { title: 'Season Passes' }
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

registerLocaleData(localeEn);

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    DirectorioPersonasComponent,
    DirectorioCaballosComponent,
    CrearClubComponent,
    InscripcionesRemotasComponent,
    ImportarListasComponent,
    MenuLateralComponent,
    LoginComponent,
    InicioComponent,
    CrearConcursosComponent,
    ConvocatoriaComponent,
    ResultadosComponent,
    DayPlanComponent,
    CajaComponent,
    FilterClubPipe,
    FilterPaisPipe,
    FilterJinetePipe,
    FilterCaballoPipe,
    FilterResultadosPipe,
    FilterResponsablePipe,
    FilterEntrenadorPipe,
    FilterClientePipe,
    KeysPipe,
    FechaPipe,
    TraduccionPipe,
    FilterJovenesPipe,
    ContabilidadComponent,
    ReportesComponent,
    PaseComponent,
    ResumenEjecutivoComponent,
    ListasComponent,
    UsuariosComponent,
    VideosComponent,
    MenuVideosComponent,
    PeticionesVideosComponent,
    ClientesVideosComponent,
    PerfilClienteComponent,
    PagosSinAsignarComponent,
    CrearUsuarioInscripcionesComponent,
    VincularUsuarioInscripcionesComponent,
    GestionUsuariosInscripcionesComponent,
    PantallasComponent,
    ControlPantallaComponent,
    PantallaComponent,
    LimpiarBDCaballos,
    LimpiarBDCaballosFEM,
    InscripcionesPendientesComponent,
    InscripcionPendienteComponent,
    InscripcionPendienteComponent,
    ListasEntrenadorComponent,
    MantenimientoClubesComponent,
    CrearAnuncioComponent,
    OnlyNumber,
    CrearAnuncioComponent,
    EntriesComponent,
    ListaEditableComponent,
    CuentaEntrenadorComponent,
    ClassSheetsComponent,
    FeedOrdersComponent,
    FeedOrderComponent,
    CopyEntriesComponent,
    PrintLayoutComponent,
    EntryInvoiceComponent,
    ListasOrdenManualComponent,
    PrizesComponent,
    FeiShowComponent,
    FeiEntriesComponent,
    PantallaGrandeComponent,
    ValidarMasterListComponent,
    MasterListComponent,
    PrintClasesComponent,
    PrintChecksComponent,
    PrintChecksFormatComponent,
    PruebasPaddockComponent,
    OrderPaddockComponent,
    MergeRidersManualComponent,
    MergeHorsesManualComponent,
    ProductsComponent,
    HunterClassesComponent,
    RidersDuplicatedComponent,
    TrainerReservationsComponent,
    TrainerReservationComponent,
    HuntersResultsComponent,
    DivisionsComponent,
    DivisionComponent,
    PrizeDistributionsComponent,
    PrintDivisionsComponent,
    UsersIngateComponent,
    PrintClassSheetsComponent,
    LeaderboardsComponent,
    LocationGuideComponent,
    DivisionChampionsComponent,
    ViewIngateComponent,
    UnassignedPaymentsComponent,
    CopyShowComponent,
    FormatoInscripcionesComponent,
    DivisionChartsComponent,
    JudgeCardsComponent,
    DayPlanPrintComponent,
    ResultadosFirestoreComponent,
    HorsesDuplicatedComponent,
    UsersOrdersComponent,
    CheckFormComponent,
    CcPaymentsComponent,
    FederationEntryAgreementComponent,
    StripeCardListComponent,
    StripeAddCardComponent,
    ClassDivisionListComponent,
    StripePaymentFormComponent,
    SettleCreditsComponent,
    ImportResultsComponent,
    DivisionChartComponent,
    SplitClassComponent,
    MlsjComponent,
    TermsComponent,
    PaginationComponent,
    FormHorseComponent,
    LoaderComponent,
    SeasonPassesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES),
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatCardModule,
    MatButtonToggleModule,
    HttpClientModule,
    MatCheckboxModule,
    CalendarModule,
    MatTabsModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    DragDropModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    provideFirebaseApp(() => initializeApp({
      apiKey: "AIzaSyBFhMU1VwH3l1Hf4yGoQE6Qm3SEY9fIO0Y",
      authDomain: "ecuestredigital.firebaseapp.com",
      databaseURL: "https://ecuestredigital.firebaseio.com",
      projectId: "ecuestredigital",
      storageBucket: "ecuestredigital",
      messagingSenderId: "581815221685"
    })),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    Sentry.TraceModule,
    // SignaturePadModule
    PaymentFormComponent,
    MatDialogModule,
    CircuitsModule,
    ShowMessagesComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
    httpInterceptorProviders,
    JineteService,
    ClubService,
    CaballoService,
    ResponsablesService,
    EntrenadorService,
    PaisService,
    BinomioService,
    PruebaService,
    InscripcionesRemotasService,
    ListaService,
    ClassListService,
    AuthService,
    ConcursoService,
    ModalidadService,
    JuezService,
    CategoriaService,
    SerieService,
    ResultadoService,
    ConceptoService,
    ContabilidadService,
    ReportesService,
    VideoService,
    UsuarioService,
    PantallaService,
    StripeService,
    MantenimientoService,
    AppService,
    EntriesService,
    PersonaService,
    ChaseService,
    FeedOrdersService,
    PrintService,
    UsefService,
    EmailService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

/*
    "i18nMissingTranslation": "error"
    en angular.json configurations->en para que turne si falta una traduccion
*/
