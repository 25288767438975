import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PruebaService } from '../services/prueba.service';
import { ModalidadService } from '../services/modalidad.service';
import { ConceptoService } from '../services/concepto.service';
import { ConcursoService } from '../services/concurso.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '../services/app.service';
import { PersonaService } from '../services/persona.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { JuezService } from '../services/juez.service';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ClassFormDialogComponent } from '../shared/components/class-form/class-form-dialog.component';
declare let $: any;

@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.css']
})
export class DivisionComponent implements OnInit, AfterViewInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public id: string;
  public classes: any[];
  public form: FormGroup;
  public pruebas: any[];
  public mostrarFormularioPruebas = false;
  public formPrueba: FormGroup;
  public modalidades: any[];
  public cargos: any[];
  public conceptos: any[];
  public conceptoSeleccionado: string;
  public porcentajeSubida: number;
  public imgPatrocinador: string;
  public croquis: string;
  public ipcSeleccionado: string;
  public ipcEliminar: string;
  public agregarPrueba: boolean;
  public header: string;
  public header2: string;
  public footer: string;
  public distribuciones: any[];
  public pistas: string[];
  public actualizarPruebasConcurso: boolean;
  public personas: any[];
  public jueces: Array<any>;
  public esHunters: string;
  public reining: boolean;
  public concurso: any;
  public riderCategories: FormControl;
  public ecSectionCodes = [
    { code: "29000043", description: "4 YR Old Jumper 1.10M - 1.20M" },
    { code: "29000044", description: "5 YR Old Jumper 1.20M - 1.30M" },
    { code: "29000045", description: "6 YR Old Jumper 1.30M - 1.40M" },
    { code: "29000071", description: "7/8 YR Old Jumper 1.40M - 1.50M" },
    { code: "29000050", description: "Adult Amateur Hunter 3' (0.90M)" },
    { code: "29000052", description: "Amateur Owner Hunter 3' (0.90M)" },
    { code: "20011200", description: "Amateur Owner Hunter 3' 6\" (1.10M)" },
    { code: "31000143", description: "Canadian Hunter Derby Series 3'0" },
    { code: "29000048", description: "Canadian Hunter Derby Series 3'6" },
    { code: "29000008", description: "Low Hunter" },
    { code: "29000010", description: "Combined Working Hunter " },
    { code: "29000022", description: "Children's Pony" },
    { code: "31000136", description: "Junior/Amateur Jumper 0.90M" },
    { code: "29000032", description: "CET Medal" },
    { code: "29000033", description: "CET Mini Medal" },
    { code: "31000159", description: "CHILD/AA/AO 3'0\" Hunter Combined" },
    { code: "20010800", description: "Children's Hunter 3' (0.90M)" },
    { code: "29000026", description: "Equitation A (16 until 18 years of age)" },
    { code: "31000157", description: "Equitation A,B,C Combined" },
    { code: "29000027", description: "Equitation B (13 until 15 years of age)" },
    { code: "29000028", description: "Equitation C (12 years of age)" },
    { code: "29000046", description: "FEI" },
    { code: "29000110", description: "Green & Regular Hunter Combined" },
    { code: "31000155", description: "Green Hunter - 1st and 2nd Year Combined" },
    { code: "20010200", description: "Green Hunter - 1st Year -  3' 6\" (1.10M)" },
    { code: "20010300", description: "Green Hunter - 2nd Year - 3' 9\" (1.15M)" },
    { code: "29000228", description: "Green Pony Hunter - Large 2'9\"" },
    { code: "29000227", description: "Green Pony Hunter - Medium 2'6\"" },
    { code: "29000226", description: "Green Pony Hunter - Small  2'3\"" },
    { code: "29000025", description: "Green Pony Hunter Combined" },
    { code: "29000031", description: "JC Medal" },
    { code: "20010900", description: "Junior Hunter 3' 6\" (1.10M)" },
    { code: "20011333", description: "Junior/Amateur Hunter 3' 3\" (1.00M)" },
    { code: "29000056", description: "Junior/Amateur Jumper 1.00M" },
    { code: "29000057", description: "Junior/Amateur Jumper 1.10M" },
    { code: "29000058", description: "Junior/Amateur Jumper 1.20M" },
    { code: "29000059", description: "Junior/Amateur Jumper 1.30M" },
    { code: "29000060", description: "Junior/Amateur Jumper 1.40M" },
    { code: "31000137", description: "Junior/Amateur Owner Hunter 3' 6\" (1.10M)" },
    { code: "29000029", description: "Miscellaneous Equitation" },
    { code: "29000041", description: "Miscellaneous Hunter" },
    { code: "29000042", description: "Miscellaneous Jumper" },
    { code: "29000061", description: "Open Jumper 1.0M" },
    { code: "29000062", description: "Open Jumper 1.10M" },
    { code: "29000063", description: "Open Jumper 1.15M" },
    { code: "29000064", description: "Open Jumper 1.20M" },
    { code: "29000065", description: "Open Jumper 1.25M" },
    { code: "29000066", description: "Open Jumper 1.30M" },
    { code: "29000067", description: "Open Jumper 1.35M" },
    { code: "29000068", description: "Open Jumper 1.40M" },
    { code: "29000039", description: "Open Jumper 1.45M/1.60M" },
    { code: "31000147", description: "Pleasure Hack" },
    { code: "29000020", description: "Pony Hunter - Large 2'9\" (0.85M)" },
    { code: "29000019", description: "Pony Hunter - Medium 2'6\" (0.75M)" },
    { code: "29000018", description: "Pony Hunter - Small 2'3\" (0.70M)" },
    { code: "21111100", description: "Pony Jumpers 1.0M" },
    { code: "29000003", description: "Pre Green Hunter - 1st Year - 3' (0.90M)" },
    { code: "31000146", description: "Pre Green Hunter - 2nd Year - 3' 3\" (1.00M)" },
    { code: "29000004", description: "Pre Green Hunter Combined" },
    { code: "20011500", description: "Regular Hunter 4' (1.20M)" },
    { code: "29000021", description: "Regular Pony Hunter Combined" },
    { code: "31000156", description: "Regular/Green Pony Hunter Combined" },
    { code: "31000148", description: "Road Hack" },
    { code: "31000149", description: "Show Hack" }
  ];

  filteredEcCodes: Observable<any[]>;
  filteredPruebaEcCodes: Observable<any[]>;
  selectedClass: any = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private pruebaService: PruebaService,
    private modalidadService: ModalidadService,
    private conceptoService: ConceptoService,
    private appService: AppService,
    private concursoService: ConcursoService,
    private personaService: PersonaService,
    private db: AngularFirestore,
    private juezService: JuezService,
    private dialog: MatDialog
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.id = this.route.snapshot.paramMap.get('id');
    this.agregarPrueba = !!this.route.snapshot.paramMap.get('accion');
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      cost: new FormControl('', [Validators.required]),
      usef_section_code: new FormControl('', []),
      ec_section_code: new FormControl('', []), 
      type: new FormControl('hunters', [Validators.required]),
      charged_by: new FormControl('class-fees', [Validators.required]),
      // categories: new FormControl([])
    });
    this.pruebas = [];
    this.mostrarFormularioPruebas = false;
    this.formPrueba = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      numero_prueba: new FormControl('', [Validators.required]),
      description: new FormControl('', []),
      modalidad: new FormControl('', [Validators.required]),
      altura: new FormControl('', []),
      pista: new FormControl('', []),
      costo: new FormControl('', []),
      premio: new FormControl('', []),
      prize_distribution: new FormControl('', []),
      inicio: new FormControl('', []),
      juez: new FormControl('', []),
      video: new FormControl(false, []),
      designer: new FormControl('', []),
      youtube: new FormControl('', []),
      fei_competition_id: new FormControl('', []),
      fei_event_id: new FormControl('', []),
      usef_section: new FormControl('', []),
      ec_section: new FormControl('', []),
      hard_start: new FormControl(false, []),
      overall: new FormControl(false, []),
      height_unit: new FormControl('M', []),
      categories: new FormControl([], [])
    });
    this.modalidades = [];
    this.cargos = [];
    this.conceptos = [];
    this.conceptoSeleccionado = '';
    this.porcentajeSubida = 0;
    this.imgPatrocinador = '';
    this.croquis = '';
    this.ipcSeleccionado = '';
    this.ipcEliminar = '';
    this.header = '';
    this.header2 = '';
    this.footer = '';
    this.distribuciones = [];
    this.pistas = [];
    this.actualizarPruebasConcurso = false;
    this.personas = [];
    this.jueces = [];
    this.esHunters = "0";
    this.reining = sessionStorage.getItem('reining') == '1';
    this.riderCategories = new FormControl([]);

    this.filteredEcCodes = this.form.get('ec_section_code').valueChanges.pipe(
      startWith(''),
      map(value => this._filterEcCodes(value))
    );

    this.filteredPruebaEcCodes = this.formPrueba.get('ec_section').valueChanges.pipe(
      startWith(''),
      map(value => this._filterEcCodes(value))
    );
  }

  ngAfterViewInit(): void {
    if (this.agregarPrueba) {
      setTimeout(() => {
        this.mostrarAgregarPrueba();
      });
    }
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getDivision();
    this.getClasses();
    this.getConceptos();
    this.getConcurso();
    this.getDistributions();
    this.getPistas();
    this.getJueces();
  }

  public getJueces() {
    this.juezService.getJueces().subscribe(
      response => {
        if (!response.error) {
          this.jueces = response.jueces;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the judges list', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getPistas(): void {
    this.concursoService.getPistas(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.pistas = response.pistas;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the rings list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getDistributions(): void {
    this.pruebaService.getPrizeDistributions(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.distribuciones = [];
          for (let distribucion of response.distribuciones) {
            if (distribucion.tipo == 'percentage') {
              distribucion.distribucion = distribucion.distribucion.map(d => d * 100);
              this.distribuciones.push(distribucion);
            } else {
              this.distribuciones.push(distribucion);
            }
          }
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to save the prize distribution.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getConcurso() {
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.header = response.concurso.header;
          this.header2 = response.concurso.header2;
          this.footer = response.concurso.footer;
          this.concurso = response.concurso;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the show info.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getDivision(): void {
    this.pruebaService.getDivision(this.id, this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.pruebas = response.division.pruebas;
          this.form.setValue({
            name: response.division.name,
            code: response.division.code,
            cost: response.division.cost,
            type: response.division.type,
            usef_section_code: response.division.usef_section_code,
            charged_by: response.division.charged_by,
            ec_section_code: response.division.ec_section_code,
            // categories: response.division.categories ? JSON.parse(response.division.categories) : []
          });
          this.esHunters = "0";
          if (this.form.value.type == 'hunters') {this.esHunters = "1"}
          this.getModalidades();
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the division info.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        $('#loader').hide();
      }
    );
    $('#loader').hide();
  }

  public getClasses(): void {
    this.pruebaService.getPruebasList(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.classes = response.pruebas;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the classes list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getModalidades(): void {
    this.modalidadService.getModalidades().subscribe(
      response => {
        if (!response.error) {
          this.modalidades = response.modalidades.filter((item) => item.hunters == this.esHunters);
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the tables list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  private getConceptos(): void {
    this.conceptoService.getConceptos(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.conceptos = response.conceptos;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the concepts list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public addClass(e): void {
    const num = e.target.value;
    if (num) {
      const prueba = this.classes.find(c => c.numero_prueba == num);
      if (prueba) {
        this.pruebas.push({
          ipc: prueba.ipc,
          numero_prueba: prueba.numero_prueba,
          nombre: prueba.nombre,
          altura: prueba.altura
        });
        e.target.value = '';
      } else {
        e.target.value = '';
        $.NotificationApp.send("Error", "There is no class with that number.", 'bottom-right', '#fa5c7c', 'error', 10000);
      }
    }
  }

  public modalRemoveClass(ipc) {
    this.ipcEliminar = ipc;
    $('#modal-eliminar-prueba').modal('show');
  }

  public async deleteClass() {
    this.pruebaService.deletePrueba(this.ipcEliminar, this.idUsuario, this.idConcurso).subscribe(
      async (response) => {
        if (!response.error) {
          await this.db.collection('concursos').doc(`us_${this.idConcurso}`).collection('pruebas').doc(`us_${this.ipcEliminar}`).delete();
          const indexClasses = this.classes.findIndex(c => c.ipc == this.ipcEliminar);
          const indexPruebas = this.pruebas.findIndex(p => p.ipc == this.ipcEliminar);
          if (indexClasses > -1) this.classes.splice(indexClasses, 1);
          if (indexPruebas > -1) this.pruebas.splice(indexPruebas, 1);
          $('#modal-eliminar-prueba').modal('hide');
          $.NotificationApp.send("Success", 'The class has been successfully deleted', 'bottom-right', '#3c763d', 'success');
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to update the division.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public updateDivision() {
    if (this.form.valid) {
      const formData = this.form.value;
      //formData.categories = formData.categories?.length ? JSON.stringify(formData.categories) : null;
      
      $('#loader').show();
      this.pruebaService.updateDivisionNest(formData, this.id, this.pruebas).subscribe(
        response => {
          if (!response.error) {
            $.NotificationApp.send("Success", 'The division has been successfully updated', 'bottom-right', '#3c763d', 'success');
            $('#loader').hide();
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
            $('#loader').hide();
          }
        },
        error => {
          $.NotificationApp.send("Error", "It has not been possible to update the division.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
          $('#loader').hide();
        }
      );
    }
  }

  public filtroConceptosSinAgregar(): any[] {
    const cargos = this.cargos.map(c => c.id);
    return this.conceptos.filter(c => cargos.indexOf(c.id) === -1);
  }

  public agregarCargo(): void {
    if (this.conceptoSeleccionado) {
      const concepto = this.conceptos.find(c => c.id == this.conceptoSeleccionado);
      this.cargos.push({
        id: concepto.id,
        name: concepto.nombre
      });
      this.conceptoSeleccionado = '';
    }
  }

  public removeCharge(id): void {
    const index = this.cargos.findIndex(c => c.id == id);
    this.cargos.splice(index, 1);
  }

  public imagenPatrocinador(event) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      event.target.value = '';
      if (file.type === 'image/jpeg') {
        $('#loader').show();

        const ref = firebase.storage().ref('sponsor').child(`${new Date().getTime()}`);
        const task = ref.put(file);
        task.on('state_changed',
          (snapshot: any) => {
            this.porcentajeSubida = (snapshot.bytesTransferred / snapshot.totalBytes);
          },
          (error: any) => {
            $('#loader').hide();
            $.NotificationApp.send("Error", error.code, 'bottom-right', '#ffbc00', 'error');
            console.log(error);
          },
          () => {
            task.snapshot.ref.getDownloadURL().then(
              downloadURL => {
                $('#loader').hide();
                this.imgPatrocinador = downloadURL;
              },
              error => {
                $('#loader').hide();
                $.NotificationApp.send("Error", 'The download url could not be obtained.', 'bottom-right', '#ffbc00', 'error');
                console.log(error);
              }
            );
          }
        );
      } else {
        $.NotificationApp.send("Invalid file.", 'This type of file is not allowed, please try with a JPG image.', 'bottom-right', '#ffbc00', 'warning');
      }
    }
  }

  public archivoCroquis(event) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      event.target.value = '';
      $('#loader').show();
      const ref = firebase.storage().ref('croquis').child(`${new Date().getTime()}`);
      const task = ref.put(file);
      task.on('state_changed',
        (snapshot: any) => {
          this.porcentajeSubida = (snapshot.bytesTransferred / snapshot.totalBytes);
        },
        (error: any) => {
          $('#loader').hide();
          $.NotificationApp.send("Error", error.code, 'bottom-right', '#ffbc00', 'error');
          console.log(error);
        },
        () => {
          task.snapshot.ref.getDownloadURL().then(
            downloadURL => {
              $('#loader').hide();
              this.croquis = downloadURL;
            },
            error => {
              $('#loader').hide();
              $.NotificationApp.send("Error", 'The download url could not be obtained.', 'bottom-right', '#ffbc00', 'error');
              console.log(error);
            }
          );
        }
      );
    }
  }

  public mostrarAgregarPrueba() {
    console.log('mostrarAgregarPrueba: ', this.id);
    const dialogRef = this.dialog.open(ClassFormDialogComponent, {
      width: '800px',
      data: {
        concursoId: this.idConcurso,
        divisionId: this.id
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.getDivision();
      }
    });
  }

  public editClass(classId: string) {
    const dialogRef = this.dialog.open(ClassFormDialogComponent, {
      width: '800px',
      data: { 
        classId,
        concursoId: this.idConcurso,
        divisionId: this.id
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.getDivision();
      }
    });
  }

  public async duplicateClass(ipc: string, numero_prueba  : string){
    const lastClassSameNumber = this.pruebas.filter(p => {
      const regex = new RegExp(`^${numero_prueba.replace(/[A-Za-z]/g,'')}[A-Za-z]?$`);
      return regex.test(p.numero_prueba);
    }).sort((a, b) => {
      let charCodeA = a.numero_prueba.replace(/\d/g,'').charCodeAt(0);
      let charCodeB = b.numero_prueba.replace(/\d/g,'').charCodeAt(0);
      if(isNaN(charCodeA)) charCodeA = 0;
      if(isNaN(charCodeB)) charCodeB = 0;

      if(charCodeA == charCodeB) return 0;

      return charCodeA > charCodeB ? -1 : 1;
    }).shift();
    const charCode = lastClassSameNumber.numero_prueba.replace(/\d/g,'').charCodeAt(0);
    let letra;
    if(isNaN(charCode)) letra = 'A';
    else letra = String.fromCharCode(charCode+1);
    const response = await this.pruebaService.getPrueba(ipc).toPromise();
    if(response.error){
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    console.log(response);
    const prueba = {
      altura: response.prueba.altura||'',
      costo: response.prueba.costo||'',
      designer: response.prueba.designer_id||'',
      inicio: '',
      juez: response.prueba.juez||'',
      modalidad: response.prueba.idModalidad||'',
      nombre: response.prueba.nombre||'',
      nombreModalidad: response.prueba.nombreModalidad||'',
      numero: `${response?.prueba?.numero?.replace(/[A-Za-z]/g,'')}${letra}`,
      description: response.prueba.description||'',
      pista: response.prueba.pista||'',
      premio: response.prueba.premio||'0',
      video: response.prueba.video ? 1 : 0,
      youtube: '',
      imgPatrocinador: response.prueba.sponsorImage||'',
      fei_competition_id: '',
      fei_event_id: response.prueba.eventId||'',
      usef_section: response.prueba.usef_section||'',
      ec_section: response.prueba.ec_section||'',
      hard_start: response.prueba.hard_start ? 1 : 0,
      overall: response.prueba.overall ? 1 : 0,
      croquis: '',
      cargosPrueba: response.prueba.charges||[],
      prize_distribution: response.prueba.prize_distribution||'',
      division: this.id,
      height_unit: response.prueba.height_unit||'',
      categories: response.prueba.categories ? JSON.parse(response.prueba.categories) : []
    };

    const responseCreateClass = await this.pruebaService.createPrueba(prueba, this.idConcurso).toPromise();
    if(responseCreateClass.error){
      $.NotificationApp.send("Error", responseCreateClass.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    await this.getDivision();
    await this.getClasses();
    $.NotificationApp.send("Success", `The class ${prueba.numero} has been successfully created.`, 'bottom-right', '#06d5a1', 'success');
  }

  public cambioPista() {
    console.log('cambioPista');
    this.actualizarPruebasConcurso = true;
  }

  public getPersonas(e, entidad) {
    const filtro: string = e.target.value;
    if (filtro.length > 3) {
      this.personaService.getPersonasFiltradas(filtro, this.idConcurso).subscribe(
        response => {
          if (!response.error) {
            this.personas = response.personas;
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $.NotificationApp.send("Error", 'It has not been possible to query the designers, please try again.', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    } else {
      console.log('Busqueda de personas muy corta');
      this.personas = [];
    }
  }

  public displayPersona(idPersona): string {
    const designer = this.personas.find(p => p.id_persona == idPersona);

    return designer ? designer.fullname : idPersona;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event) {
    console.log(event);
    if (event.ctrlKey == true && event.key == 'q') {
      event.preventDefault();
      console.log("New Class");
      this.mostrarAgregarPrueba();
    }
    if (event.ctrlKey == true && event.key == 'Q') {
      event.preventDefault();
      console.log("New Class");
      this.mostrarAgregarPrueba();
    }
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getEcSectionDescription(code: string): string {
    return this.ecSectionCodes.find(c => c.code === code)?.description || code;
  }

  private _filterEcCodes(value: string): any[] {
    const filterValue = value?.toLowerCase() || '';
    return this.ecSectionCodes.filter(code => 
      code.code.toLowerCase().includes(filterValue) || 
      code.description.toLowerCase().includes(filterValue)
    );
  }

  displayEcCode(code: any): string {
    if (code) {
      const found = this.ecSectionCodes.find(c => c.code === code);
      return found ? `${found.code} - ${found.description}` : code;
    }
    return '';
  }

  onClassSave(classData: any) {
    // Handle save
  }

  onClassCancel() {
    this.selectedClass = null;
  }

  onClassImageUpload(event: {type: 'sponsor' | 'course', file: File}) {
    // Handle image upload
  }


}
