import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class ConcursoService extends Api {

    constructor(private http: HttpClient, private authService: AuthService) {
        super();
    }

    public getConcursos(idConcurso: any = ''): Observable<any> {
        const params = JSON.stringify({ idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/`, params, { headers: headers });
    }

    public getConcursoNest(idConcurso: string | number | boolean): Observable<any> {
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8',
            'authorization': 'Bearer ' + this.authService.getAuthorizationToken() || '',
        };

        return this.http.get(`${environment.api.nest.uri}concursos/${idConcurso}`, { headers: headers });
    }

    
    public getDivisionsNest(idConcurso: string | number | boolean): Observable<any> {
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8',
            'authorization': 'Bearer ' + this.authService.getAuthorizationToken() || '',
        };

        return this.http.get(`${environment.api.nest.uri}divisiones?idConcurso=${idConcurso}`, { headers: headers });
    }


    public create(concurso): Observable<any> {
        const params = JSON.stringify(concurso);
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/crear/`, params, { headers: headers });
    }

    public getConcurso(idConcurso): Observable<any> {
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}concursos/getById/?c=${idConcurso}`, { headers: headers });
    }

    public guardarCampo(nombreCampo, tipoCampo, valor, idConcurso): Observable<any> {
        const params = JSON.stringify({ nombreCampo, tipoCampo, valor, idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/guardarCampo/`, params, { headers: headers });
    }

    public getSemanas(idConcurso): Observable<any> {
        const params = JSON.stringify({ idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/semanas/`, params, { headers: headers });
    }

    public getDias(idConcurso): Observable<any> {
        const params = JSON.stringify({ idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/dias/`, params, { headers: headers });
    }

    public getConcursosFei(): Observable<any> {
        const headers = {
            'x-api-key': this.api_key
        };

        return this.http.get(`${environment.api.uri}fei/get_shows.php`, { headers: headers });
    }

    public saveFeiShow(show, idConcurso): Observable<any> {
        const params = JSON.stringify({ show, idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}fei/save_show.php`, params, { headers: headers });
    }

    public getPistas(idConcurso): Observable<any> {
        const params = JSON.stringify({ idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/pistas/`, params, { headers: headers });
    }

    public getPistasNest(idConcurso): Observable<any> {
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8',
            'authorization': 'Bearer ' + this.authService.getAuthorizationToken() || '',
        };

        return this.http.get(`${environment.api.nest.uri}pistas/show/${idConcurso}`, { headers: headers });
    }

    public getDistributions(id_concurso): Observable<any> {
        console.log('Getting distributions for id_concurso', id_concurso);
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8',
            'authorization': 'Bearer ' + this.authService.getAuthorizationToken() || '',
        };

        return this.http.get(`${environment.api.nest.uri}distribuciones/show/${id_concurso}`, { headers: headers });
    }

    public copy(from, to, classes, divisions, prizeMoneyDistributions, billingItems, showSetting): Observable<any> {
        const params = JSON.stringify({ from, to, classes, divisions, prizeMoneyDistributions, billingItems, showSetting });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}concursos/copy/`, params, { headers: headers });
    }

    public copyNest(from, to, classes, divisions, prizeMoneyDistributions, billingItems, showSetting): Observable<any> {
        const params = JSON.stringify({ from, to, classes, divisions, prizeMoneyDistributions, billingItems, showSetting });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8',
            'authorization': 'Bearer ' + this.authService.getAuthorizationToken() || '',
        };

        return this.http.post(`${environment.api.nest.uri}concursos/copyShow/`, params, { headers: headers });
    }

    public updateNest(concurso: Partial<ConcursoNest>): Promise<ApiResponseNest<ConcursoNest>> {
        console.log('Updating nest concurso:', concurso);
        try {
            // Create a copy of the concurso object to avoid mutating the input
            const concursoToUpdate = { ...concurso };

            // Handle date formatting if dates are present
            if (concursoToUpdate.inicio || concursoToUpdate.fin) {
                concursoToUpdate.inicio = concursoToUpdate.inicio ? new Date(concursoToUpdate.inicio) : undefined;
                concursoToUpdate.fin = concursoToUpdate.fin ? new Date(concursoToUpdate.fin) : undefined;
            }

            const headers = {
                'authorization': `Bearer ${this.authService.getAuthorizationToken() || ''}`,
                'Content-Type': 'application/json;charset=UTF-8'
            };

            return this.http.put<ApiResponseNest<ConcursoNest>>(
                `${environment.api.nest.uri}concursos/update`,
                concursoToUpdate,
                { headers }
            ).toPromise()
            .catch(error => {
                console.error('Error updating concurso:', error);
                throw new Error('Failed to update concurso. Please try again later.');
            });

        } catch (error) {
            console.error('Error in updateNest:', error);
            throw new Error('An unexpected error occurred while updating the concurso.');
        }
    }

}
